import styled from "styled-components";
import { d, v } from "../../../CssVariables";
import bg from "../images/background_timeschedule.jpg";

const TimeSchedule = () => {
  return (
    <STimeSchedule id="timeSchedule">
      <div className="title-box">
        <h2 className="center">タイムスケジュール</h2>
        <div className="title-en-small title-en center">TIME SCHEDULE</div>
      </div>
      <div className="desc">
        <dl>
          <dt>12:30</dt>
          <dd>開場</dd>
          <dt>13:00〜16:00</dt>
          <dd>
            関係者からのご挨拶
            <br />
            大学発ベンチャー起業に結び付く研究シーズ６テーマの発表
          </dd>
          <dt>16:00〜16:30</dt>
          <dd>研究代表者との名刺交換会</dd>
          <dt>16:30〜17:30</dt>
          <dd>個別相談会</dd>
        </dl>
      </div>
    </STimeSchedule>
  );
};
export default TimeSchedule;

const STimeSchedule = styled.section`
  margin: 0;
  padding: 8vw 6%;
  background-image: url(${bg});
  background-size: cover;
  background-position: center center;
  @media ${d.minLaptop} {
    padding: 6vw 16vw;
  }
  .title-en-small {
    color: ${v.primaryColor21};
  }
  .desc {
    max-width: 900px;
    /* box-shadow: 0 2px 10px 3px rgba(0, 0, 0, 0.1); */
    margin: 0 auto;
    padding: 30px;
    background-color: #fff;

    dl {
      dt {
        flex-basis: 20%;
      }
      dd {
        flex-basis: 80%;
      }
    }
  }
`;
