
import styled from "styled-components";
import { d, v } from "../../../CssVariables";
import SpeakerList from "../data/speakerList";


const EndAnnounce = () => {

  const outputSpeakerDocument = SpeakerList.map((speaker, index) => (
    <li key={index}>
      <span className="num">発表0{index + 1}</span>
      <span className="name">{ speaker.name }氏</span>
      <span className="movie-start">{ speaker.movieStart }</span>
      <a href={ speaker.document } className="pdf-link">PDF</a>
    </li>
  ))

  return(
    <SEndAnnounce>
      <h3>イベントは終了しました。</h3>
      <div className="movie-wrapper">
        <h4>当日アーカイブムービー</h4>
        <iframe src="https://www.youtube.com/embed/bQRV67Ia5p8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        <div className="document-list-wrapper">
          <h4>当日プレゼン資料</h4>
          <ul className="document-list flex">
            {outputSpeakerDocument}
          </ul>
        </div>
      </div>
    </SEndAnnounce>
  );
}
export default EndAnnounce;

const SEndAnnounce = styled.section`
  margin: 0;
  padding-top: 8rem;
  padding-bottom: 8rem;
  background-color: #eee;
  text-align: center;
  h3 {
    font-size: 3.0rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0.5em;
    @media ${d.maxTab} {
      font-size: 2.4rem;
    }
  }
  .movie-wrapper {
    width: 70%;
    margin: 0 auto;
    @media ${d.maxTab} {
      width: 90%;
    }
    h4 {
      font-size: 1.8rem;
      font-weight: bold;
      text-align: center;
      background-color: ${v.primaryColor21};
      display: inline-block;
      color: #fff;
      padding: 0.2em 1.5em;
      @media ${d.maxTab} {
        font-size: 1.6rem;
      }
    }
    iframe {
      width: 100%;
      aspect-ratio: 5 / 3;
    }
    .document-list-wrapper {
    margin-top: 20px;
    padding: 20px;
    text-align: center;
    background-color: #fff;
    h4 {
      margin-bottom: 20px;
    }
    ul.document-list.flex {
      margin-bottom: 0;
      li {
        width: 50%;
        text-align: center;
        padding: 0.2em;
        border-bottom: 1px solid #ddd;
        &:nth-of-type(-n+2) {
          border-top: 1px solid #ddd;
        }
        &:nth-of-type(odd) {
          border-right: 1px solid #ddd;
          border-left: 1px solid #ddd;
        }
        &:nth-of-type(even) {
          border-right: 1px solid #ddd;
        }
        @media ${d.maxTab} {
          &:nth-of-type(odd) {
          border-right: none;
          border-left: none;
        }
        &:nth-of-type(even) {
          border-right: none;
        }
          &:nth-of-type(n+2) {
            border-top: none;
          }
          width: 100%;
        }
        .num {
          display: inline-block;
          margin-right: 1em;
        }
        .movie-start {
          display: inline-block;
          margin-left: 1em;
        }
        a.pdf-link {
          display: inline-block;
          margin-left: 1em;
          border-bottom: 1px solid ${v.primaryColor21};
          line-height: 1.5em;
        }
      }
    }
  }
  }

`;