import styled from "styled-components";
import { d, v } from "../../../CssVariables";
import bg from "../images/background_timeschedule.jpg";
import overlay from "../images/overlay_02.png";

const TimeSchedule = () => {
  return (
    <STimeSchedule id="timeSchedule">
      <div className="content-over-box"></div>

      <div className="inner">
        <div className="title-box">
          <div className="title-en-large title-en center">TIME SCHEDULE</div>
          <h2 className="center">タイムスケジュール</h2>
        </div>

        <div className="desc">
          <dl>
            <dt>13:00</dt>
            <dd>開会のご挨拶</dd>

            <dt>13:25</dt>
            <dd>
              <b>[発表01]</b>
              <br />
              <b>Rasシグナルの網羅的阻害を基軸とした革新的がん治療薬の開発</b>
              <br />
              神戸大学 研究代表<span className="name">島 扶美</span>
            </dd>

            <dt>13:45</dt>
            <dd>
              <b>[発表02]</b>
              <br />
              <b>癌幹細胞を標的とした新規癌ウイルス療法薬の開発</b>
              <br />
              神戸大学 研究代表<span className="name">白川 利朗</span>
            </dd>

            <dt>14:05</dt>
            <dd>
              <b>[発表03]</b>
              <br />
              <b>構造発色する無機ナノ粒子の色材としての事業化の検討</b>
              <br />
              神戸大学 研究代表<span className="name">杉本 泰</span>
            </dd>

            <dt>14:25</dt>
            <dd>
              <b>[発表04]</b>
              <br />
              <b>革新的な疾患評価用バイオマーカーの開発</b>
              <br />
              神戸大学 研究代表<span className="name">今石 浩正</span>
            </dd>

            <dt>14:45</dt>
            <dd>休憩</dd>

            <dt>15:00</dt>
            <dd>
              <b>[発表05]</b>
              <br />
              <b>がんや認知症を防ぐマイオカインを高濃度に含む培養食肉カプセル</b>
              <br />
              大阪工業大学 研究代表<span className="name">藤里 俊哉</span>
            </dd>

            <dt>15:20</dt>
            <dd>
              <b>[発表06]</b>
              <br />
              <b>燃料電池の原理(電解法)を用いたバリアフィルムの酸素および燃料分子の透過・拡散係数の評価装置</b>
              <br />
              大阪工業大学 研究代表<span className="name">金藤 敬一</span>
            </dd>

            <dt>15:40</dt>
            <dd>
              <b>[発表07]</b>
              <br />
              <b>患者のQOL向上を目指した、新規生体類似材料を用いた、異物付着抑制効果を有した尿管ステントの研究</b>
              <br />
              神戸大学 研究代表<span className="name">重村 克巳</span>
            </dd>

            <dt>16:00</dt>
            <dd>閉会のご挨拶</dd>
          </dl>

          <p style={{ "margin-top": "10px" }}>
            16時過ぎ終了予定
            <br />
            ※現地参加の方は、DEMODAY終了後、別室にて発表者との名刺交換会を予定しております。
          </p>
        </div>
      </div>
    </STimeSchedule>
  );
};
export default TimeSchedule;

const STimeSchedule = styled.section`
  position: relative;
  margin: 0;
  padding: 8vw 6%;
  background-image: url(${bg});
  background-size: cover;
  background-position: center center;
  @media ${d.minLaptop} {
    padding: 6vw 16vw;
  }
  .title-en-large {
    color: ${v.primaryColor22};
  }
  .content-over-box {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-image: url(${overlay});
    background-color: transparent;
  }

  .inner {
    position: relative;
    z-index: 2;
  }

  .title-box {
    h2 {
      color: ${v.primaryColor22};
      font-size: 3.2rem;
      padding-bottom: 0;
      @media ${d.maxTab} {
        font-size: 2.4rem;
      }
    }
  }
  .desc {
    width: 900px;
    margin: 0 auto;
    padding: 30px;
    background-color: rgba(255, 255, 255, 0.95);
    @media ${d.maxTab} {
      width: 100%;
      padding: 10px;
    }

    dl {
      dt {
        flex-basis: 20%;
        background-color: transparent;
      }
      dd {
        flex-basis: 80%;
        background-color: transparent;
        letter-spacing: 0;
      }
      span.name {
        display: inline-block;
        margin-left: 1em;
      }
    }
  }
`;
