import { useEffect } from "react";
import BalloonBase from "../BalloonBase2021";

const Balloon8 = (props) => {
  const { hover8 } = props;
  useEffect(() => {
    document.body.style.cursor = hover8 ? "pointer" : "default";
  }, [hover8]);

  return <BalloonBase whichHover={hover8}>三角柱マルチリモコンの製品化事業</BalloonBase>;
};
export default Balloon8;
