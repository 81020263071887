import styled from "styled-components";
import Head from "../../Head";
import Hero from "../organisms/Hero2023";
import TempUnderLayerMax from "../templates/TempUnderLayerMax";
import { d } from "../../CssVariables";

const DemoDay2024 = () => {
  return (
    <>
      <Head title="DEMO DAY" />
      <SDemoday2024>
        <Hero title={"DEMODAY 2024 動画視聴ページ"} />
        <TempUnderLayerMax>
          <section id="movie">
            <div className="movie-wrapper">
              <iframe
                width="960"
                height="540"
                src="https://www.youtube.com/embed/rJYd9tiJNH8?si=cFz0WMrOc4Yqz12_"
                title="YouTube video player"
                frameborder="0"
                allowfullscreen></iframe>
            </div>
          </section>
        </TempUnderLayerMax>
      </SDemoday2024>
    </>
  );
};
export default DemoDay2024;

const SDemoday2024 = styled.div`
  section#movie {
    margin: 0;
    background: #222222;
    background: -webkit-linear-gradient(315deg, #222222 0%, #111111 100%);
    background: linear-gradient(135deg, #222222 0%, #111111 100%);
    padding: 30px;
  }

  @media screen and (min-width: 1025px) {
    section#movie {
      padding: 40px;
    }
  }

  section#movie .movie-wrapper {
    position: relative;
    width: 100%;
  }

  section#movie .movie-wrapper:before {
    content: "";
    display: block;
    padding-top: 56.25%;
    /* 9:16 */
  }

  @media screen and (min-width: 1025px) {
    section#movie .movie-wrapper:before {
      display: none;
    }
  }

  section#movie .movie-wrapper iframe {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: 1025px) {
    section#movie .movie-wrapper iframe {
      position: relative;
      width: 960px;
      height: 540px;
    }
  }

  section#movie .movie-wrapper .dammy {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    background-color: #333;
  }

  @media screen and (min-width: 1025px) {
    section#movie .movie-wrapper .dammy {
      position: relative;
      width: 960px;
      height: 540px;
    }
  }
`;
