import { Link } from "react-router-dom";
import styled from "styled-components";
import { d, v } from "../../CssVariables";
import { IoArrowBackSharp, IoArrowForwardSharp } from "react-icons/io5";
import Projects2020 from "../../data/Project2020";

const Pager = (props) => {
  const { thisProject } = props;

  const prevLink = thisProject.id <= Projects2020.length ? thisProject.id - 1 : thisProject.id;
  const nextLink = thisProject.id < Projects2020.length ? thisProject.id + 1 : thisProject.id;
  const noMorePrev = thisProject.id === Projects2020[0].id && "done";
  const noMoreNext = thisProject.id === Projects2020.length && "done";

  return (
    <SPager className="flex">
      <div className="prev">
        {noMorePrev === "done" ? null : (
          <Link className="btn" to={`/project-2020/${prevLink}`}>
            <span>
              <IoArrowBackSharp />
            </span>
            <span>前の技術シーズへ</span>
          </Link>
        )}
      </div>

      <div className="to--all-project">
        <Link className="btn" to={`/project-all-2020`}>
          <span>技術シーズ一覧</span>
        </Link>
      </div>

      <div className="next">
        {noMoreNext === "done" ? null : (
          <Link className="btn" to={`/project-2020/${nextLink}`}>
            <span>次の技術シーズへ</span>
            <span>
              <IoArrowForwardSharp />
            </span>
          </Link>
        )}
      </div>
    </SPager>
  );
};
export default Pager;

const SPager = styled.section`
  justify-content: center !important;
  align-items: center;
  div {
    margin: 0 10px;
    @media ${d.maxSp} {
      margin: 0 20px 10px;
    }
    text-align: center;
    /* background-color: ${v.primaryColor21}; */
  }
`;
