import { useEffect } from "react";
import BalloonBase from "./BalloonBase";

const BalloonUniversityKobe = (props) => {
  
  const {hoverUniversityKobe} = props;
  useEffect(() => {
    document.body.style.cursor = hoverUniversityKobe ? "pointer" : "default";
  },[hoverUniversityKobe])

  return (
    <BalloonBase whichHover={hoverUniversityKobe}>神戸大学</BalloonBase>
  )
}
export default BalloonUniversityKobe;