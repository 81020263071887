import { useEffect } from "react";
import BalloonBase from "../BalloonBase2022";

const Balloon2 = (props) => {
  const { hover2 } = props;
  useEffect(() => {
    document.body.style.cursor = hover2 ? "pointer" : "default";
  }, [hover2]);

  return <BalloonBase whichHover={hover2}>生体膜を模倣したバイオチップの開発とその事業化</BalloonBase>;
};
export default Balloon2;
