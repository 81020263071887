/* eslint eqeqeq: 0 */
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import Head from "../../Head";
import UnderLayer from "../templates/TempUnderLayer";
import Project2022 from "../../data/Project2022";
import Pager from "../organisms/Pager2022";
import ProjectHero from "../organisms/ProjectHero2022";
import ProjectDescription from "../organisms/ProjectDescription2022";

const SingleProject = () => {
  const { projectId } = useParams();
  const thisProject = Project2022.find((element) => element.id == projectId);

  useEffect(() => {
    const heroTitle = document.querySelectorAll(".hero-title");

    // hero animation
    setTimeout(() => {
      heroTitle.forEach((item, index) => {
        setTimeout(() => {
          item.classList.add("hero-title-on");
        }, 200 * index);
      });
    }, 1500);
  }, []);

  return (
    <>
      <Head title={thisProject.title} />
      <SSingleProject>
        <ProjectHero thisProject={thisProject} />
        <UnderLayer>
          <ProjectDescription thisProject={thisProject} />
          <Pager thisProject={thisProject} />
        </UnderLayer>
      </SSingleProject>
    </>
  );
};
export default SingleProject;

const SSingleProject = styled.div``;
