import styled from "styled-components";
import { d } from "../../../CssVariables";

const BottomLogoArea = () => {
  return (
    <SBottomLogoArea>
      <div className="flex">
        <img src={`${process.env.PUBLIC_URL}/assets/demoday2022/images/logo_jst.svg`} />
        <img src={`${process.env.PUBLIC_URL}/assets/demoday2022/images/logo_kobe.svg`} />
        <img className="logo-oit" src={`${process.env.PUBLIC_URL}/assets/demoday2022/images/logo_oit.svg`} />
      </div>
    </SBottomLogoArea>
  )
}
export default BottomLogoArea;


const SBottomLogoArea = styled.section`
  margin: 0;
  padding: 3vw;
  @media ${d.maxSp} {
    padding: 6vw 3vw;
  }
  .flex {
    justify-content: center;
    img {
      /* align-self: center; */
      flex-shrink: 0;
      width: auto;
      height: 40px;
      margin: 0 20px;
      @media ${d.maxSp} {
        margin-bottom: 6vw; 
        &:last-child {
          margin-bottom: 0;
        }
      }
      &.logo-oit {
        height: 35px;
      }
    }
  }
`;