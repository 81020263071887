import { useEffect } from "react";
import BalloonBase from "../BalloonBase";

const Balloon6 = (props) => {
  const { hover6 } = props;
  useEffect(() => {
    document.body.style.cursor = hover6 ? "pointer" : "default";
  }, [hover6]);

  return <BalloonBase whichHover={hover6}>光オンデマンド合成法によるポリマーや医薬品原料の安全で安価なエコ生産システムの事業化</BalloonBase>;
};
export default Balloon6;
