import { useEffect } from "react";
import BalloonBase from "../BalloonBase2021";

const Balloon13 = (props) => {
  const { hover13 } = props;
  useEffect(() => {
    document.body.style.cursor = hover13 ? "pointer" : "default";
  }, [hover13]);

  return <BalloonBase whichHover={hover13}>ヒトiPS細胞の心筋細胞分化に及ぼす血管新生促進ゲルの効果の検証</BalloonBase>;
};
export default Balloon13;
