
import styled from "styled-components";
import { d, v } from "../../../CssVariables";
import SpeakerList from "../../../data/Project2022";
import ReactPlayer from "react-player/youtube";
import useMeasure from "react-use-measure";
import { useMediaQuery } from "../../../UseMediaQuery";
const mq = useMediaQuery;


const EndAnnounce = () => {

  const outputSpeakerDocument = SpeakerList.map((speaker, index) => (
    <li key={index}>
      {/* <span className="num">発表0{index + 1}</span> */}
      <span className="name">{ speaker.authorName } 氏</span>
      <span className="movie-start">{ speaker.movieStart }</span>
      <a href={ speaker.document } className="pdf-link">PDF</a>
    </li>
	))
	

	 const [ref, videoWrapperSize] = useMeasure();

  return(
    <SEndAnnounce>
			<h3>イベントは終了しました。<br />ご視聴ありがとうございました。</h3>
			
        
				<h4>当日アーカイブムービー</h4>
        {/* <div className="movie-wrapper">
          <div className="iframe-wrapper">
            <iframe src="https://www.youtube.com/embed/zH5XvBQ5t8o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          
          <div className="document-list-wrapper">
            <h4>動画内プレゼン開始時間&資料</h4>
            <ul className="document-list flex">
              {outputSpeakerDocument}
            </ul>
          </div>
				</div> */}
			
				<section id="projectMovie">
          <div className="width-max">
            <div
							
              ref={ref}
              className="video-wrapper"
              style={(mq.isPC && { height: (videoWrapperSize.width / 5) * 1.666 }) || (mq.isSP && { height: (videoWrapperSize.width / 16) * 9 })}>
              <ReactPlayer
                width="100%"
                height="100%"
                url="https://youtu.be/wCt9Pryjpkk?si=kHn1Y9Tz911rbwxO"
                light="/assets/demoday2024/images/video_thumbnail.jpg" // サムネイル
							// onPause={() => console.log('onPause')}
							
                config={{
                  youtube: {
                    playerVars: {
                      autoplay: 1,
                      controls: 1,
                      modestbranding: 1,
                      rel: 0, // 関連動画の非表示
                      showinfo: 0, // サポート終了のため効かない
                    },
                  },
                }}
              />
            </div>
          </div>
        </section>


			
    </SEndAnnounce>
  );
}
export default EndAnnounce;

const SEndAnnounce = styled.section`
  margin: 0;
  padding-top: 8rem;
  padding-bottom: 8rem;
  background-color: #f3f3f3;
  text-align: center;

	section#projectMovie {
		margin: 0;
	}
  h3 {
    font-size: 2.4rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0.5em;
    @media ${d.maxTab} {
      font-size: 1.8rem;
    }
  }
  h4 {
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    background-color: ${v.primaryColor23};
    display: inline-block;
    color: #fff;
    padding: 0.2em 1.5em;
    @media ${d.maxTab} {
      font-size: 1.6rem;
    }
  }

	.video-wrapper {
		width: 100%;
		aspect-ratio: 5/2
	}


  .movie-wrapper {
    width: 80%;
    margin: 0 auto;
    @media ${d.maxTab} {
      width: 90%;
    }
    .iframe-wrapper {
      width: 80%;
      display:inline-block;
      @media ${d.maxTab} {
        width: 100%;
      }
      p {
        background-color: #111;
        text-align: center;
        color: #fff;
        font-weight: bold;
      }
    }
    iframe {
      width: 100%;
      aspect-ratio: 5 / 3;
    }
    .document-list-wrapper {
    margin-top: 20px;
    padding: 20px;
    text-align: center;
    background-color: #fff;
    h4 {
      margin-bottom: 20px;
    }
    ul.document-list.flex {
      margin-bottom: 0;
      li {
        width: 50%;
        text-align: center;
        padding: 0.2em;
        margin-bottom: 0;
        border-bottom: 1px solid #ddd;
        &:nth-of-type(-n+2) {
          border-top: 1px solid #ddd;
        }
        &:nth-of-type(odd) {
          border-right: 1px solid #ddd;
          border-left: 1px solid #ddd;
        }
        &:nth-of-type(even) {
          border-right: 1px solid #ddd;
        }
        @media ${d.maxTab} {
          &:nth-of-type(odd) {
          border-right: none;
          border-left: none;
        }
        &:nth-of-type(even) {
          border-right: none;
        }
          &:nth-of-type(n+2) {
            border-top: none;
          }
          width: 100%;
        }
        .num {
          display: inline-block;
          margin-right: 1em;
        }
        .movie-start {
          display: inline-block;
          margin-left: 1em;
        }
        a.pdf-link {
          display: inline-block;
          margin-left: 1em;
          border-bottom: 1px solid ${v.primaryColor21};
          line-height: 1.5em;
        }
      }
    }
  }
  }

`;