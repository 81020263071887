const Projects = [
  {
    id: 1,
    url: "/project-2021/1",
    thumbnail: "/assets/models/textures/project_2021_14.jpg",
    category: "IT",
    adoptionYear: "2021年",
    publicationPeriod: "2021年4月",
    title: "移動軌跡データの効率的な活用のためのアプリケーション開発",
    subtitle: null,
    authorUniversity: "(採択時) 神戸大学",
    authorAffiliation: "大学院工学研究科",
    authorPosition: "特命助教",
    authorName: "安田 昌平",
    portrait: "/assets/images/project2021/1/portrait.jpg",
    video: {
      url: "https://www.youtube.com/embed/pXDfL9BbbV8",
      thumbnail: "/assets/images/project2021/1/video_thumbnail.jpg",
    },
    overviewText: [
      <p>
        近年、人々の移動・滞在を記録する人流データが豊富に収集されるようになった。人流データは都市や交通などの多様な分野において活用が期待されているが、その前処理にかかる負荷の高さ等から扱いが非常に難しいビッグデータとされている。我々の技術シーズは、実社会における人流データの活用促進のため、機械学習や空間統計学を用いてその特徴量を効率的に学習し、高速な解析・予測を可能とするアルゴリズムである。
      </p>,
    ],
    overviewImage: "/assets/images/project2021/1/1.jpg",
    resultText: null,
    resultImage: null,
    buisinessModelText: [
      <h4>人流データ処理を行うGUIアプリケーションの提供</h4>,
      <p>
        本技術シーズを用いて、企業や自治体が収集・保有する人流ビッグデータを効率的に活用可能なデータ構造へと変換し、GUIアプリケーションを介してその解析・予測結果を提供するビジネスモデルを想定している。本事業は主に人流データの処理及びGUIアプリケーションへの変換に注力するものである。
      </p>,
    ],
    buisinessModelImage: null,
    futurePlanText: [
      <p>
        任意の人流データを本技術シーズに基づくアルゴリズムで処理し、GUIアプリケーション上で解析結果等を確認することを可能とするプログラム及びwebサーバーの試作機を作製する。本試作機により実観測データを解析した結果の一例を用いて、人流データの活用を目指す企業や自治体へのヒアリングを行い、その結果を元に顧客にとって必要となる機能やオプションの精査を行う。
      </p>,
    ],
    futurePlanImage: "/assets/images/project2021/1/4.jpg",
  },
  {
    id: 2,
    url: "/project-2021/2",
    thumbnail: "/assets/models/textures/project_2021_15.jpg",
    category: "ライフサイエンス",
    adoptionYear: "2021年",
    publicationPeriod: "2021年8月",
    title: "生体膜を模倣したバイオチップの開発とその事業化",
    subtitle: null,
    authorUniversity: "神戸大学",
    authorAffiliation: "バイオシグナル総合研究センター",
    authorPosition: "准教授",
    authorName: "森垣 憲一",
    portrait: "/assets/images/project2021/2/portrait.jpg",
    video: {
      url: "https://www.youtube.com/embed/VEih0ENt9CA",
      thumbnail: "/assets/images/project2021/2/video_thumbnail.jpg",
    },
    overviewText: [
      <p>
        生体膜は、細胞においてシグナル伝達やエネルギー変換など重要な役割を果たし、膜に結合したタンパク質（膜タンパク質）は医薬品の主要標的になっている。本プロジェクトでは、安定な高分子材料と生体由来の脂質を組みわせて、生体膜の構造と機能をガラスなどの基板表面に再現する独自技術を開発する。
      </p>,
    ],
    overviewImage: "/assets/images/project2021/2/1.jpg",
    resultText: null,
    resultImage: null,
    buisinessModelText: [
      <p>
        生体膜を模倣した人工生体膜チップを、基礎研究や診断に用いられる素子として事業化する。安定な高分子膜（ポリマー膜）と生体膜を組み合わせたパターン化人工生体膜を、膜タンパク質機能計測のツールとして、基礎研究や創薬候補物質探索などに応用する。また、人工生体膜を用いて、疾患マーカーを高感度、高精度に検出できる技術を新規診断技術として事業化する。
      </p>,
    ],
    buisinessModelImage: null,
    futurePlanText: [
      <h4>パターン化ポリマー脂質膜チップの試作</h4>,
      <p>
        膜タンパク質の機能解析、新薬候補分子の評価などに応用するために、基板表面に形成したパターン化ポリマー脂質膜を試作する。それぞれの応用に適したパターン、製品形態を設計する。大学等の研究機関、企業に配布し、フィードバックを解析することで、事業化に適した人工生体膜の製品形態、作製技術について知見を得る。
      </p>,
      <h4>疾患マーカーの高感度・高精度検出技術の開発</h4>,
      <p>
        人工生体膜を用いて疾患マーカーを超高感度に計測する基礎技術を開発する。生体膜における分子の2次元拡散と非特異的吸着抑制を利用することで、血液のように高濃度に夾雑分子が存在する溶液に微少量しか存在しない疾患マーカーを、高感度・高精度に検出できる診断技術を開発する。
      </p>,
    ],
    futurePlanImage: null,
  },
  {
    id: 3,
    url: "/project-2021/3",
    thumbnail: "/assets/models/textures/project_2021_16.jpg",
    category: "ライフサイエンス",
    adoptionYear: "2021年",
    publicationPeriod: "2021年8月",
    title: "人工設計ペプチドを用いたレアメタル回収技術の開発とその事業化",
    subtitle: null,
    authorUniversity: "神戸大学",
    authorAffiliation: "大学院理学研究科",
    authorPosition: "准教授",
    authorName: "田村 厚夫",
    portrait: "/assets/images/project2021/3/portrait.jpg",
    video: {
      url: "https://www.youtube.com/embed/d6-eO4_NpZA",
      thumbnail: "/assets/images/project2021/3/video_thumbnail.jpg",
    },
    overviewText: [
      <p>
        ペプチドを環状化または集合させて中空のナノチューブ化し、任意の標的物質を捕捉可能なライブラリーを作製する。１）コロナウイルスのスパイクタンパク質中、変異の起こりにくい領域のヘリックス構造部分（右図：赤黄色）と結合し、変異型に対しても有効なウイルス不活化ペプチドを創製する。また、２）通常の生体物質では結合困難なレアメタルへの選択的結合性を持たせ、白金やロジウムなど目的の稀少金属だけを回収するシステムを開発する。
      </p>,
    ],
    overviewImage: "/assets/images/project2021/3/1.jpg",
    resultText: null,
    resultImage: null,
    buisinessModelText: [
      <p>
        本人工設計技術を用いた二種類の機能性ペプチドを用い、１）ウイルス不活化フィルターおよび２）レアメタル回収システムの製品化を目指す。さらに、得られたノウハウを活かし、顧客のニーズに基づくペプチド設計支援というビジネスモデルを構築する。
      </p>,
    ],
    buisinessModelImage: null,
    futurePlanText: [
      <ol>
        <li>機能を持ったペプチドライブラリーの構築</li>
        <li>コロナウイルスのスパイクタンパク質と相互作用するものの選定</li>
        <li>レアメタル結合ペプチドの選定</li>
      </ol>,
      <p>本開発手法を用いて作製されたペプチドを紙フィルター等に固定化し、利用可能性を探る。</p>,
      <ol>
        <li>マスクや空気清浄フィルターに付加価値を付与することを目指す。</li>
        <li>
          レアメタル回収フィルターを組み合わせ、目的金属ごとに分けた専用フィルターセットを試作する。これより、種々のベースメタルやレアメタルが混ざった溶液から、目的の金属のみを分離回収可能となる。
        </li>
        <li>以上の機能性ペプチド設計技術を、他ニーズへ展開することを支援するビジネスにつなげる。</li>
      </ol>,
    ],
    futurePlanImage: "/assets/images/project2021/3/4.jpg",
  },
  {
    id: 4,
    url: "/project-2021/4",
    thumbnail: "/assets/models/textures/project_2021_17.jpg",
    category: "IT",
    adoptionYear: "2021年",
    publicationPeriod: "2021年7月",
    title: "フードビジネスを対象としたシミュレーション・最適化技術",
    subtitle: null,
    authorUniversity: "神戸大学",
    authorAffiliation: "大学院システム情報学研究科",
    authorPosition: "教育研究補佐員",
    authorName: "舟橋 健雄",
    portrait: "/assets/images/project2021/4/portrait.jpg",
    video: {
      url: "https://www.youtube.com/embed/Q48MCuKvE04",
      thumbnail: "/assets/images/project2021/4/video_thumbnail.jpg",
    },
    overviewText: [
      <p>
        神戸大学大学院システム情報学研究科 藤井信忠
        准教授が研究開発したシステム情報技術を汎用化し、飲食業だけでなく農業を含む食のバリューチェーン全体に適用する。具体的には、生物由来の「自律分散型シミュレーション手法」を厨房レイアウトやスタッフスケジューリング等の実現場に適用し、人員を最小限にしながら顧客満足度の最大化を目指す飲食サービス業や農家の意思決定・収益改善に貢献する。
      </p>,
    ],
    overviewImage: "/assets/images/project2021/4/1.jpg",
    resultText: null,
    resultImage: null,
    buisinessModelText: [
      <p>
        最適な人材配置や経営の意思決定を支援する汎用的なシミュレーションシステムを構築し、飲食サービス業界に対し、生産性向上に係るコンサルティングサービスを実施する。
      </p>,
      <p>
        ※日本の外食産業市場の飲食主体部門の市場規模： 3兆8,138億円[R1年日本フードサービス協会]
        <br />
        この内、1%（約380億円）の市場を狙う。
      </p>,
    ],
    buisinessModelImage: null,
    futurePlanText: [
      <h4>1.システム最適化およびシミュレーション手法の汎用化</h4>,
      <p>
        藤井准教授のシミュレーションシステムを分析し、機械学習や深層学習でも使われている言語ベースのプログラムに変換すると共に、シミュレーション手法として汎用的に使用できる形に作り替える。
      </p>,
      <h4>2.マーケティング調査・有用性検討</h4>,
      <p>
        主たるターゲットとして設定する飲食サービス業界の企業関係者にヒアリングを実施し、開発予定のシミュレーションシステムの有用性について調査する。また、市場ニーズについての調査も並行して実施する。
      </p>,
      <h4>3.イベント出展・見込み顧客獲得</h4>,
      <p>
        国内外のイベントに出展し、本システム及びコンサルティングサービスの試用を希望する見込み顧客を確保する。
        <br />
        目標：5社以上
      </p>,
    ],
    futurePlanImage: null,
  },
  {
    id: 5,
    url: "/project-2021/5",
    thumbnail: "/assets/models/textures/project_2021_21.jpg",
    category: "ライフサイエンス",
    adoptionYear: "2020年",
    publicationPeriod: "2021年8月",
    title: "ペプチドを基軸とする神経障害性疼痛治療薬の開発",
    subtitle: null,
    authorUniversity: "大阪工業大学",
    authorAffiliation: "生命工学科",
    authorPosition: "教授",
    authorName: "芦高 恵美子",
    portrait: "/assets/images/project2021/5/portrait.jpg",
    video: {
      url: "https://www.youtube.com/embed/cBZFI9fHm74",
      thumbnail: "/assets/images/project2021/5/video_thumbnail.jpg",
    },
    overviewText: [
      <p>
        概要:
        神経障害性疼痛に対するノシスタチン由来ペプチドの経口投与の創薬に向け、ペプチドの体内動態と作用機序を検証し、その有効性を明らかにする。それらの結果に基づき、副作用をおさえた、有効な新薬を開発する。
      </p>,
      <p>
        背景:
        糖尿病、がんなどの疾患が原因となる神経障害性疼痛で悩んでいる患者に対し、第一選択薬としてカルシウムチャネル阻害剤であるプレガバリン・ガバペンチンなどが推奨されているが、有効性が30％と低く、眠気、ふらつきなどの副作用があるため、患者のQOL向上のため副作用の少ない効果の高い薬剤の開発が求められている。
      </p>,
      <p>
        独創性・新規性：
        神経ペプチド・ノシスタチンが髄腔内投与により神経障害性疼痛に特徴的な触れることが痛みとなるアロディニアを抑制することを見出した。ペプチドは、低分子化合物の薬剤に比べ高い薬効と選択性を有し、生体内物質であるため副作用が少なく、治療薬開発に有利である。
      </p>,
    ],
    overviewImage: "/assets/images/project2021/5/1.jpg",
    resultText: null,
    resultImage: null,
    buisinessModelText: [
      <p>
        神経障害性疼痛などの慢性疼痛に対する新しい治療薬や機能性食品を開発。ただし、治療薬の開発には膨大な費用と時間がかかるため、JST
        「大学発新産業創出プログラム (START)
        」の申請及び、事業化に向けて、研究開発の成果と特許を基に、共同研究により前臨床試験と臨床試験を行うことができる製薬や食品会社とのアライアンス開拓に、向けての取り組みを強化中。
      </p>,
      <p>最終的には資金調達の上、自社での創薬事業開始もしくは既存製薬会社へのライセンスアウトを予定。</p>,
    ],
    buisinessModelImage: null,
    futurePlanText: [
      <h4>技術開発</h4>,
      <ul>
        <li>・ノシスタチン由来ペプチドの作用機序と体内動態の解析</li>
        <li>
          ・ノシスタチン由来ペプチド含有食品の探索と機能解析
          <br />
          現在、神経障害性疼痛へのペプチドの経口投与に関し、特許を出願中。
        </li>
        <li>・候補食品や食品含有配列の合成ペプチドによる鎮痛効果を評価予定</li>
      </ul>,
      <h4>事業化に向けた計画</h4>,
      <ul>
        <li>・技術開発と並行し、共同研究先となるVC,製薬会社へのアプローチを実施。</li>
        <li>
          ・～2025年度末：
          ノシスタチン由来ペプチドの創薬に向けた安全性等の試験と、ノシスタチン由来ペプチド含有食品の機能性食品としてのヒト対象研究により有効性を検討し、事業化の検証を実施
        </li>
        <li>・2025年4月以後：医薬品や機能性食品への展開などの事業育成を進め、ベンチャー企業の創出またはライセンスアウトを目指す。</li>
      </ul>,
    ],
    futurePlanImage: null,
  },
  {
    id: 6,
    url: "/project-2021/6",
    thumbnail: "/assets/models/textures/project_2021_22.jpg",
    category: "IT",
    adoptionYear: "2020年",
    publicationPeriod: "2021年8月",
    title: "分散センサに基づく危機管理AIシステムの実現、及び事業化",
    subtitle: null,
    authorUniversity: "大阪工業大学",
    authorAffiliation: "情報知能学科",
    authorPosition: "教授",
    authorName: "尾崎 敦夫",
    portrait: "/assets/images/project2021/6/portrait.jpg",
    video: {
      url: "https://www.youtube.com/embed/Wrx653uxrbM",
      thumbnail: "/assets/images/project2021/6/video_thumbnail.jpg",
    },
    overviewText: [
      <p>
        概要：
        イベント会場、商業施設にWi-Fi信号を検知するセンサを配置し、人流をリアルタイムに取得、解析することで、避難計画の策定、店舗運営の効率化等に活用できる技術の開発及び販売事業。
      </p>,
      <p>
        背景：
        危機管理、マーケティングの効率化などに必須となっている人流データであるが、カメラを使用する手法が多く、コスト、個人情報の観点から、新たな手法による人流解析の方法が市場から要望されている。
      </p>,
      <p>
        独創性・新規性：
        通行者が保有するスマートフォンから発せられる信号を検知することで、人流の検知を行う方法を開発。これにより機器の設置が簡便となり、低コストで正確な人流検知が可能となる。現在、個人情報保護の観点から、Wi-Fi端末(スマートフォン等)のMACアドレス秘匿化が推進しているため、端末の同定･追跡が困難となっている。そこで、秘匿化されたMACアドレスを紐づけることで、端末の同定･追跡する技術に関する特許を出願した。取得したデータの評価及び予測シミュレーションソフトとあわせて市場に提供する。
      </p>,
    ],
    overviewImage: null,
    resultText: null,
    resultImage: null,
    buisinessModelText: [
      <p>
        安価で高性能かつ設置の容易なセンサを開発することにより、人流解析の分野に参入。センサユニットの設置、人流解析、評価をパッケージで行い、発注者に提供。また将来的にはセンサユニット及び解析ソフトのパッケージ販売も計画。
      </p>,
    ],
    buisinessModelImage: null,
    futurePlanText: [
      <h4>技術開発</h4>,
      <ul>
        <li>・Wi-Fi信号検知による人流検知の精度向上（最終目標は誤差5％）</li>
        <li>・取得したデータを解析、評価するアプリケーションソフトの開発</li>
        <li>・データにもとづく、人流予測シミュレーションの手法を確立</li>
      </ul>,
      <h4>事業化に向けた計画</h4>,
      <ul>
        <li>
          ・現在、枚方くらわんか五六市及びひらかたパークにて実証実験中
          <br />
          <a href="http://www.oit.ac.jp/japanese/pressrelease/show.php?id=7940">http://www.oit.ac.jp/japanese/pressrelease/show.php?id=7940</a>
        </li>
      </ul>,
    ],
    futurePlanImage: null,
  },
];

export default Projects;
