import { useEffect } from "react";
import BalloonBase from "../BalloonBase";

const Balloon1 = (props) => {
  const { hover1 } = props;
  useEffect(() => {
    document.body.style.cursor = hover1 ? "pointer" : "default";
  }, [hover1]);

  return <BalloonBase whichHover={hover1}>細胞間シグナルCD47-SIRPα系を標的とした新規がん治療法の開発</BalloonBase>;
};
export default Balloon1;
