import { useLocation, Link } from "react-router-dom";
import styled from "styled-components";
import Project2022 from "../../data/Project2022";
import Head from "../../Head";
import UnderLayer from "../templates/TempUnderLayer";
import Hero from "../organisms/Hero2022";
import { d, v } from "../../CssVariables";

const ProjectAll = () => {

  const location = useLocation();

  const LifeScienceProject = Project2022.filter(project => project.category === 'ライフサイエンス')
  const ITProject = Project2022.filter(project => project.category === 'IT')
  const ManufactureProject = Project2022.filter(project => project.category === 'ものづくり')
  const EnviromentalEnergyProject = Project2022.filter(project => project.category === '環境エネルギー')
  const OtherProject = Project2022.filter(project => project.category === 'その他')


  const outputProject = (category) => (
    category.map((el, i) => (
      <li key={i}>
        <Link to={el.url} className={location.pathname === el.url ? 'active' : null}>
          <img src={el.thumbnail} alt=""/>
          <div>
            <h3>{el.title}</h3>
            <h4>
              <div>
                <span>{el.authorUniversity}</span>
                <span>{el.authorAffiliation}</span>
              </div>
              <div>
                <span>{el.authorName}</span>
                <span>{el.authorPosition}</span>
              </div>
            </h4>
          </div>
        </Link>
      </li>
    ))
  )

  return (
    <>
      <Head title="2022年度 技術シーズ一覧" />
      <SProjectAll>
        <Hero title="2022年度 技術シーズ一覧" />
        <UnderLayer>
          <section>
            <div id="lifeScience" className="project-category-list">
              <h2>ライフサイエンス</h2>
              <ul>{outputProject(LifeScienceProject)}</ul>
            </div>

            <div id="manufacture" className="project-category-list">
              <h2>ものづくり</h2>
              <ul>{outputProject(ManufactureProject)}</ul>
            </div>

          </section>
        </UnderLayer>
      </SProjectAll>
    </>
  )
};
export default ProjectAll;


const SProjectAll = styled.div`
  .project-category-list {
    margin-bottom: 40px;
    h2 {
      background-color: ${v.primaryColor22};
      color: #fff;
      margin-bottom: 1em;
      padding-left: 1em;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &::after {
        content:"";
        display: block;
        width:31%;
      }
      li {
        width: 31%;
        margin-bottom: 2vw;
        background-color: #fff;
        border-bottom: 1px solid ${v.primaryColor22};
        @media ${d.maxSp} {
          width: 100%;
          margin-bottom: 6vw;
        }
        a {
          img {
            border-bottom: 1px solid ${v.primaryColor22};
            display: block;
          }
          > div {
              padding-bottom: 0;
              padding: 1em;
            h3 {
              font-size: 1.6rem;
            }
            h4 {
              font-size: 1.4rem;
              span {
                display: inline-block;
                padding-right: 0.5em;
              }
            }
          }
        }
      }
    }
  }
`