import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { d, v } from "../../CssVariables";
import { YearContext } from "../../providers/YearProvider";
import { AnnounceContext } from "../../providers/AnnounceProvider";
import { IoClose } from "react-icons/io5";

import Project2020 from "../../data/Project2020";
import Project2021 from "../../data/Project2021";
import Project2022 from "../../data/Project2022";
import Project2023 from "../../data/Project2023";

const TopProjectAllLists = () => {
  const location = useLocation();

  const { year, setYear } = useContext(YearContext);
  const { announce } = useContext(AnnounceContext);

  const [showProjectList, setShowProjectList] = useState(false);

  const outputList = (project) =>
    project.map((el, i) => (
      <li key={i}>
        <Link to={el.url} className="flex">
          <img src={el.thumbnail} alt="" />
          <div className="text-wrapper">
            <h3>{el.title}</h3>
            <h4>
              <div>
                <span>{el.authorUniversity}</span>
                <span>{el.authorAffiliation}</span>
              </div>
              <div>
                <span>{el.authorName}</span>
                <span>{el.authorPosition}</span>
              </div>
            </h4>
          </div>
        </Link>
      </li>
    ));

  return (
    <STopProjectAllLists className="project-all-lists flex" show={announce}>
      <button type="button" className="flex" onClick={() => setShowProjectList(!showProjectList)}>
        <img src="./assets/images/icon_project-all-lists.svg" className="icon-project-all" />
        <span>技術シーズ一覧</span>
      </button>

      <div className={`project-list-box ${showProjectList ? "visible" : "hidden"}`}>
        <button type="button" className="btn-close" onClick={() => setShowProjectList(!showProjectList)}>
          <IoClose />
        </button>

        {year === "2021" && (
          <>
            <p className="title" style={{ color: v.primaryColor20 }}>
              技術シーズ一覧 2020年度
            </p>
            <ul className="project-list flex">{outputList(Project2020)}</ul>
            <hr />
            <ul className="project-list flex">
              <li>
                <a
                  href="https://www.youtube.com/watch?v=8u20JpatnLc&t=162s&ab_channel=%E7%A5%9E%E6%88%B8%E5%A4%A7%E5%AD%A6%E3%83%BB%E5%A4%A7%E9%98%AA%E5%B7%A5%E6%A5%AD%E5%A4%A7%E5%AD%A6JSTSCORE%E4%BA%8B%E6%A5%AD"
                  className="flex"
                  target="_blank">
                  <img className="mv-title" src={`${process.env.PUBLIC_URL}/assets/models/texture2021/demoday_screen_2021.jpg`} />
                  <div className="text-wrapper">
                    <h3>DEMODAY 2021</h3>
                  </div>
                </a>
              </li>
            </ul>
          </>
        )}

        {year === "2022" && (
          <>
            <p className="title" style={{ color: v.primaryColor21 }}>
              技術シーズ一覧 2021年度
            </p>
            <ul className="project-list flex">{outputList(Project2021)}</ul>
            <hr />
            <ul className="project-list flex">
              <li>
                <Link className="flex" to={`/demoday2022`}>
                  <img className="mv-title" src={`${process.env.PUBLIC_URL}/assets/models/texture2022/demoday_screen_2022.jpg`} />
                  <div className="text-wrapper">
                    <h3>DEMODAY 2022</h3>
                  </div>
                </Link>
              </li>
            </ul>
          </>
        )}

        {year === "2023" && (
          <>
            <p className="title" style={{ color: v.primaryColor22 }}>
              技術シーズ一覧 2022年度
            </p>
            <ul className="project-list flex">{outputList(Project2022)}</ul>
            <hr />
            <ul className="project-list flex">
              <li>
                <Link className="flex" to={`/demoday2023`}>
                  <img className="mv-title" src={`${process.env.PUBLIC_URL}/assets/models/textures/demoday_screen_2023.jpg`} />
                  <div className="text-wrapper">
                    <h3>DEMODAY 2023</h3>
                  </div>
                </Link>
              </li>
            </ul>
          </>
        )}

        {year === "2024" && (
          <>
            <p className="title" style={{ color: v.primaryColor23 }}>
              技術シーズ一覧 2023年度
            </p>
            <ul className="project-list flex">{outputList(Project2023)}</ul>
            <hr />
            <ul className="project-list flex">
              <li>
                <Link className="flex" to={`/demoday2024`}>
                  <img className="mv-title" src={`${process.env.PUBLIC_URL}/assets/models/texture2024/demoday_screen_trimed.jpg`} />
                  <div className="text-wrapper">
                    <h3>DEMODAY 2024</h3>
                  </div>
                </Link>
              </li>
            </ul>
          </>
        )}
      </div>
    </STopProjectAllLists>
  );
};
export default TopProjectAllLists;

const STopProjectAllLists = styled.div`
  position: fixed;
  z-index: 6;
  bottom: 36px;
  right: 0;
  left: 0;
  margin: auto;
  opacity: ${({ show }) => (show ? 0 : 1)};
  transform: ${({ show }) => (show ? "translateY(10px)" : "translateY(0px)")};
  transition: all 0.5s 0.5s;
  flex-direction: column !important;
  pointer-events: none;

  @media ${d.maxSp} {
    align-items: center;
    bottom: 70px;
  }

  .project-list-box {
    z-index: 2;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    background-color: #fff;
    width: calc(100% - 36px - 36px);
    height: calc(100vh - 70px - 36px - 36px);
    padding: 36px;
    margin: 0 auto;
    overflow-y: scroll;
    border-radius: 3px;
    @media ${d.maxSp} {
      width: calc(100% - 20px - 20px);
      height: calc(100vh - 200px - 20px - 20px);
      padding: 12px;
    }
  }

  .project-list-box.visible {
    pointer-events: auto;
    opacity: 1;
  }

  .project-list-box.hidden {
    opacity: 0;
  }

  .btn-close {
    position: fixed;
    top: -30px;
    right: 0;
    left: 0;
    margin: auto;
    width: 100px;
    height: 30px;
    background-color: #fff;
    color: #333;
    border-radius: 3px 3px 0 0;
    font-size: 2.5rem;
  }
  .title {
    text-align: center;
    font-size: 1.5em;
    font-weight: bold;
    line-height: 1em;
    margin-bottom: 0.5em;
    @media ${d.maxSp} {
      margin-top: 0.5em;
    }
  }

  ul.project-list.flex {
    li {
      width: 48%;
      margin-top: 12px;
      margin-bottom: 12px;
      @media ${d.maxSp} {
        width: 100%;
      }
      a.flex {
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;

        img {
          width: 140px;
          height: fit-content;
          aspect-ratio: 3 / 2;
          margin-right: 16px;
          @media ${d.maxSp} {
            width: 90px;
          }
        }
        .text-wrapper {
          h3 {
            font-size: 1.4rem;
            padding-top: 0;
            letter-spacing: 0;
            @media ${d.maxSp} {
              line-height: 1.3em;
            }
          }
          h4 {
            padding-top: 0;
            padding-bottom: 0;
            font-weight: normal;
            font-size: 1.3rem;
            letter-spacing: 0;
            @media ${d.maxSp} {
              line-height: 1.3em;
            }
            span {
              display: inline-block;
              margin-right: 0.5em;
            }
          }
        }
      }
    }
  }

  button.flex {
    z-index: 1;
    pointer-events: auto;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;

    flex-direction: column;
    justify-content: center;
    background-color: #fff;

    color: ${v.textColor};
    box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 6px;
    border-radius: 2px;
    padding-right: 1rem;
    padding-left: 1rem;
    font-weight: bold;
    width: 160px;
    &:last-child {
      margin-bottom: 0;
      font-weight: bold;
    }
    @media ${d.maxSp} {
      flex-direction: row;
      align-items: center;
      padding: 1.2rem 1.6rem;
      width: 48%;
      &:last-child {
        margin-bottom: 10px;
      }
    }

    .icon-project-all {
      width: 32px;
      margin-bottom: 0.5em;
      @media ${d.maxSp} {
        width: 20px;
        margin-bottom: 0;
        margin-right: 4px;
      }
    }

    span {
      vertical-align: middle;
      display: inline-block;
      padding: 0 0.2em;
      line-height: 1em;
    }
  }
`;
