import styled from 'styled-components';
import { d } from '../../CssVariables';
import Button from '../atoms/Button';

const UniversityDetail = (props) => {
  const { thisUniversity } = props;


  return (
    <SUniversityDetail>
      <section>
        <div className="flex">
          <div className="col">
          <div className="desc">
            {thisUniversity.desc.map((elem, i) => elem)}
          </div>
            <Button link={thisUniversity.officialUrl}>オフィシャルサイト</Button>
          </div>
          <div className="col">
            <img src={thisUniversity.image} alt=""/>
          </div>
        </div>
      </section>
    </SUniversityDetail>
  )
}
export default UniversityDetail;

const SUniversityDetail = styled.div`
  margin-bottom: 4vw;
  .flex {
    .col:first-child {
      width: 36%;
      @media ${d.maxSp} {
        width: 100%;
      }
    }
    .col:nth-child(2) {
      width: 60%;
      @media ${d.maxSp} {
        width: 100%;
      }
    }
  }
  .desc {
    margin-bottom: 4vw;
  }
`;