import styled from "styled-components";
import { d, v } from "../../../CssVariables";
import { useMediaQuery } from "../../../UseMediaQuery";
import overlay from "../images/overlay_01.png";
import bg from "../images/background_reservation.jpg";
import { IoArrowForwardSharp } from "react-icons/io5";
const MV = () => {
  const mq = useMediaQuery();

  return (
    <SMV id="mv">
      <div className="video-wrapper">
        <video
          autoPlay // オートプレイ。 reactではキャメルケースにする。
          muted // chromeではこれがないとオートプレイされない
          playsInline // 全画面モードにして再生するのではなく、動画ファイルが埋め込まれた表示領域でそのまま動画ファイルを再生することを指す。 モバイルの自動再生では必須。
          loop
          poster="" // 要設定。 映像のダウンロード中に表示される画像の URL を指定
        >
          {mq.isSP && <source src="./assets/demoday2024/videos/movie_sp.mp4"></source>}
          {mq.isTAB && <source src="./assets/demoday2024/videos/movie_pc.mp4"></source>}
        </video>
      </div>

      <div className="object-wrapper">
        <img className="mv-title" src={`${process.env.PUBLIC_URL}/assets/demoday2024/images/mv_title.svg`} />

        <a
          className="btn-to-reservation flex"
          href={"https://forms.office.com/pages/responsepage.aspx?id=BgaLzUoz5ke7n9VwcRig6lenyvQ2A3xNsX9-2P_6Zo9UMVU5VUQ4TDVDTzZTN09JWFE3WFBNQVFaSS4u"}>
          <IoArrowForwardSharp />
          <span>参加申込はこちら</span>
        </a>
      </div>
    </SMV>
  );
};
export default MV;

const SMV = styled.div`
  position: relative;
  /* width: 100vw; */
  width: 100%;
  height: calc(100vh - ${v.headerHeightPc});
  @media ${d.maxTab} {
    height: calc(100vh - ${v.headerHeightSp});
  }

  .video-wrapper {
    position: absolute;
    z-index: 1;
    margin-top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    &:before {
      content: "";
      position: absolute;
      z-index: 101;
      width: 100%;
      height: 100%;
      background-image: url(${overlay});
    }
    video {
      position: absolute;
      z-index: 100;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      min-width: 100%;
      min-height: 100%;
      z-index: 10;
    }
  }

  .object-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;

    img.mv-title {
      position: absolute;
      z-index: 102;
      /* width: 40vw; */
      width: auto;
      height: calc(76vh - ${v.headerHeightPc});
      /* top: calc(50% + 20px); */
      top: 50%;
      left: 5vw;
      transform: translateY(-50%);
      @media ${d.maxTab} {
        width: 80vw;
        height: auto;
      }
    }
    a.btn-to-reservation {
      flex-direction: row;
      position: absolute;
      z-index: 102;
      right: 20px;
      bottom: 20px;
      border-radius: 4px;
      padding: 1.4em 2.4em;
      height: auto;
      background-color: #fff;
      font-size: 1.4em;
      font-weight: bold;
      letter-spacing: 0;
      background-image: url(${bg});
      background-size: cover;
      background-position: center center;
      cursor: default;
      color: #155eb9;
      @media ${d.maxTab} {
        padding: 1em 2em;
      }
    }
  }
`;
