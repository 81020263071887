const Universities = [
   { 
      id: 1,
      name: '神戸大学',
      url: '/kobe-university',
      officialUrl: 'https://www.kobe-u.ac.jp/',
      desc: [
        <p>世界に開かれた港湾都市に位置する神戸大学は、「人文・人間科学系」、「社会科学系」、「自然科学系」、「生命・医学系」の4大学術系列の下に10の学部、15の大学院、1研究環、1研究所と多数のセンターを有する総合大学です。</p>,
      ],
      image: '/assets/images/university/kobe.jpg',
   },
   {
      id: 2,
      name: '大阪工業大学',
      url: '/oit',
      officialUrl: 'https://www.oit.ac.jp/',
      desc: [
        <p>
          大阪工業大学は、母体である関西工学専修学校の創設から数え、2022年に創立100周年を迎えます。創設以来、「世のため、人のため、地域のため、理論に裏付けられた実践的技術を持ち、現場で活躍できる専門職業人を育成する」を建学の精神として掲げ、実学を通じて、世の中の技術革新に貢献をし続けてまいりました。
        </p>,
        <p>
          近年、この、本学の建学の精神である「実学」に根差した研究を広く世の中に活用するため、産学連携活動はもとより、大学発スタートアップ起業についても、2020年度より科学技術振興機構（JST）大学発新事業創出プログラム(START)の支援を受け、神戸大学とタッグを組んで協力に進めております。
        </p>,
      ],
      image: '/assets/images/university/oit.jpg',
  },
]
export default Universities;