import { Route, Routes } from "react-router-dom";

import Header from "./components/organisms/Header";
import Home from "./components/pages/Home";
import About from "./components/pages/About";

import ProjectAll2023 from "./components/pages/ProjectAll2023";
import ProjectAll2022 from "./components/pages/ProjectAll2022";
import ProjectAll2021 from "./components/pages/ProjectAll2021";
import ProjectAll2020 from "./components/pages/ProjectAll2020";
import SingleProject2023 from "./components/pages/SingleProject2023";
import SingleProject2022 from "./components/pages/SingleProject2022";
import SingleProject2021 from "./components/pages/SingleProject2021";
import SingleProject2020 from "./components/pages/SingleProject2020";

import DemoDay2024 from "./components/pages/DemoDay2024";
import DemoDayWatchMovie from "./components/pages/DemoDayWatchMovie";
import DemoDay2023 from "./components/pages/DemoDay2023";
import DemoDay2022 from "./components/pages/DemoDay2022";

import SingleUniversity from "./components/pages/SingleUniversity";

import Page404 from "./components/pages/Page404";
import GlobalStyles from "./GlobalStyles";
import ScrollToTopOnMove from "./ScrollToTopOnMove";
import usePageTracking from "./usePageTracking";

import { AnnounceProvider } from "./providers/AnnounceProvider";
import { YearProvider } from "./providers/YearProvider";

import { Globals } from "@react-spring/shared";

/**
 * three.jsのframeを利用する構成サイトに、react-springを対応させる
 * (これがないとreact-springのアニメーションが動かない)
 * https://github.com/pmndrs/react-spring/issues/1586
 */
Globals.assign({ frameLoop: "always" });

const App = () => {
  usePageTracking();

  return (
    <AnnounceProvider>
      <YearProvider>
        <GlobalStyles />
        <div id="scrollAnchor"></div>
        {/* <ScrollTopButton /> */}
        <ScrollToTopOnMove />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/project-all-2023" element={<ProjectAll2023 />} />
          <Route path="/project-all-2022" element={<ProjectAll2022 />} />
          <Route path="/project-all-2021" element={<ProjectAll2021 />} />
          <Route path="/project-all-2020" element={<ProjectAll2020 />} />

          <Route path="/demoday2024" element={<DemoDay2024 />} />
          <Route path="/demoday2024-watch-movie" element={<DemoDayWatchMovie />} />
          <Route path="/demoday2023" element={<DemoDay2023 />} />
          <Route path="/demoday2022" element={<DemoDay2022 />} />

          <Route path="/project-2023/:projectId" element={<SingleProject2023 />} />
          <Route path="/project-2022/:projectId" element={<SingleProject2022 />} />
          <Route path="/project-2021/:projectId" element={<SingleProject2021 />} />
          <Route path="/project-2020/:projectId" element={<SingleProject2020 />} />
          <Route path="/university/:universityId" element={<SingleUniversity />} />

          <Route path="*" element={<Page404 />} />
        </Routes>
      </YearProvider>
    </AnnounceProvider>
  );
};

export default App;
