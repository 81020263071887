import { useEffect } from "react";
import BalloonBase from "../BalloonBase2022";

const Balloon3 = (props) => {
  const { hover3 } = props;
  useEffect(() => {
    document.body.style.cursor = hover3 ? "pointer" : "default";
  }, [hover3]);

  return <BalloonBase whichHover={hover3}>人工設計ペプチドを用いたレアメタル回収技術の開発とその事業化</BalloonBase>;
};
export default Balloon3;
