import { useEffect } from "react";
import BalloonBase from "../BalloonBase";

const Balloon6 = (props) => {
  
  const {hover6} = props;
  useEffect(() => {
    document.body.style.cursor = hover6 ? "pointer" : "default";
  },[hover6])

  return (
    <BalloonBase whichHover={hover6}>燃料電池の原理(電解法)を用いたバリアフィルムの酸素および燃料分子の透過・拡散係数の評価装置</BalloonBase>
  )
}
export default Balloon6;