
import styled from 'styled-components';
import { d, v } from '../../CssVariables';

import { IoMenuSharp, IoCloseSharp } from "react-icons/io5";

// import { useSpring, a } from 'react-spring';

const MenuToggleButton = (props) => {
  const { toggle, setToggle } = props
  // const [styles, api] = useSpring(() => {
  //   return 
  // });

  return (
    <SMenuToggleButton onClick={setToggle}>
      <button>
        { toggle ? <IoCloseSharp /> : <IoMenuSharp />}
      </button>
    </SMenuToggleButton>
  )
}
export default MenuToggleButton;


/* ---- css ---- */
const SMenuToggleButton = styled.div`
  pointer-events: auto;
  position: fixed;
  z-index: 4;
  width: ${v.headerHeightPc};
  height: ${v.headerHeightPc};
  top: 0;
  right: 0;
  @media ${d.maxSp} {
    width: ${v.headerHeightSp};
    height: ${v.headerHeightSp};
  }
  button {
    border-radius: 1px 0px 1px 1px;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    color: ${v.textColor};
    text-align: center;
    font-size: 3rem;
    padding: 0;
    @media ${d.maxSp} {
      font-size: 2.5rem;
    }
    // border-radius: 50%;
  }
`