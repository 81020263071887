import styled from "styled-components";
import { useLocation, Link } from "react-router-dom";
import { useSpring, a } from "react-spring";

import MenuLists from "../../data/MenuLists.js";

const Menu = (props) => {
  const { toggle, setToggle } = props;
  const location = useLocation();

  const displayLinks = MenuLists.map((item, i) => (
    <li key={i}>
      {item.type === "text" && (
        <Link to={item.url} className={location.pathname === item.url ? "active" : null} onClick={setToggle}>
          {item.name}
        </Link>
      )}
      {item.type === "line" && <hr />}
    </li>
  ));

  const styles = useSpring({
    right: toggle ? "0" : "-300px",
  });

  return (
    <SMenu as={a.nav} style={styles}>
      <ul className="main-menu">{displayLinks}</ul>
    </SMenu>
  );
};
export default Menu;

/* ---- css ---- */
const SMenu = styled.nav`
  pointer-events: auto;
  position: fixed;
  z-index: 1;
  right: 0;
  width: 300px;
  height: 100vh;
  background-color: #fff;
  @media (max-width: 768px) {
  }

  ul {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0 1.5em;
    text-align: right;

    > li {
      display: inline-block;
      padding: 0.25em 0;
      text-align: center;
      margin-bottom: 0;
      width: 100%;
      a {
        font-size: 1.6rem;
        font-weight: bold;
        &.active {
          color: #999;
        }
      }
      hr {
        background-color: #ccc;
        border: none;
        height: 1px;
      }
    }
  }
`;
