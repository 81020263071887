/* eslint eqeqeq: 0 */

import { useParams } from "react-router-dom";
import styled from "styled-components";
import Universities from "../../data/Universities";
import Head from "../../Head";
import TempUnderLayer from "../templates/TempUnderLayer";
import Hero2023 from "../organisms/Hero2023";
import UniversityDetail from "../organisms/UniversityDetail";

const SingleUniversity = () => {
  const { universityId } = useParams();
  const thisUniversity = Universities.find((element) => element.id == universityId);

  return (
    <>
      <Head title={thisUniversity.name} />
      <SUniversity>
        <Hero2023 title={thisUniversity.name} />
        <TempUnderLayer>
          <UniversityDetail thisUniversity={thisUniversity} />
        </TempUnderLayer>
      </SUniversity>
    </>
  );
};
export default SingleUniversity;

const SUniversity = styled.div``;
