import { useEffect } from "react";
import BalloonBase from "../BalloonBase";

const Balloon2 = (props) => {
  
  const {hover2} = props;
  useEffect(() => {
    document.body.style.cursor = hover2 ? "pointer" : "default";
  },[hover2])

  return (
    <BalloonBase whichHover={hover2}>癌幹細胞を標的とした新規癌ウイルス療法薬の開発</BalloonBase>
  )
}
export default Balloon2;