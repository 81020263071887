import styled from 'styled-components';
import { d } from '../../CssVariables';
import BackHomeButton from '../atoms/BackHomeButton';
import Footer from '../organisms/Footer';

const TempUnderLayerMax = (props) => {
  const {children} = props
  return (
    <>
      <SUnderLayerMax style={{position: 'absolute'}}>
        <div className="wrapper">
          <BackHomeButton />
          {children}
        </div>
        <Footer />
      </SUnderLayerMax>
    </>
  )
};
export default TempUnderLayerMax;

const SUnderLayerMax = styled.div`
  cursor: default;
	width: 100%;
	height: auto;
	background-color: #f8f8f8;

  .wrapper {
    margin: 0 auto;
    width: 100%;  
    @media ${d.maxSp} {
      width: 100%;
    }
  }
`