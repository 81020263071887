import { useEffect } from "react";
import BalloonBase from "../BalloonBase";

const Balloon5 = (props) => {
  const { hover5 } = props;
  useEffect(() => {
    document.body.style.cursor = hover5 ? "pointer" : "default";
  }, [hover5]);

  return <BalloonBase whichHover={hover5}>画期的なカーボンニュートラルのエネルギー供給を可能とする日本発の新技術</BalloonBase>;
};
export default Balloon5;
