import styled from "styled-components";
import { d } from "../../CssVariables";

const CopyRight = () => {
  return <SCopyRight className="copyright">&copy; 2024 JST START</SCopyRight>;
};
export default CopyRight;

const SCopyRight = styled.div`
  color: #fff;
  font-size: 0.85em;
  width: 100%;
  pointer-events: none;
  @media ${d.maxTab} {
    display: none;
    text-align: center;
  }
`;
