import { useEffect } from "react";
import BalloonBase from "../BalloonBase2021";

const Balloon12 = (props) => {
  const { hover12 } = props;
  useEffect(() => {
    document.body.style.cursor = hover12 ? "pointer" : "default";
  }, [hover12]);

  return <BalloonBase whichHover={hover12}>高性能・超低消費電力プロセッサアーキテクチャの研究</BalloonBase>;
};
export default Balloon12;
