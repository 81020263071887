import { useEffect } from "react";
import BalloonBase from "../BalloonBase";

const Balloon8 = (props) => {
  const { hover8 } = props;
  useEffect(() => {
    document.body.style.cursor = hover8 ? "pointer" : "default";
  }, [hover8]);

  return <BalloonBase whichHover={hover8}>エンゲージメントやコミュニケーションを促進するデジタルツイン行動変容支援システム</BalloonBase>;
};
export default Balloon8;
