import styled from "styled-components";
import { d, v } from "../../../CssVariables";

const DemodayAbout = () => {
  return (
    <SDemodayAbout id="Demodayabout">
      <div className="flex">
        <div>
          <div className="title-box">
            <div className="title-en-large title-en">ABOUT</div>
            <h2>開催について</h2>
          </div>
        </div>
        <div>
          <p>
            科学技術振興機構(JST)大学発新事業創出プログラム(START)のDEMO DAYを開催します。
            本START事業では、神戸大学と大阪工業大学が共同で、所属する研究者・アントレプレナーの技術シーズを発掘し、起業活動支援を行い、技術シーズやビジネスモデルのブラッシュアップを行うと共に、JST「大学発新産業創出プログラム(START)」の申請やベンチャーキャピタル(VC)から投資を受けられるベンチャーへ橋渡しをすることで、「京阪神連携によるスタートアップ・エコシステム拠点形成(グローバル拠点都市：大阪・京都・ひょうご神戸コンソーシアム)」に貢献することを目的としています。
          </p>
          <p>
            DEMO
            DAYでは、神戸大学・大阪工業大学に所属する研究者・アントレプレナーと、事業プロモーター又は起業家(候補)が組んだ6つのチームが、それぞれの技術シーズをもとにしたベンチャー事業構想を発表します。オンラインでご覧いただけますので、ぜひご参加ください。
          </p>
        </div>
      </div>
    </SDemodayAbout>
  );
};
export default DemodayAbout;

const SDemodayAbout = styled.section`
  padding: 6vw 12vw 6vw;
  margin: 0;
  background-color: #fff;
  .flex {
    flex-wrap: nowrap;
    @media ${d.maxTab} {
      flex-wrap: wrap;
    }
    > div:first-child {
      margin-right: 5vw;
      @media ${d.maxTab} {
        width: 100%;
      }
      @media ${d.maxSp} {
        margin-right: 0;
      }
    }
  }
  .title-en-large {
    color: ${v.primaryColor21};
  }
  h2 {
    font-size: 3.2rem;
    @media ${d.maxSp} {
      font-size: 2.4rem;
    }
  }
  p {
    font-weight: bold;
    @media ${d.minLaptop} {
      &:first-child {
        margin-top: 0;
      }
    }
  }
`;
