import React from "react";
import { useMediaQuery } from "../../UseMediaQuery";

const Lights = () => {
  const mq = useMediaQuery();

  return (
    <>
      {/* {mq.isSP && <fog attach='fog' args={['#ffffff', 400, 600]} />} */}
      {/* {mq.isPC && <fog attach='fog' args={['#ffffff', 140, 180]} />} */}

      <directionalLight
        castShadow
        color={"#ffffff"}
        position={[70, 50, 60]}
        intensity={3.5}
        // 影の解像度
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
        // バイアス モアレ現象に効果がある
        shadow-bias={-0.0005}
        // 影の範囲 (3Dオブジェクトが収まる範囲に設定する)
        shadow-camera-far={500}
        shadow-camera-left={-70}
        shadow-camera-right={70}
        shadow-camera-top={70}
        shadow-camera-bottom={-70}
      />
      <ambientLight color={"#bbbbbb"} />

      {/* <pointLight position={[0, 50, 0]} intensity={2} /> */}
    </>
  );
};
export default Lights;
