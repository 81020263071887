import styled from "styled-components";
import { d, v } from "../../../CssVariables";
import { useMediaQuery } from "../../../UseMediaQuery";
import overlay from "../images/overlay_01.png";

const MV = () => {
  const mq = useMediaQuery();

  return (
    <SMV id="mv">
      <div className="video-wrapper">
        <video
          autoPlay // オートプレイ。 reactではキャメルケースにする。
          muted // chromeではこれがないとオートプレイされない
          playsInline // 全画面モードにして再生するのではなく、動画ファイルが埋め込まれた表示領域でそのまま動画ファイルを再生することを指す。 モバイルの自動再生では必須。
          loop
          poster="" // 要設定。 映像のダウンロード中に表示される画像の URL を指定
        >
          {mq.isSP && <source src="./assets/demoday2023/videos/movie_sp.mp4"></source>}
          {mq.isTAB && <source src="./assets/demoday2023/videos/movie_pc.mp4"></source>}
        </video>
      </div>

      <div className="object-wrapper">
        <img className="mv-title" src={`${process.env.PUBLIC_URL}/assets/demoday2023/images/mv_title.svg`} />

        {/* <a className="btn-to-reservation" href="#reservation">
          <img src={`${process.env.PUBLIC_URL}/assets/demoday2023/images/btn_to_reservation.svg`} alt=""/>
        </a> */}
      </div>
    </SMV>
  );
};
export default MV;

const SMV = styled.div`
  position: relative;
  /* width: 100vw; */
  width: 100%;
  height: calc(100vh - ${v.headerHeightPc});
  @media ${d.maxTab} {
    height: calc(100vh - ${v.headerHeightSp});
  }

  .video-wrapper {
    position: absolute;
    z-index: 1;
    margin-top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    &:before {
      content: "";
      position: absolute;
      z-index: 101;
      width: 100%;
      height: 100%;
      background-image: url(${overlay});
    }
    video {
      position: absolute;
      z-index: 100;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      min-width: 100%;
      min-height: 100%;
      z-index: 10;
    }
  }

  .object-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;

    img.mv-title {
      position: absolute;
      z-index: 102;
      /* width: 40vw; */
      width: auto;
      height: calc(84vh - ${v.headerHeightPc});
      /* top: calc(50% + 20px); */
      top: 50%;
      left: 5vw;
      transform: translateY(-50%);
      @media ${d.maxTab} {
        width: 73vw;
        height: auto;
      }
    }
    a.btn-to-reservation {
      position: absolute;
      z-index: 102;
      right: 50px;
      top: 50px;
      width: 180px;
      height: auto;
      @media ${d.maxTab} {
        top: 16px;
        right: 16px;
        width: 24vw;
        max-width: 140px;
      }
      img {
        width: 100%;
        height: 100%;
        transform: scale(1);
        transition-duration: 0.5s;
        &:hover {
          transform: scale(1.05);
          transition-duration: 0.5s;
        }
      }
    }
  }
`;
