import styled from "styled-components";
import { d, v } from "../../CssVariables";
import CopyRight from "../atoms/CopyRight";

const Footer = () => {
  return (
    <SFooter>
      <CopyRight />
    </SFooter>
  )
}
export default Footer;

const SFooter = styled.footer`
  margin-top: auto;
  width: 100%;
  height: ${v.headerHeightPc};
  @media ${d.maxSp} {
    height: ${v.headerHeightSp};
  }
  padding: 0 40px 0 10vw;
  color: #fff;
  background-color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;