import styled from "styled-components";

import { useEffect } from "react";
import { useSpring, a } from "react-spring";


const MainTitle = (props) => {

  const {children} = props;

  return (
    <SmainTitle className="main-title">{children}</SmainTitle>
  )
}

export default MainTitle;
const SmainTitle = styled.h1`
  color: #fff;
`