import { useEffect } from "react";
import BalloonBase from "../BalloonBase2021";

const Balloon3 = (props) => {
  const { hover3 } = props;
  useEffect(() => {
    document.body.style.cursor = hover3 ? "pointer" : "default";
  }, [hover3]);

  return <BalloonBase whichHover={hover3}>生体由来波形データおよび画像データを使用した疾患診断用マルチモーダル人工知能(AI)の開発</BalloonBase>;
};
export default Balloon3;
