import styled from "styled-components";
import { d, v } from "../../../CssVariables";
import SpeakerList from "../../../data/Project2023";
import overlay from "../images/overlay_01.png";
import bg from "../images/background_speakers.jpg";

const Speakers = () => {
  // 先生を名前順に並び替えるための変数
  const orderId = [2, 5, 1, 8, 3, 7, 4, 6, 9];
  const sortedProjects = SpeakerList.sort((a, b) => orderId.indexOf(a.id) - orderId.indexOf(b.id));

  return (
    <SSpeakers id="speakers" className="flex">
      {/* <img src="./assets/demoday2023/images/circle_03.svg" alt="" className="circle-03" /> */}

      <div className="col-left">
        <div className="title-en">SPEAKERS</div>
      </div>
      <div className="col-right">
        <div className="title-box">
          <h2>
            登壇者情報<span style={{ fontSize: "0.7em" }}>（五十音順）</span>
          </h2>
        </div>

        <div className="speaker-list flex">
          {sortedProjects.map((speaker, index) => (
            <div key={index} className="item col-4 flex">
              <div className="flex">
                <a className="image-box" href={speaker.url}>
                  <img src={speaker.portrait} alt="" />
                </a>
                <div className="desc">
                  <h4>{speaker.title}</h4>
                  <div className="inner">
                    <h3>{speaker.authorName} 氏</h3>
                    <h4>
                      {speaker.authorUniversity && <span>{speaker.authorUniversity}</span>}
                      {speaker.authorAffiliation && <span>{speaker.authorAffiliation}</span>}
                      {speaker.authorPosition && <span>{speaker.authorPosition}</span>}
                    </h4>
                    {speaker.companion && <div className="companion">{speaker.companion}</div>}
                    {speaker.alternatePresenter && <div className="alternate-presenter">{speaker.alternatePresenter}</div>}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </SSpeakers>
  );
};
export default Speakers;
const SSpeakers = styled.section`
  position: relative;
  margin: 0;
  padding: 6vw 4vw;
  /* background-color: #3f7ec3; */
  background-image: url(${bg});
  background-size: cover;
  background-position: center center;
  @media ${d.maxSp} {
    padding: 18vw 8vw;
  }

  img.circle-03 {
    position: absolute;
    bottom: -6vw;
    right: -15vw;
    z-index: 2;
    width: 36vw;
    height: auto;
    max-width: 660px;
    @media ${d.maxTab} {
      bottom: -3vw;
      width: 50vw;
    }
  }

  .col-left {
    z-index: 2;
    width: 12vw;
    @media ${d.maxTab} {
      width: 100%;
    }
    .title-en {
      font-family: "Roboto";
      color: #4490e0;
      font-size: 12vw;
      font-weight: bold;
      letter-spacing: -0.03em;
      transform: rotate(90deg);
      transform-origin: 50% 50%;
      line-height: 12vw;
      @media ${d.maxTab} {
        transform: none;
      }
    }
  }
  .col-right {
    z-index: 2;
    width: calc(100vw - 12vw - 14vw);
    @media ${d.maxTab} {
      width: 100%;
    }
    .title-box {
      margin-bottom: 30px;
    }
    h2 {
      color: #fff;
      font-size: 3.2rem;
      padding: 0;
      @media ${d.maxTab} {
        font-size: 2.4rem;
      }
    }
    .speaker-list {
      .item {
        margin-bottom: 40px;
        text-align: center;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          top: 12px;
          left: 12px;
          width: 100%;
          height: 100%;
          background-image: url(${overlay});
        }

        div.flex {
          flex-direction: column;
          position: relative;
          z-index: 1;

          a.image-box {
            position: relative;
            display: inline-flex;
            width: 100%;
            aspect-ratio: 1 / 1;

            :before {
              content: "研究紹介ページへ";
              position: absolute;
              z-index: 2;
              left: 0;
              bottom: 0;
              width: auto;
              height: auto;
              padding: 0.6em 1.5em 0.6em 2em;
              background-color: ${v.primaryColor23};
              color: #111;
              font-size: 1.5rem;
              font-weight: bold;
            }
            :after {
              content: "";
              position: absolute;
              z-index: 3;
              bottom: calc(0.6em + 4px);
              left: 1em;
              width: 6px;
              height: 6px;
              border-top: solid 1px #111;
              border-right: solid 1px #111;
              transform: rotate(45deg);
            }
            img {
              position: relative;
              z-index: 1;
            }
          }

          .desc {
            flex-grow: 2;
            background-color: ${v.primaryColor23};
            text-align: left;
            > h4 {
              background-color: #174674;
              padding: 1.5rem 2rem;
              color: #fff;
              font-size: 1.6rem;
              text-align: left;
              font-weight: normal;
            }
            .inner {
              padding: 1.5rem 2rem;

              h3 {
                color: #fff;
                padding: 0;
              }
              h4 {
                line-height: 1.5em;
                font-size: 1.5rem;
                color: #fff;
                padding-bottom: 0;
                span {
                  display: inline-block;
                  margin-right: 0.5em;
                }
              }
              hr {
                background-color: ${v.secondaryColor22};
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  .col-4 {
    width: 29%;
    @media ${d.maxTab} {
      width: 90%;
      margin: 0 auto;
    }
  }
`;
