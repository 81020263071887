import { useNavigate } from "react-router-dom";
import { IoArrowBackSharp } from "react-icons/io5";

import styled from "styled-components";
import { d, v } from "../../CssVariables";

const BackHomeButton = () => {
  const navigate = useNavigate();

  const OnClickBackHome = () => navigate("/");

  return (
    <SBackHomeButton onClick={OnClickBackHome}>
      <button>
        <IoArrowBackSharp />
      </button>
      <p>MAP</p>
    </SBackHomeButton>
  );
};
export default BackHomeButton;

const SBackHomeButton = styled.div`
  position: fixed;
  z-index: 9999;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${v.textColor};
  height: ${v.headerHeightPc};
  bottom: 0;
  left: 0;
  padding: 0 1.7rem 0 1.5rem;
  cursor: pointer;
  @media ${d.maxSp} {
    height: ${v.headerHeightSp};
  }
  button {
    background-color: ${v.textColor};
    color: #fff;
    text-align: center;
    font-size: 2rem;
    padding: 0;
    @media ${d.maxSp} {
      font-size: 2.5rem;
    }
    // border-radius: 50%;
  }
  p {
    z-index: 10;
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: 0.2rem;
  }
`;
