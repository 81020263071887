import React from "react";
import * as ReactDOM from "react-dom/client";

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import App from "./App";
import { BrowserRouter } from "react-router-dom";

import "./index.scss";

const root = document.getElementById("root");

if (root) {
  const rootElement = document.createElement("div");
  root.appendChild(rootElement);

  const { createRoot } = ReactDOM;

  const appRoot = createRoot(rootElement);

  appRoot.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}
