/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 /Users/oboshi/Dropbox/inProgress/START/2024/START-2024/public/assets/models/FIELD_2024.gltf --keepnames --keepgroups --shadows --meta 
*/

import React, { useState, useEffect, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

import { useSpring, a } from "@react-spring/three";
import * as easings from "d3-ease";

import gsap from "gsap";

import { useGesture } from "react-use-gesture";
import { v } from "../../CssVariables";

import Balloon1 from "./Balloon/2024/Balloon1";
import Balloon2 from "./Balloon/2024/Balloon2";
import Balloon3 from "./Balloon/2024/Balloon3";
import Balloon4 from "./Balloon/2024/Balloon4";
import Balloon5 from "./Balloon/2024/Balloon5";
import Balloon6 from "./Balloon/2024/Balloon6";
import Balloon7 from "./Balloon/2024/Balloon7";
import Balloon8 from "./Balloon/2024/Balloon8";
import Balloon9 from "./Balloon/2024/Balloon9";
import BalloonDemoday from "./Balloon/2024/BalloonDemoday";

export default function Model(props) {
  const group = useRef();
  const project1 = useRef();
  const project2 = useRef();
  const project3 = useRef();
  const project4 = useRef();
  const project5 = useRef();
  const project6 = useRef();
  const project7 = useRef();
  const project8 = useRef();
  const project9 = useRef();
  const demoday = useRef();

  const { nodes, materials, animations } = useGLTF("/assets/models/FIELD_2024.gltf");
  const { actions } = useAnimations(animations, group);

  useEffect(() => {
    actions.project1Anim.play();
    actions.project2Anim.play();
    actions.project3Anim.play();
    actions.project4Anim.play();
    actions.project5Anim.play();
    actions.project6Anim.play();
    actions.project7Anim.play();
    actions.project8Anim.play();
    actions.project9Anim.play();
    actions.commonAnim.play();
  });

  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [hover3, setHover3] = useState(false);
  const [hover4, setHover4] = useState(false);
  const [hover5, setHover5] = useState(false);
  const [hover6, setHover6] = useState(false);
  const [hover7, setHover7] = useState(false);
  const [hover8, setHover8] = useState(false);
  const [hover9, setHover9] = useState(false);
  const [hoverDemoday, setHoverDemoday] = useState(false);

  /**
   * クリックによるアニメーションと遷移
   * @param {*} item
   * @param {*} clickEventItem
   */
  // setLookAt(positionX, positionY, positionZ, targetX, targetY, targetZ, enableTransition)
  const lookAtFunc = (item) => {
    props.cameraControlRef.current?.setLookAt(
      item.current.position.x,
      item.current.position.z + 20,
      item.current.position.y + 15,
      item.current.position.x,
      item.current.position.z,
      item.current.position.y - 7,
      true
    );
  };

  const operateProject1 = useGesture({
    onPointerOver: () => setHover1(true),
    onPointerOut: () => setHover1(false),
    onClick: () => {
      lookAtFunc(project1);
      setTimeout(() => {
        props.onClickProject1();
      }, 1000);
    },
  });
  const operateProject2 = useGesture({
    onPointerOver: () => setHover2(true),
    onPointerOut: () => setHover2(false),
    onClick: () => {
      lookAtFunc(project2);
      setTimeout(() => {
        props.onClickProject2();
      }, 1000);
    },
  });
  const operateProject3 = useGesture({
    onPointerOver: () => setHover3(true),
    onPointerOut: () => setHover3(false),
    onClick: () => {
      lookAtFunc(project3);
      setTimeout(() => {
        props.onClickProject3();
      }, 1000);
    },
  });
  const operateProject4 = useGesture({
    onPointerOver: () => setHover4(true),
    onPointerOut: () => setHover4(false),
    onClick: () => {
      lookAtFunc(project4);
      setTimeout(() => {
        props.onClickProject4();
      }, 1000);
    },
  });
  const operateProject5 = useGesture({
    onPointerOver: () => setHover5(true),
    onPointerOut: () => setHover5(false),
    onClick: () => {
      lookAtFunc(project5);
      setTimeout(() => {
        props.onClickProject5();
      }, 1000);
    },
  });
  const operateProject6 = useGesture({
    onPointerOver: () => setHover6(true),
    onPointerOut: () => setHover6(false),
    onClick: () => {
      lookAtFunc(project6);
      setTimeout(() => {
        props.onClickProject6();
      }, 1000);
    },
  });
  const operateProject7 = useGesture({
    onPointerOver: () => setHover7(true),
    onPointerOut: () => setHover7(false),
    onClick: () => {
      lookAtFunc(project7);
      setTimeout(() => {
        props.onClickProject7();
      }, 1000);
    },
  });
  const operateProject8 = useGesture({
    onPointerOver: () => setHover8(true),
    onPointerOut: () => setHover8(false),
    onClick: () => {
      lookAtFunc(project8);
      setTimeout(() => {
        props.onClickProject8();
      }, 1000);
    },
  });
  const operateProject9 = useGesture({
    onPointerOver: () => setHover9(true),
    onPointerOut: () => setHover9(false),
    onClick: () => {
      lookAtFunc(project9);
      setTimeout(() => {
        props.onClickProject9();
      }, 1000);
    },
  });
  const operateDemoday = useGesture({
    onPointerOver: () => setHoverDemoday(true),
    onPointerOut: () => setHoverDemoday(false),
    onClick: () => {
      props.cameraControlRef.current?.setLookAt(
        demoday.current.position.x - 6.5,
        demoday.current.position.z + 20,
        demoday.current.position.y + 15,
        demoday.current.position.x - 6.5,
        demoday.current.position.z - 2,
        demoday.current.position.y - 7,
        true
      );
      setTimeout(() => {
        props.onClickDemoday();
      }, 1000);
    },
  });

  const config = { config: { duration: 500, easing: easings.easeBackOut } };

  const { scale1 } = useSpring({ scale1: hover1 ? 1.15 : 1, config });
  const { scale2 } = useSpring({ scale2: hover2 ? 1.15 : 1, config });
  const { scale3 } = useSpring({ scale3: hover3 ? 1.15 : 1, config });
  const { scale4 } = useSpring({ scale4: hover4 ? 1.15 : 1, config });
  const { scale5 } = useSpring({ scale5: hover5 ? 1.15 : 1, config });
  const { scale6 } = useSpring({ scale6: hover6 ? 1.15 : 1, config });
  const { scale7 } = useSpring({ scale7: hover7 ? 1.15 : 1, config });
  const { scale8 } = useSpring({ scale8: hover8 ? 1.15 : 1, config });
  const { scale9 } = useSpring({ scale9: hover9 ? 1.15 : 1, config });

  /**
   * ref
   */
  const field2024Ref = useRef();

  useEffect(() => {
    gsap.to(field2024Ref.current.scale, { z: 1, x: 1, y: 1, duration: 0.8, delay: 0.5, ease: "back.out(1)" });
  }, []);

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group ref={field2024Ref} name="FIELD_2024" rotation={[Math.PI / 2, 0, 0]} scale={[0, 0, 0]} userData={{ name: "FIELD_2024" }}>
          <group ref={demoday} name="COMMON" userData={{ name: "COMMON" }} {...operateDemoday()}>
            <group name="pc" position={[1.764, -0.921, -0.112]} userData={{ name: "pc" }}>
              <group name="monitor" position={[-1.764, 0.921, 0.112]} userData={{ name: "monitor" }}>
                <mesh
                  name="screen"
                  castShadow
                  receiveShadow
                  geometry={nodes.screen.geometry}
                  material={materials.lambert95}
                  position={[-9.635, -6.375, -6.066]}
                  userData={{ name: "screen" }}
                />
              </group>
              <mesh
                name="body"
                castShadow
                receiveShadow
                geometry={nodes.body.geometry}
                material={materials.base_color_2024}
                position={[-11.497, -5.593, -5.073]}
                userData={{ name: "body" }}>
                <meshStandardMaterial color={v.primaryColor23} />
              </mesh>
            </group>
            <mesh
              name="chair_set"
              castShadow
              receiveShadow
              geometry={nodes.chair_set.geometry}
              material={materials.base_color_2024}
              position={[-4.716, 1.517, -2.919]}
              userData={{ name: "chair_set" }}>
              <meshStandardMaterial color={v.primaryColor23} />
            </mesh>
            <mesh
              name="stage"
              castShadow
              receiveShadow
              geometry={nodes.stage.geometry}
              material={materials.base_color_2024}
              position={[-6.658, -1.526, 0.354]}
              userData={{ name: "stage" }}>
              <meshStandardMaterial color={v.primaryColor23} />
            </mesh>
            <mesh
              name="text_demoday"
              castShadow
              receiveShadow
              geometry={nodes.text_demoday.geometry}
              material={materials.White1}
              position={[-7.936, -3.574, -1.023]}
              userData={{ name: "text_demoday" }}
            />
            <BalloonDemoday hoverDemoday={hoverDemoday} />
          </group>
          <group name="ENERGY" userData={{ name: "ENERGY" }}>
            <group name="stage_energy" position={[16.477, 16.489, -0.25]} userData={{ name: "stage_energy" }}>
              <mesh
                name="side_face003"
                castShadow
                receiveShadow
                geometry={nodes.side_face003.geometry}
                material={materials.base_color_2024}
                position={[-13.892, 0.798, 0]}
                userData={{ name: "side_face.003" }}>
                <meshStandardMaterial color={v.primaryColor23} />
              </mesh>
              <mesh
                name="top_face003"
                castShadow
                receiveShadow
                geometry={nodes.top_face003.geometry}
                material={materials.stage1}
                position={[-13.892, 0.798, -0.25]}
                userData={{ name: "top_face.003" }}
              />
            </group>
            <a.mesh
              ref={project5}
              name="project5"
              castShadow
              receiveShadow
              geometry={nodes.project5.geometry}
              material={materials.project_2024_05}
              position={[2.538, 17.942, -3.5]}
              userData={{ name: "project5" }}
              {...operateProject5()}
              scale={scale5}>
              <Balloon5 hover5={hover5} />
            </a.mesh>
            <mesh
              name="text_energy"
              castShadow
              receiveShadow
              geometry={nodes.text_energy.geometry}
              material={materials.base_color_2024}
              position={[2.572, 24.323, -0.55]}
              userData={{ name: "text_energy" }}>
              <meshStandardMaterial color={v.primaryColor23} />
            </mesh>
          </group>
          <group name="IT" userData={{ name: "IT" }}>
            <group name="stage_it" position={[-5.775, 3.463, -0.5]} userData={{ name: "stage_it" }}>
              <mesh
                name="side_face001"
                castShadow
                receiveShadow
                geometry={nodes.side_face001.geometry}
                material={materials.base_color_2024}
                position={[-21.613, -18.599, 0.25]}
                userData={{ name: "side_face.001" }}>
                <meshStandardMaterial color={v.primaryColor23} />
              </mesh>
              <mesh
                name="top_face001"
                castShadow
                receiveShadow
                geometry={nodes.top_face001.geometry}
                material={materials.stage1}
                position={[-21.613, -18.599, 0]}
                userData={{ name: "top_face.001" }}
              />
            </group>
            <a.mesh
              ref={project7}
              name="project7"
              castShadow
              receiveShadow
              geometry={nodes.project7.geometry}
              material={materials.project_2024_07}
              position={[-22.384, -19.667, -3.5]}
              userData={{ name: "project7" }}
              {...operateProject7()}
              scale={scale7}>
              <Balloon7 hover7={hover7} />
            </a.mesh>
            <a.mesh
              ref={project8}
              name="project8"
              castShadow
              receiveShadow
              geometry={nodes.project8.geometry}
              material={materials.project_2024_08}
              position={[-33.539, -12.379, -3.5]}
              userData={{ name: "project8" }}
              {...operateProject8()}
              scale={scale8}>
              <Balloon8 hover8={hover8} />
            </a.mesh>
            <mesh
              name="text_it"
              castShadow
              receiveShadow
              geometry={nodes.text_it.geometry}
              material={materials.base_color_2024}
              position={[-38.793, -7.839, -0.551]}
              userData={{ name: "text_it" }}>
              <meshStandardMaterial color={v.primaryColor23} />
            </mesh>
          </group>
          <group name="LIFE_SCIENCE" userData={{ name: "LIFE_SCIENCE" }}>
            <group name="stage_lifeScience" position={[-27.548, 16.489, -0.25]} userData={{ name: "stage_lifeScience" }}>
              <mesh
                name="side_face002"
                castShadow
                receiveShadow
                geometry={nodes.side_face002.geometry}
                material={materials.base_color_2024}
                position={[-0.69, -3.224, 0]}
                userData={{ name: "side_face.002" }}>
                <meshStandardMaterial color={v.primaryColor23} />
              </mesh>
              <mesh
                name="top_face002"
                castShadow
                receiveShadow
                geometry={nodes.top_face002.geometry}
                material={materials.stage1}
                position={[-0.69, -3.224, -0.25]}
                userData={{ name: "top_face.002" }}
              />
            </group>
            <a.mesh
              ref={project1}
              name="project1"
              castShadow
              receiveShadow
              geometry={nodes.project1.geometry}
              material={materials.project_2024_01}
              position={[-35.12, 19.308, -3.5]}
              userData={{ name: "project1" }}
              {...operateProject1()}
              scale={scale1}>
              <Balloon1 hover1={hover1} />
            </a.mesh>

            <a.mesh
              ref={project3}
              name="project3"
              castShadow
              receiveShadow
              geometry={nodes.project3.geometry}
              material={materials.project_2024_03}
              position={[-22.327, 12.666, -3.5]}
              userData={{ name: "project3" }}
              {...operateProject3()}
              scale={scale3}>
              <Balloon3 hover3={hover3} />
            </a.mesh>
            <a.mesh
              ref={project4}
              name="project4"
              castShadow
              receiveShadow
              geometry={nodes.project4.geometry}
              material={materials.project_2024_04}
              position={[-34.718, 5.24, -3.5]}
              userData={{ name: "project4" }}
              {...operateProject4()}
              scale={scale4}>
              <Balloon4 hover4={hover4} />
            </a.mesh>
            <mesh
              name="text_lifeScience"
              castShadow
              receiveShadow
              geometry={nodes.text_lifeScience.geometry}
              material={materials.base_color_2024}
              position={[-33.461, 24.818, -0.55]}
              userData={{ name: "text_lifeScience" }}>
              <meshStandardMaterial color={v.primaryColor23} />
            </mesh>
          </group>
          <group name="MANUFACTURING" userData={{ name: "MANUFACTURING" }}>
            <group name="stage_manufacturing" position={[15.942, -14.22, -0.25]} userData={{ name: "stage_manufacturing" }}>
              <mesh
                name="side_face"
                castShadow
                receiveShadow
                geometry={nodes.side_face.geometry}
                material={materials.base_color_2024}
                userData={{ name: "side_face" }}>
                <meshStandardMaterial color={v.primaryColor23} />
              </mesh>
              <mesh
                name="top_face"
                castShadow
                receiveShadow
                geometry={nodes.top_face.geometry}
                material={materials.stage1}
                position={[0, 0, -0.25]}
                userData={{ name: "top_face" }}
              />
            </group>
            <a.mesh
              ref={project2}
              name="project2"
              castShadow
              receiveShadow
              geometry={nodes.project2.geometry}
              material={materials.project_2024_02}
              position={[19.171, -9.067, -3.5]}
              userData={{ name: "project2" }}
              {...operateProject2()}
              scale={scale2}>
              <Balloon2 hover2={hover2} />
            </a.mesh>
            <a.mesh
              ref={project6}
              name="project6"
              castShadow
              receiveShadow
              geometry={nodes.project6.geometry}
              material={materials.project_2024_06}
              position={[24.86, -20.597, -3.5]}
              userData={{ name: "project6" }}
              {...operateProject6()}
              scale={scale6}>
              <Balloon6 hover6={hover6} />
            </a.mesh>
            <a.mesh
              ref={project9}
              name="project9"
              castShadow
              receiveShadow
              geometry={nodes.project9.geometry}
              material={materials.project_2024_09}
              position={[6.099, -20.789, -3.5]}
              userData={{ name: "project9" }}
              {...operateProject9()}
              scale={scale9}>
              <Balloon9 hover9={hover9} />
            </a.mesh>
            <mesh
              name="text_manufacturing"
              castShadow
              receiveShadow
              geometry={nodes.text_manufacturing.geometry}
              material={materials.base_color_2024}
              position={[23.869, -3.646, -0.55]}
              userData={{ name: "text_manufacturing" }}>
              <meshStandardMaterial color={v.primaryColor23} />
            </mesh>
          </group>
          <group name="YEARPLATE" userData={{ name: "YEARPLATE" }}>
            <mesh
              name="year_2024"
              castShadow
              receiveShadow
              geometry={nodes.year_2024.geometry}
              material={materials.White1}
              position={[34.193, 6.808, 0]}
              userData={{ name: "year_2024" }}
            />
          </group>
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/assets/models/FIELD_2024.gltf");
