import { useEffect } from "react";
import BalloonBase from "../BalloonBase2021";

const BalloonDemoday = (props) => {
  const { hoverDemoday } = props;
  useEffect(() => {
    document.body.style.cursor = hoverDemoday ? "pointer" : "default";
  }, [hoverDemoday]);

  return <BalloonBase whichHover={hoverDemoday}>DEMO DAY 2021 (YouTube)</BalloonBase>;
};
export default BalloonDemoday;
