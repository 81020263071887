import { createGlobalStyle } from "styled-components";
import { v, d } from "./CssVariables";

const GlobalStyles = createGlobalStyle`

/* ---------- reset ---------- */

// http://meyerweb.com/eric/tools/css/reset/ 
// v2.0 | 20110126
// License: none (public domain)

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* ---------- reset end ---------- */



* {
    box-sizing: border-box;
    /* text-align: justify; */
    font-feature-settings: "palt";
    letter-spacing: 0.05em;
}

.sp-only {
  display: none;
  @media ${d.maxSp} {
    display: block;
  }
}
.pc-only {
  display: block;
  @media ${d.maxSp} {
    display: none;
  }
}


html {
	font-size: 10px;
}
html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  overflow-x: hidden;
}
body {
    font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "MS Pゴシック", "MS PGothic", sans-serif;
    color: #333;
    font-size: 1.4rem;
}

p{
    font-size: 1.5rem;
    padding: 0.4em 0;
    line-height: 1.7em;
    color: ${v.textColor};
    font-weight: 300;
    text-align: left;
}
ul, ol {
    margin-bottom: 1em;

}
ul{
    // list-style: none;
    margin: 0;
    padding: 0;
    li{
      line-height: 1.5em;
      font-size: 1.5rem;
      text-align: left;
      margin-bottom: 0.5em;

    }
}
ol{
    list-style-position: inside;
    list-style-type: decimal;
    margin: 0;
    padding: 0;
    li{
        line-height: 1.5em;
        font-size: 1.6rem;
        text-align: left;
        margin-bottom: 0.5em;
    }
}
a{
    color: ${v.textColor};
    outline: 0;
    text-decoration: none;
}

b {
    font-weight: bold;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto',"游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体","Yu Gothic",YuGothic,"ヒラギノ角ゴ Pro","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"MS Pゴシック","MS PGothic",sans-serif;
  color: ${v.textColor};
  font-weight: bold;
  padding: 0.4em 0;
	line-height: 1.5em;
}

h1 {
	font-size: 2.6rem;
	@media ${d.maxSp} { font-size: 2.2rem; }
}
h2 {
	font-size: 2.2rem;
	@media ${d.maxSp} { font-size: 1.8rem; }
}
h3 {
	font-size: 1.8rem;
	@media ${d.maxSp} { font-size: 1.6rem; }
}
h4 {
	font-size: 1.8rem;
	@media ${d.maxSp} { font-size: 1.6rem; }
}




canvas {
	width: 100%;
	height: 100vh;
}
button {
    padding: 1.5rem 3rem;
    background-color: ${v.primaryColor21};
    border: none;
    cursor: pointer;
    outline: none;
    appearance: none;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	color: #fff;
	&:focus {
		outline:0;
	}
}


img {
    width: 100%;
    height: auto;
}

.width-max {
    margin: 0 calc(50% - 50vw);
    width: 100vw;
}

.flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    @media ${d.maxSp} {
        flex-direction: column;
    }
    .col {
        width: 48%;
        margin-bottom: 0;
        @media ${d.maxSp} {
          width: 100%;
          padding: 1vw 0;
          margin-bottom: 4vw;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    .col-4 {
        width: 31%;
        @media ${d.maxTab} {
          width: 47%;
          padding: 1vw 0;
        }
        @media ${d.maxSp} {
            width: 100%;
        }
        
    }
    .col-6 {
        width: 47%;
        @media ${d.maxTab} {
          width: 100%;
          padding: 1vw 0;
        }
    }

    .col-8 {
        width: 62%;
        @media ${d.maxTab} {
          width: 100%;
          padding: 1vw 0;
        }
    }
    .col-max {
        width: 100%;
        @media ${d.maxSp} {
            padding: 1vw 0;
        }
    }
}

.width-max-border {
    margin: 0 calc(50% - 50vw);
    width: 100vw;
    height: 1px;
    border-bottom: 1px solid ${v.textColor};
}

section {
    margin: 2vw 0;
    @media ${d.maxSp} {
        margin: 8vw 0;
    }
}

.title-box {
    margin-bottom: 2vw;
    @media ${d.maxSp} {
        margin-bottom: 4vw;
    }
}
.title-en {
    letter-spacing: -0.01em;
}


.title-en-large {
		font-family: 'Roboto';
		font-weight: 700;
		font-size: 9rem;
		line-height: 1em;
		@media ${d.maxTab} {
				font-size: 4.5rem;
		}
}
.title-en-small {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 2.1rem;
    line-height: 1.5em;;
    @media ${d.maxSp} {
        font-size: 1.8rem;
    }
}

.center {
    text-align: center;
}


dl {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    @media ${d.maxTab} {
        flex-flow: column;
    }
}
dt {
    flex-basis: 15%;
    padding: 1em 20px;
    @media ${d.maxTab} {
        padding: 1em 10px 0.5em;        
    }
    background-color: #fff;
    font-weight: bold;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    @media ${d.maxTab} {
        border-bottom: none;
    }
    margin-top: -1px;
    line-height: 1.75em;
}
dd {
    flex-basis: 85%;
    padding: 1em 20px;
    @media ${d.maxTab} {
      padding: 0 10px 1em;
    }
    background-color: #fff;
    border-top: 1px solid #ccc;
    @media ${d.maxTab} {
        border-top: none;
    }
    border-bottom: 1px solid #ccc;
    margin-top: -1px;
    line-height: 1.75em;

    ul {
        margin-bottom: 0;
        list-style-type: disc;
        margin-left: 1em;
        li {
            margin-bottom: 0.5em;
            line-height: 1.75em;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.btn-box.flex {
    justify-content: center;
    margin: 0 0 3vw;
    @media ${d.maxTab} {
        margin: 0 0 6vw;
    }
    a {
        margin: 0 1em;
        @media ${d.maxTab} {
            margin: 0 1em 1em;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}


.content-over-box {
    margin: 0 calc(50% - 50vw);
    width: 100vw;
    background: #eee;
        
    .content-over-inner {;
        padding: 2vw 0;
    }
}



`;

export default GlobalStyles;
