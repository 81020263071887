import { useEffect } from "react";
import BalloonBase from "../BalloonBase2021";

const Balloon10 = (props) => {
  const { hover10 } = props;
  useEffect(() => {
    document.body.style.cursor = hover10 ? "pointer" : "default";
  }, [hover10]);

  return <BalloonBase whichHover={hover10}>環境DNA分析手法を用いた生物調査手法の事業化に向けた研究</BalloonBase>;
};
export default Balloon10;
