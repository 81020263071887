import { useEffect } from "react";
import BalloonBase from "../BalloonBase";

const Balloon9 = (props) => {
  const { hover9 } = props;
  useEffect(() => {
    document.body.style.cursor = hover9 ? "pointer" : "default";
  }, [hover9]);

  return <BalloonBase whichHover={hover9}>金型成形と拡散接合による高機能マイクロ流体デバイス量産工程の確立</BalloonBase>;
};
export default Balloon9;
