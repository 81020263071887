/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.1 FIELD_2023.gltf --keepnames --keepgroups --shadows --meta
*/

import React, { useState, useEffect, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

import { useSpring, a } from "@react-spring/three";
import * as easings from "d3-ease";

import gsap from "gsap";

import { useGesture } from "react-use-gesture";
import { v } from "../../CssVariables";

import Balloon1 from "./Balloon/2023/Balloon1";
import Balloon2 from "./Balloon/2023/Balloon2";
import Balloon3 from "./Balloon/2023/Balloon3";
import Balloon4 from "./Balloon/2023/Balloon4";
import Balloon5 from "./Balloon/2023/Balloon5";
import Balloon6 from "./Balloon/2023/Balloon6";
import Balloon7 from "./Balloon/2023/Balloon7";
import BalloonDemoday from "./Balloon/2023/BalloonDemoday";

export default function Model(props) {
  const group = useRef();
  const project1 = useRef();
  const project2 = useRef();
  const project3 = useRef();
  const project4 = useRef();
  const project5 = useRef();
  const project6 = useRef();
  const project7 = useRef();
  const demoday = useRef();

  const { nodes, materials, animations } = useGLTF("/assets/models/FIELD_2023.gltf");
  const { actions } = useAnimations(animations, group);

  useEffect(() => {
    // アニメーションの再生
    // 参考: https://codeworkshop.dev/blog/2021-01-20-react-three-fiber-character-animation/
    // actions.<blenderのアクション名>.play()
    actions.project1Anim.play();
    actions.project2Anim.play();
    actions.project3Anim.play();
    actions.project4Anim.play();
    actions.project5Anim.play();
    actions.project6Anim.play();
    actions.project7Anim.play();
  });

  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [hover3, setHover3] = useState(false);
  const [hover4, setHover4] = useState(false);
  const [hover5, setHover5] = useState(false);
  const [hover6, setHover6] = useState(false);
  const [hover7, setHover7] = useState(false);
  const [hoverDemoday, setHoverDemoday] = useState(false);

  /**
   * クリックによるアニメーションと遷移
   * @param {*} item
   * @param {*} clickEventItem
   */
  // setLookAt(positionX, positionY, positionZ, targetX, targetY, targetZ, enableTransition)
  const lookAtFunc = (item) => {
    props.cameraControlRef.current?.setLookAt(
      item.current.position.x,
      item.current.position.z + 20,
      item.current.position.y + 15,
      item.current.position.x,
      item.current.position.z,
      item.current.position.y - 7,
      true
    );
  };

  const lookAtFuncType2 = (item) => {
    props.cameraControlRef.current?.setLookAt(
      item.current.position.x - 25,
      item.current.position.z + 22,
      item.current.position.y + 42,

      item.current.position.x - 25,
      item.current.position.z - 40,
      item.current.position.y - 22,
      true
    );
  };

  const operateProject1 = useGesture({
    onPointerOver: () => setHover1(true),
    onPointerOut: () => setHover1(false),
    onClick: () => {
      lookAtFuncType2(project1);
      setTimeout(() => {
        props.onClickProject1();
      }, 1000);
    },
  });
  const operateProject2 = useGesture({
    onPointerOver: () => setHover2(true),
    onPointerOut: () => setHover2(false),
    onClick: () => {
      lookAtFuncType2(project2);
      setTimeout(() => {
        props.onClickProject2();
      }, 1000);
    },
  });
  const operateProject3 = useGesture({
    onPointerOver: () => setHover3(true),
    onPointerOut: () => setHover3(false),
    onClick: () => {
      lookAtFunc(project3);
      setTimeout(() => {
        props.onClickProject3();
      }, 1000);
    },
  });
  const operateProject4 = useGesture({
    onPointerOver: () => setHover4(true),
    onPointerOut: () => setHover4(false),
    onClick: () => {
      lookAtFuncType2(project4);
      setTimeout(() => {
        props.onClickProject4();
      }, 1000);
    },
  });
  const operateProject5 = useGesture({
    onPointerOver: () => setHover5(true),
    onPointerOut: () => setHover5(false),
    onClick: () => {
      lookAtFuncType2(project5);
      setTimeout(() => {
        props.onClickProject5();
      }, 1000);
    },
  });
  const operateProject6 = useGesture({
    onPointerOver: () => setHover6(true),
    onPointerOut: () => setHover6(false),
    onClick: () => {
      lookAtFuncType2(project6);
      setTimeout(() => {
        props.onClickProject6();
      }, 1000);
    },
  });
  const operateProject7 = useGesture({
    onPointerOver: () => setHover7(true),
    onPointerOut: () => setHover7(false),
    onClick: () => {
      lookAtFunc(project7);
      setTimeout(() => {
        props.onClickProject7();
      }, 1000);
    },
  });
  const operateDemoday = useGesture({
    onPointerOver: () => setHoverDemoday(true),
    onPointerOut: () => setHoverDemoday(false),
    onClick: () => {
      props.cameraControlRef.current?.setLookAt(
        demoday.current.position.x - 5,
        demoday.current.position.z + 35,
        demoday.current.position.y + 25,
        demoday.current.position.x - 5,
        demoday.current.position.z - 2,
        demoday.current.position.y - 20,
        true
      );
      setTimeout(() => {
        props.onClickDemoday();
      }, 1000);
    },
  });

  const config = { config: { duration: 500, easing: easings.easeBackOut } };

  const { scale1 } = useSpring({ scale1: hover1 ? 1.15 : 1, config });
  const { scale2 } = useSpring({ scale2: hover2 ? 1.15 : 1, config });
  const { scale3 } = useSpring({ scale3: hover3 ? 1.15 : 1, config });
  const { scale4 } = useSpring({ scale4: hover4 ? 1.15 : 1, config });
  const { scale5 } = useSpring({ scale5: hover5 ? 1.15 : 1, config });
  const { scale6 } = useSpring({ scale6: hover6 ? 1.15 : 1, config });
  const { scale7 } = useSpring({ scale7: hover7 ? 1.15 : 1, config });

  /**
   * ref
   */
  const field2023Ref = useRef();

  useEffect(() => {
    gsap.to(field2023Ref.current.scale, { z: 1, x: 1, y: 1, duration: 0.8, delay: 0.5, ease: "back.out(1)" });
  }, []);

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group ref={field2023Ref} scale={[0, 0, 0]} name="FIELD_2023" rotation={[Math.PI / 2, 0, 0]} userData={{ name: "FIELD_2023" }}>
          <group name="COMMON" position={[-11.65, -14.78, 0]} userData={{ name: "COMMON" }}>
            <group ref={demoday} name="theater" position={[-23.31, -9.02, -5]} rotation={[0, 0, 0.53]} userData={{ name: "theater" }} {...operateDemoday()}>
              <mesh
                name="chair"
                castShadow
                receiveShadow
                geometry={nodes.chair.geometry}
                material={materials.base_color_2023}
                position={[12.46, 8.84, 1.86]}
                userData={{ name: "chair" }}>
                <meshStandardMaterial color={v.primaryColor22} />
              </mesh>

              <mesh
                name="screen"
                castShadow
                receiveShadow
                geometry={nodes.screen.geometry}
                material={materials.demoday_screen_2023}
                position={[5.4, -3.27, -2.16]}
                userData={{ name: "screen" }}
              />

              <mesh
                name="speaker_desk"
                castShadow
                receiveShadow
                geometry={nodes.speaker_desk.geometry}
                material={materials.base_color_2023}
                position={[16.31, -5.76, 1.2]}
                userData={{ name: "speaker_desk" }}>
                <meshStandardMaterial color={v.primaryColor22} />
              </mesh>

              <mesh
                name="text_demoday"
                castShadow
                receiveShadow
                geometry={nodes.text_demoday.geometry}
                material={materials.White}
                position={[6.28, -0.58, 2.95]}
                userData={{ name: "text_demoday" }}
              />

              <mesh
                name="theater_base"
                castShadow
                receiveShadow
                geometry={nodes.theater_base.geometry}
                material={materials.base_color_2023}
                position={[10.12, 4.38, 2.74]}
                userData={{ name: "theater_base" }}>
                <meshStandardMaterial color={v.primaryColor22} />
              </mesh>

              <BalloonDemoday hoverDemoday={hoverDemoday} />
            </group>

            <mesh
              name="tree"
              castShadow
              receiveShadow
              geometry={nodes.tree.geometry}
              material={materials.base_color_2023}
              position={[9.35, 9.99, -2.41]}
              userData={{ name: "tree" }}>
              {/* ベイクしていないので、metalnessで誤魔化す */}
              <meshStandardMaterial color={v.primaryColor22} metalness={0.45} />
            </mesh>
          </group>

          <group name="LIFE_SCIENCE" position={[-24.96, 27.11, 0]} userData={{ name: "LIFE_SCIENCE" }}>
            <group name="stage_lifeScience" position={[24.96, -27.11, 0]} userData={{ name: "stage_lifeScience" }}>
              <mesh
                name="side_face"
                castShadow
                receiveShadow
                geometry={nodes.side_face.geometry}
                material={materials.base_color_2023}
                position={[-13.35, 9.55, -0.25]}
                userData={{ name: "side_face" }}
              />
              <mesh
                name="top_face"
                castShadow
                receiveShadow
                geometry={nodes.top_face.geometry}
                material={materials.stage1}
                position={[-13.35, 9.55, -0.5]}
                userData={{ name: "top_face" }}
              />
            </group>
            <a.mesh
              ref={project1}
              name="project1"
              castShadow
              receiveShadow
              geometry={nodes.project1.geometry}
              material={materials.project_2023_01}
              position={[22.75, -5.73, -3.5]}
              userData={{ name: "project1" }}
              {...operateProject1()}
              scale={scale1}>
              <Balloon1 hover1={hover1} />
            </a.mesh>
            <a.mesh
              ref={project2}
              name="project2"
              castShadow
              receiveShadow
              geometry={nodes.project2.geometry}
              material={materials.project_2023_02}
              position={[2.64, -7.6, -3.5]}
              userData={{ name: "project2" }}
              {...operateProject2()}
              scale={scale2}>
              <Balloon2 hover2={hover2} />
            </a.mesh>
            <a.mesh
              ref={project4}
              name="project4"
              castShadow
              receiveShadow
              geometry={nodes.project4.geometry}
              material={materials.project_2023_04}
              position={[25.74, -17.06, -3.5]}
              userData={{ name: "project4" }}
              {...operateProject4()}
              scale={scale4}>
              <Balloon4 hover4={hover4} />
            </a.mesh>
            <a.mesh
              ref={project5}
              name="project5"
              castShadow
              receiveShadow
              geometry={nodes.project5.geometry}
              material={materials.project_2023_05}
              position={[-10.16, -17.03, -3.5]}
              userData={{ name: "project5" }}
              {...operateProject5()}
              scale={scale5}>
              <Balloon5 hover5={hover5} />
            </a.mesh>
            {/* project6は本来はものづくり枠 ↓ */}
            <a.mesh
              ref={project6}
              name="project6"
              castShadow
              receiveShadow
              geometry={nodes.project6.geometry}
              material={materials.project_2023_06}
              position={[33.62, -48.13, -3.5]}
              userData={{ name: "project6" }}
              {...operateProject6()}
              scale={scale6}>
              <Balloon6 hover6={hover6} />
            </a.mesh>

            <mesh
              name="text_lifeScience"
              castShadow
              receiveShadow
              geometry={nodes.text_lifeScience.geometry}
              material={materials.base_color_2023}
              position={[-5.89, -0.98, -0.55]}
              userData={{ name: "text_lifeScience" }}
            />
          </group>

          <group name="MANUFACTURING" userData={{ name: "MANUFACTURING" }}>
            <group name="stage_manufacturing" position={[18.77, -16.6, 0]} userData={{ name: "stage_manufacturing" }}>
              <mesh
                name="side_face001"
                castShadow
                receiveShadow
                geometry={nodes.side_face001.geometry}
                material={materials.base_color_2023}
                position={[-3.6, 0.56, -0.25]}
                userData={{ name: "side_face.001" }}
              />
              <mesh
                name="top_face001"
                castShadow
                receiveShadow
                geometry={nodes.top_face001.geometry}
                material={materials.stage1}
                position={[-3.6, 0.56, -0.5]}
                userData={{ name: "top_face.001" }}
              />
            </group>
            <a.mesh
              ref={project3}
              name="project3"
              castShadow
              receiveShadow
              geometry={nodes.project3.geometry}
              material={materials.project_2023_03}
              position={[25.65, -14.61, -3.5]}
              userData={{ name: "project3" }}
              {...operateProject3()}
              scale={scale3}>
              <Balloon3 hover3={hover3} />
            </a.mesh>

            {/* project7は本来はライフサイエンス枠 ↓ */}
            <a.mesh
              ref={project7}
              name="project7"
              castShadow
              receiveShadow
              geometry={nodes.project7.geometry}
              material={materials.project_2023_07}
              position={[-17.07, 7.3, -3.5]}
              userData={{ name: "project7" }}
              {...operateProject7()}
              scale={scale7}>
              <Balloon7 hover7={hover7} />
            </a.mesh>
            <mesh
              name="text_manufacturing"
              castShadow
              receiveShadow
              geometry={nodes.text_manufacturing.geometry}
              material={materials.base_color_2023}
              position={[28.57, -5.73, -0.55]}
              userData={{ name: "text_manufacturing" }}
            />
          </group>

          <group name="YEARPLATE" position={[-11.65, -14.78, 0]} userData={{ name: "YEARPLATE" }}>
            <mesh
              name="year_2022"
              castShadow
              receiveShadow
              geometry={nodes.year_2022.geometry}
              material={materials.White1}
              position={[50.02, 19.81, -0.05]}
              userData={{ name: "year_2022" }}
            />
          </group>
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/assets/models/FIELD_2023.gltf");
