import { useEffect } from "react";
import BalloonBase from "../BalloonBase";

const Balloon3 = (props) => {
  
  const {hover3} = props;
  useEffect(() => {
    document.body.style.cursor = hover3 ? "pointer" : "default";
  },[hover3])

  return (
    <BalloonBase whichHover={hover3}>構造発色する無機ナノ粒子の色材としての事業化の検討</BalloonBase>
  )
}
export default Balloon3;