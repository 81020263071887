import { useEffect } from "react";
import BalloonBase from "../BalloonBase2021";

const Balloon15 = (props) => {
  const { hover15 } = props;
  useEffect(() => {
    document.body.style.cursor = hover15 ? "pointer" : "default";
  }, [hover15]);

  return <BalloonBase whichHover={hover15}>光照射によるカーボンカプセル材料創出法の開発</BalloonBase>;
};
export default Balloon15;
