import { useEffect } from "react";
import BalloonBase from "../BalloonBase2021";

const Balloon4 = (props) => {
  const { hover4 } = props;
  useEffect(() => {
    document.body.style.cursor = hover4 ? "pointer" : "default";
  }, [hover4]);

  return <BalloonBase whichHover={hover4}>性ステロイドホルモン増大による新規2型糖尿病予防法の開発</BalloonBase>;
};
export default Balloon4;
