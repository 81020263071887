import styled from 'styled-components';
import { d } from '../../CssVariables';
import BackHomeButton from '../atoms/BackHomeButton';
import Footer from '../organisms/Footer';

const TempUnderLayer = (props) => {
  const {children} = props
  return (
    <>
      <SUnderLayer style={{position: 'absolute'}}>
        <div className="wrapper">
          <BackHomeButton />
          {children}
        </div>
        <Footer />
      </SUnderLayer>
    </>
  )
};
export default TempUnderLayer;

const SUnderLayer = styled.div`
  cursor: default;
	width: 100%;
	height: auto;
	background-color: #f8f8f8;

  .wrapper {
    margin: 0 auto;
    width: calc(100% - 20%);  
    width: 960px;  
    @media ${d.maxSp} {
      width: calc(100% - 12%);
    }
  }
`