import { useEffect } from "react";
import BalloonBase from "../BalloonBase";

const Balloon7 = (props) => {
  const { hover7 } = props;
  useEffect(() => {
    document.body.style.cursor = hover7 ? "pointer" : "default";
  }, [hover7]);

  return <BalloonBase whichHover={hover7}>組織のサイバーレジリエンスを向上させるサイバーインテリジェンス情報サービスの構築</BalloonBase>;
};
export default Balloon7;
