/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 /Users/oboshi/Dropbox/inProgress/START/2024/START-2024/public/assets/models/FIELD_2021.gltf --keepnames --keepgroups --shadows --meta 
*/

import React, { useState, useEffect, useRef } from "react";

import { useGLTF, useAnimations } from "@react-three/drei";
import { useSpring, a } from "@react-spring/three";
import * as easings from "d3-ease";

import gsap from "gsap";

//useGesture = 複数のイベントをバインドする。
import { useGesture } from "react-use-gesture";

import Balloon1 from "./Balloon/2021/Balloon1";
import Balloon2 from "./Balloon/2021/Balloon2";
import Balloon3 from "./Balloon/2021/Balloon3";
import Balloon4 from "./Balloon/2021/Balloon4";
import Balloon5 from "./Balloon/2021/Balloon5";
import Balloon6 from "./Balloon/2021/Balloon6";
import Balloon7 from "./Balloon/2021/Balloon7";
import Balloon8 from "./Balloon/2021/Balloon8";
import Balloon9 from "./Balloon/2021/Balloon9";
import Balloon10 from "./Balloon/2021/Balloon10";
import Balloon11 from "./Balloon/2021/Balloon11";
import Balloon12 from "./Balloon/2021/Balloon12";
import Balloon13 from "./Balloon/2021/Balloon13";
import Balloon14 from "./Balloon/2021/Balloon14";
import Balloon15 from "./Balloon/2021/Balloon15";
import Balloon16 from "./Balloon/2021/Balloon16";
import BalloonDemoday from "./Balloon/2021/BalloonDemoday";

export default function Model(props) {
  const group = useRef();
  const project1 = useRef();
  const project2 = useRef();
  const project3 = useRef();
  const project4 = useRef();
  const project5 = useRef();
  const project6 = useRef();
  const project7 = useRef();
  const project8 = useRef();
  const project9 = useRef();
  const project10 = useRef();
  const project11 = useRef();
  const project12 = useRef();
  const project13 = useRef();
  const project14 = useRef();
  const project15 = useRef();
  const project16 = useRef();
  const demoday = useRef();

  const { nodes, materials, animations } = useGLTF("/assets/models/FIELD_2021.gltf");
  const { actions } = useAnimations(animations, group);

  useEffect(() => {
    // アニメーションの再生
    // 参考: https://codeworkshop.dev/blog/2021-01-20-react-three-fiber-character-animation/
    // actions.<blenderのアクション名>.play()
    actions.domeAnim.play();
    actions.dome_dammyAnim.play();
    actions.project1Anim.play();
    actions.project2Anim.play();
    actions.project3Anim.play();
    actions.project4Anim.play();
    actions.Action.play(); // プロジェクト5のアニメーション
    actions.project6Anim.play();
    actions.project7Anim.play();
    actions.project8Anim.play();
    actions.project9Anim.play();
    actions.project10Anim.play();
    actions.project11Anim.play();
    actions.project12Anim.play();
    actions.project13Anim.play();
    actions.project14Anim.play();
    actions.project15Anim.play();
    actions.project16Anim.play();
  });

  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [hover3, setHover3] = useState(false);
  const [hover4, setHover4] = useState(false);
  const [hover5, setHover5] = useState(false);
  const [hover6, setHover6] = useState(false);
  const [hover7, setHover7] = useState(false);
  const [hover8, setHover8] = useState(false);
  const [hover9, setHover9] = useState(false);
  const [hover10, setHover10] = useState(false);
  const [hover11, setHover11] = useState(false);
  const [hover12, setHover12] = useState(false);
  const [hover13, setHover13] = useState(false);
  const [hover14, setHover14] = useState(false);
  const [hover15, setHover15] = useState(false);
  const [hover16, setHover16] = useState(false);
  const [hoverDemoday, setHoverDemoday] = useState(false);

  /**
   * クリックによるアニメーションと遷移
   * @param {*} item
   * @param {*} clickEventItem
   */
  // setLookAt(positionX, positionY, positionZ, targetX, targetY, targetZ, enableTransition)
  // const lookAtFunc = (item) => {
  //   props.cameraControlRef.current?.setLookAt(
  //     item.current.position.x,
  //     item.current.position.z + 15,
  //     item.current.position.y + 10,
  //     item.current.position.x,
  //     item.current.position.z + 4.5,
  //     item.current.position.y - 3.5,
  //     true
  //   );
  // };
  const lookAtFunc = (item) => {
    props.cameraControlRef.current?.setLookAt(
      item.current.position.x,
      item.current.position.y + 10,
      item.current.position.z + 15,
      item.current.position.x,
      item.current.position.y + 2.5,
      item.current.position.z + 5,
      true
    );
  };

  const operateProject1 = useGesture({
    onPointerOver: () => setHover1(true),
    onPointerOut: () => setHover1(false),
    onClick: () => {
      lookAtFunc(project1);
      setTimeout(() => {
        props.onClickProject1();
      }, 1000);
    },
  });
  const operateProject2 = useGesture({
    onPointerOver: () => setHover2(true),
    onPointerOut: () => setHover2(false),
    onClick: () => {
      lookAtFunc(project2);
      setTimeout(() => {
        props.onClickProject2();
      }, 1000);
    },
  });
  const operateProject3 = useGesture({
    onPointerOver: () => setHover3(true),
    onPointerOut: () => setHover3(false),
    onClick: () => {
      lookAtFunc(project3);
      setTimeout(() => {
        props.onClickProject3();
      }, 1000);
    },
  });
  const operateProject4 = useGesture({
    onPointerOver: () => setHover4(true),
    onPointerOut: () => setHover4(false),
    onClick: () => {
      lookAtFunc(project4);
      setTimeout(() => {
        props.onClickProject4();
      }, 1000);
    },
  });
  const operateProject5 = useGesture({
    onPointerOver: () => setHover5(true),
    onPointerOut: () => setHover5(false),
    onClick: () => {
      lookAtFunc(project5);
      setTimeout(() => {
        props.onClickProject5();
      }, 1000);
    },
  });
  const operateProject6 = useGesture({
    onPointerOver: () => setHover6(true),
    onPointerOut: () => setHover6(false),
    onClick: () => {
      lookAtFunc(project6);
      setTimeout(() => {
        props.onClickProject6();
      }, 1000);
    },
  });
  const operateProject7 = useGesture({
    onPointerOver: () => setHover7(true),
    onPointerOut: () => setHover7(false),
    onClick: () => {
      lookAtFunc(project7);
      setTimeout(() => {
        props.onClickProject7();
      }, 1000);
    },
  });
  const operateProject8 = useGesture({
    onPointerOver: () => setHover8(true),
    onPointerOut: () => setHover8(false),
    onClick: () => {
      lookAtFunc(project8);
      setTimeout(() => {
        props.onClickProject8();
      }, 1000);
    },
  });
  const operateProject9 = useGesture({
    onPointerOver: () => setHover9(true),
    onPointerOut: () => setHover9(false),
    onClick: () => {
      lookAtFunc(project9);
      setTimeout(() => {
        props.onClickProject9();
      }, 1000);
    },
  });
  const operateProject10 = useGesture({
    onPointerOver: () => setHover10(true),
    onPointerOut: () => setHover10(false),
    onClick: () => {
      lookAtFunc(project10);
      setTimeout(() => {
        props.onClickProject10();
      }, 1000);
    },
  });
  const operateProject11 = useGesture({
    onPointerOver: () => setHover11(true),
    onPointerOut: () => setHover11(false),
    onClick: () => {
      lookAtFunc(project11);
      setTimeout(() => {
        props.onClickProject11();
      }, 1000);
    },
  });
  const operateProject12 = useGesture({
    onPointerOver: () => setHover12(true),
    onPointerOut: () => setHover12(false),
    onClick: () => {
      lookAtFunc(project12);
      setTimeout(() => {
        props.onClickProject12();
      }, 1000);
    },
  });
  const operateProject13 = useGesture({
    onPointerOver: () => setHover13(true),
    onPointerOut: () => setHover13(false),
    onClick: () => {
      lookAtFunc(project13);
      setTimeout(() => {
        props.onClickProject13();
      }, 1000);
    },
  });
  const operateProject14 = useGesture({
    onPointerOver: () => setHover14(true),
    onPointerOut: () => setHover14(false),
    onClick: () => {
      lookAtFunc(project14);
      setTimeout(() => {
        props.onClickProject14();
      }, 1000);
    },
  });
  const operateProject15 = useGesture({
    onPointerOver: () => setHover15(true),
    onPointerOut: () => setHover15(false),
    onClick: () => {
      lookAtFunc(project15);
      setTimeout(() => {
        props.onClickProject15();
      }, 1000);
    },
  });
  const operateProject16 = useGesture({
    onPointerOver: () => setHover16(true),
    onPointerOut: () => setHover16(false),
    onClick: () => {
      lookAtFunc(project16);
      setTimeout(() => {
        props.onClickProject16();
      }, 1000);
    },
  });

  const operateDemoday = useGesture({
    onPointerOver: () => setHoverDemoday(true),
    onPointerOut: () => setHoverDemoday(false),
    onClick: () => {
      // props.cameraControlRef.current?.setLookAt(
      //   demoday.current.position.x,
      //   demoday.current.position.z + 20,
      //   demoday.current.position.y + 20,
      //   demoday.current.position.x,
      //   demoday.current.position.z + 3,
      //   demoday.current.position.y - 7,
      //   true
      // );
      setTimeout(() => {
        props.onClickDemoday();
      }, 0);
    },
  });

  const config = { config: { duration: 500, easing: easings.easeBackOut } };

  const { scale1 } = useSpring({ scale1: hover1 ? 1.15 : 1, config });
  const { scale2 } = useSpring({ scale2: hover2 ? 1.15 : 1, config });
  const { scale3 } = useSpring({ scale3: hover3 ? 1.15 : 1, config });
  const { scale4 } = useSpring({ scale4: hover4 ? 1.15 : 1, config });
  const { scale5 } = useSpring({ scale5: hover5 ? 1.15 : 1, config });
  const { scale6 } = useSpring({ scale6: hover6 ? 1.15 : 1, config });
  const { scale7 } = useSpring({ scale7: hover7 ? 1.15 : 1, config });
  const { scale8 } = useSpring({ scale8: hover8 ? 1.15 : 1, config });
  const { scale9 } = useSpring({ scale9: hover9 ? 1.15 : 1, config });
  const { scale10 } = useSpring({ scale10: hover10 ? 1.15 : 1, config });
  const { scale11 } = useSpring({ scale11: hover11 ? 1.15 : 1, config });
  const { scale12 } = useSpring({ scale12: hover12 ? 1.15 : 1, config });
  const { scale13 } = useSpring({ scale13: hover13 ? 1.15 : 1, config });
  const { scale14 } = useSpring({ scale14: hover14 ? 1.15 : 1, config });
  const { scale15 } = useSpring({ scale15: hover15 ? 1.15 : 1, config });
  const { scale16 } = useSpring({ scale16: hover16 ? 1.15 : 1, config });

  /**
   * ref
   */
  const field2021Ref = useRef();
  useEffect(() => {
    gsap.to(field2021Ref.current.scale, { z: 1, x: 1, y: 1, duration: 0.8, delay: 0.5, ease: "back.out(1)" });
  }, []);

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group ref={field2021Ref} name="FIELD_2021" rotation={[0, 0, 0]} scale={0} userData={{ name: "FIELD_2021" }}>
          <group name="COMMON" userData={{ name: "COMMON" }}>
            <group name="dome" position={[-12.797, 10.819, -0.018]} userData={{ name: "dome" }}>
              <mesh name="メッシュ144" castShadow receiveShadow geometry={nodes.メッシュ144.geometry} material={materials.black} />
              <mesh name="メッシュ144_1" castShadow receiveShadow geometry={nodes.メッシュ144_1.geometry} material={materials.demoday_screen_2021} />
              <mesh name="メッシュ144_2" castShadow receiveShadow geometry={nodes.メッシュ144_2.geometry} material={materials.White1} />
              <mesh name="メッシュ144_3" castShadow receiveShadow geometry={nodes.メッシュ144_3.geometry} material={materials.base_color_2020} />
              <mesh name="メッシュ144_4" castShadow receiveShadow geometry={nodes.メッシュ144_4.geometry} material={materials.dark_gray} />
            </group>
            <mesh
              name="stage"
              castShadow
              receiveShadow
              geometry={nodes.stage.geometry}
              material={materials.base_color_2020}
              position={[-12.8, 0.3, 0]}
              userData={{ name: "stage" }}
            />
          </group>
          <group name="DAMMY" userData={{ name: "DAMMY" }}>
            <mesh
              name="dome_dammy"
              castShadow
              receiveShadow
              geometry={nodes.dome_dammy.geometry}
              material={materials.base_color_2020}
              position={[-12.796, 4.375, -0.004]}
              userData={{ name: "dome_dammy" }}
              {...operateDemoday()}>
              <meshPhongMaterial color="#ffffff" opacity={0} transparent />
              <BalloonDemoday hoverDemoday={hoverDemoday} />
            </mesh>
          </group>

          <group name="ENERGY" userData={{ name: "ENERGY" }}>
            <group name="stage_energy" userData={{ name: "stage_energy" }}>
              <mesh
                name="side_face003"
                castShadow
                receiveShadow
                geometry={nodes.side_face003.geometry}
                material={materials.base_color_2020}
                position={[-31.768, 0.243, 0]}
                userData={{ name: "side_face.003" }}
              />
              <mesh
                name="top_face003"
                castShadow
                receiveShadow
                geometry={nodes.top_face003.geometry}
                material={materials.White1}
                position={[-31.768, 0.493, 0]}
                userData={{ name: "top_face.003" }}
              />
            </group>
            <a.mesh
              ref={project5}
              name="project5"
              castShadow
              receiveShadow
              geometry={nodes.project5.geometry}
              material={materials.project_2021_05}
              position={[-33.3, 3.5, -0.3]}
              userData={{ name: "project5" }}
              {...operateProject5()}
              scale={scale5}>
              <Balloon5 hover5={hover5} />
            </a.mesh>
            <mesh
              name="text_energy"
              castShadow
              receiveShadow
              geometry={nodes.text_energy.geometry}
              material={materials.base_color_2020}
              position={[-31.512, 0.55, 4.443]}
              userData={{ name: "text_energy" }}
            />
          </group>
          <group name="IT" userData={{ name: "IT" }}>
            <group name="stage_manufacturing" userData={{ name: "stage_manufacturing" }}>
              <mesh
                name="side_face002"
                castShadow
                receiveShadow
                geometry={nodes.side_face002.geometry}
                material={materials.base_color_2020}
                position={[-0.193, 0.25, 16.137]}
                userData={{ name: "side_face.002" }}
              />
              <mesh
                name="top_face002"
                castShadow
                receiveShadow
                geometry={nodes.top_face002.geometry}
                material={materials.White1}
                position={[-0.193, 0.5, 16.137]}
                userData={{ name: "top_face.002" }}
              />
            </group>
            <a.mesh
              ref={project12}
              name="project12"
              castShadow
              receiveShadow
              geometry={nodes.project12.geometry}
              material={materials.project_2021_12}
              position={[-3.456, 3.5, 21.7]}
              userData={{ name: "project12" }}
              {...operateProject12()}
              scale={scale12}>
              <Balloon12 hover12={hover12} />
            </a.mesh>
            <a.mesh
              ref={project14}
              name="project14"
              castShadow
              receiveShadow
              geometry={nodes.project14.geometry}
              material={materials.project_2021_14}
              position={[5.836, 3.5, 15.726]}
              userData={{ name: "project14" }}
              {...operateProject14()}
              scale={scale14}>
              <Balloon14 hover14={hover14} />
            </a.mesh>
            <mesh
              name="text_it"
              castShadow
              receiveShadow
              geometry={nodes.text_it.geometry}
              material={materials.base_color_2020}
              position={[-4.795, 0.551, 26.293]}
              userData={{ name: "text_it" }}
            />
          </group>
          <group name="LIFE_SCIENCE" userData={{ name: "LIFE_SCIENCE" }}>
            <group name="stage_lifeScience" userData={{ name: "stage_lifeScience" }}>
              <mesh
                name="side_face"
                castShadow
                receiveShadow
                geometry={nodes.side_face.geometry}
                material={materials.base_color_2020}
                position={[2.205, 0.25, -12.034]}
                userData={{ name: "side_face" }}
              />
              <mesh
                name="top_face"
                castShadow
                receiveShadow
                geometry={nodes.top_face.geometry}
                material={materials.White1}
                position={[2.205, 0.5, -12.034]}
                userData={{ name: "top_face" }}
              />
            </group>
            <a.mesh
              ref={project1}
              name="project1"
              castShadow
              receiveShadow
              geometry={nodes.project1.geometry}
              material={materials.project_2021_01}
              position={[28.729, 3.5, -8.816]}
              userData={{ name: "project1" }}
              {...operateProject1()}
              scale={scale1}>
              <Balloon1 hover1={hover1} />
            </a.mesh>
            <a.mesh
              ref={project11}
              name="project11"
              castShadow
              receiveShadow
              geometry={nodes.project11.geometry}
              material={materials.project_2021_11}
              position={[7.328, 3.5, -23.799]}
              userData={{ name: "project11" }}
              {...operateProject11()}
              scale={scale11}>
              <Balloon11 hover11={hover11} />
            </a.mesh>
            <a.mesh
              ref={project13}
              name="project13"
              castShadow
              receiveShadow
              geometry={nodes.project13.geometry}
              material={materials.project_2021_13}
              position={[14.782, 3.5, -15.846]}
              userData={{ name: "project13" }}
              {...operateProject13()}
              scale={scale13}>
              <Balloon13 hover13={hover13} />
            </a.mesh>
            <a.mesh
              ref={project16}
              name="project16"
              castShadow
              receiveShadow
              geometry={nodes.project16.geometry}
              material={materials.project_2021_16}
              position={[26.295, 3.5, -21.765]}
              userData={{ name: "project16" }}
              {...operateProject16()}
              scale={scale16}>
              <Balloon16 hover16={hover16} />
            </a.mesh>
            <a.mesh
              ref={project2}
              name="project2"
              castShadow
              receiveShadow
              geometry={nodes.project2.geometry}
              material={materials.project_2021_02}
              position={[16.969, 3.5, -4.834]}
              userData={{ name: "project2" }}
              {...operateProject2()}
              scale={scale2}>
              <Balloon2 hover2={hover2} />
            </a.mesh>
            <a.mesh
              ref={project3}
              name="project3"
              castShadow
              receiveShadow
              geometry={nodes.project3.geometry}
              material={materials.project_2021_03}
              position={[4.742, 3.5, -8.342]}
              userData={{ name: "project3" }}
              {...operateProject3()}
              scale={scale3}>
              <Balloon3 hover3={hover3} />
            </a.mesh>
            <a.mesh
              ref={project4}
              name="project4"
              castShadow
              receiveShadow
              geometry={nodes.project4.geometry}
              material={materials.project_2021_04}
              position={[-3.625, 3.5, -17.153]}
              userData={{ name: "project4" }}
              {...operateProject4()}
              scale={scale4}>
              <Balloon4 hover4={hover4} />
            </a.mesh>
            <a.mesh
              ref={project6}
              name="project6"
              castShadow
              receiveShadow
              geometry={nodes.project6.geometry}
              material={materials.project_2021_06}
              position={[-13.211, 3.5, -25.142]}
              userData={{ name: "project6" }}
              {...operateProject6()}
              scale={scale6}>
              <Balloon6 hover6={hover6} />
            </a.mesh>
            <mesh
              name="text_lifeScience"
              castShadow
              receiveShadow
              geometry={nodes.text_lifeScience.geometry}
              material={materials.base_color_2020}
              position={[11.94, 0.55, 0.353]}
              userData={{ name: "text_lifeScience" }}
            />
          </group>
          <group name="MANUFACTURING" userData={{ name: "MANUFACTURING" }}>
            <group name="stage_it" userData={{ name: "stage_it" }}>
              <mesh
                name="side_face001"
                castShadow
                receiveShadow
                geometry={nodes.side_face001.geometry}
                material={materials.base_color_2020}
                position={[-26.86, 0.25, 17.445]}
                userData={{ name: "side_face.001" }}
              />
              <mesh
                name="top_face001"
                castShadow
                receiveShadow
                geometry={nodes.top_face001.geometry}
                material={materials.White1}
                position={[-26.86, 0.5, 17.445]}
                userData={{ name: "top_face.001" }}
              />
            </group>
            <a.mesh
              ref={project15}
              name="project15"
              castShadow
              receiveShadow
              geometry={nodes.project15.geometry}
              material={materials.project_2021_15}
              position={[-29.548, 3.5, 15.484]}
              userData={{ name: "project15" }}
              {...operateProject15()}
              scale={scale15}>
              <Balloon15 hover15={hover15} />
            </a.mesh>
            <a.mesh
              ref={project7}
              name="project7"
              castShadow
              receiveShadow
              geometry={nodes.project7.geometry}
              material={materials.project_2021_07}
              position={[-20.822, 3.5, 21.9]}
              userData={{ name: "project7" }}
              {...operateProject7()}
              scale={scale7}>
              <Balloon7 hover7={hover7} />
            </a.mesh>
            <a.mesh
              ref={project8}
              name="project8"
              castShadow
              receiveShadow
              geometry={nodes.project8.geometry}
              material={materials.project_2021_08}
              position={[-38.282, 3.5, 20.046]}
              userData={{ name: "project8" }}
              {...operateProject8()}
              scale={scale8}>
              <Balloon8 hover8={hover8} />
            </a.mesh>
            <mesh
              name="text_manufacturing"
              castShadow
              receiveShadow
              geometry={nodes.text_manufacturing.geometry}
              material={materials.base_color_2020}
              position={[-32.217, 0.55, 26.298]}
              userData={{ name: "text_manufacturing" }}
            />
          </group>
          <group name="OTHERS" userData={{ name: "OTHERS" }}>
            <group name="stage_othres" userData={{ name: "stage_othres" }}>
              <mesh
                name="side_face004"
                castShadow
                receiveShadow
                geometry={nodes.side_face004.geometry}
                material={materials.base_color_2020}
                position={[-34.4, 0.25, -17.7]}
                userData={{ name: "side_face.004" }}
              />
              <mesh
                name="top_face004"
                castShadow
                receiveShadow
                geometry={nodes.top_face004.geometry}
                material={materials.White1}
                position={[-34.4, 0.5, -17.7]}
                userData={{ name: "top_face.004" }}
              />
            </group>
            <a.mesh
              ref={project10}
              name="project10"
              castShadow
              receiveShadow
              geometry={nodes.project10.geometry}
              material={materials.project_2021_10}
              position={[-30.03, 3.5, -20.9]}
              userData={{ name: "project10" }}
              {...operateProject10()}
              scale={scale10}>
              <Balloon10 hover10={hover10} />
            </a.mesh>
            <a.mesh
              ref={project9}
              name="project9"
              castShadow
              receiveShadow
              geometry={nodes.project9.geometry}
              material={materials.project_2021_09}
              position={[-38.613, 3.5, -15.7]}
              userData={{ name: "project9" }}
              {...operateProject9()}
              scale={scale9}>
              <Balloon9 hover9={hover9} />
            </a.mesh>
            <mesh
              name="text_others"
              castShadow
              receiveShadow
              geometry={nodes.text_others.geometry}
              material={materials.base_color_2020}
              position={[-28.607, 0.55, -11.79]}
              userData={{ name: "text_others" }}
            />
          </group>
          <group name="YEARPLATE" userData={{ name: "YEARPLATE" }}>
            <mesh
              name="year_2020"
              castShadow
              receiveShadow
              geometry={nodes.year_2020.geometry}
              material={materials.White1}
              position={[38.159, 0.05, 5.179]}
              userData={{ name: "year_2020" }}
            />
          </group>
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/assets/models/FIELD_2021.gltf");
