import styled from 'styled-components';

const TempPage404 = (props) => {
  const {children} = props
  return (
    <STempPage404 className="contents" style={{position: 'absolute'}}>
      {children}
    </STempPage404>
  )
};
export default TempPage404;

const STempPage404 = styled.div`
  cursor: default;
  overflow: scroll;
  position: absolute; 
	z-index: 3;
  padding: 10rem;
	width: 100%;
	height: 100vh;
	background-color: rgba(255,255,255,1);
`