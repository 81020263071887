import styled from "styled-components";
import { IoArrowForwardSharp } from "react-icons/io5";
import { d, v } from "../../../CssVariables";

const Button = (props) => {
  const { url, color, children } = props;

  return (
    <SButton href={url} style={{ color: `${color}` }}>
      <span className="arrow">
        <IoArrowForwardSharp />
      </span>
      {children}
    </SButton>
  );
};
export default Button;

const SButton = styled.a`
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 1.2em 2em;
  border-radius: 0;
  text-align: center;
  font-size: 2rem;
  @media ${d.maxSp} {
    font-size: 1.8rem;
  }
  font-weight: bold;
  line-height: 1.5em;

  span.arrow {
    display: flex;
    align-items: center;
    margin-right: 0.1em;
    width: 1em;
    height: 1em;
  }
  span.free-mark {
    background-color: ${v.primaryColor21};
    display: inline-block;
    padding: 0 0.5em;
    margin-left: 0.5em;
    color: #fff;
  }
`;
