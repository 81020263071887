import styled from "styled-components";
import Head from "../../Head";
import Hero from "../organisms/Hero2023";
import TempUnderLayerMax from "../templates/TempUnderLayerMax";
import MV from "../demoday2024/organisms/MV";
import DemodayAbout from "../demoday2024/organisms/DemodayAbout";
import Speakers from "../demoday2024/organisms/Speakers";
import Overview from "../demoday2024/organisms/Overview";
import TimeSchedule from "../demoday2024/organisms/TimeSchedule";
import Reservation from "../demoday2024/organisms/Reservation";
import Supporter from "../demoday2024/organisms/Supporter";
import Sponsor from "../demoday2024/organisms/Sponsor";
import Contact from "../demoday2024/organisms/Contact";
import BottomLogoArea from "../demoday2024/organisms/BottomLogoArea";
import EndAnnounce from "../demoday2024/organisms/EndAnnounce";
import { d } from "../../CssVariables";

const DemoDay2024 = () => {
  return (
    <>
      <Head title="DEMO DAY" />
      <SDemoday2024>
        <Hero title={"DEMO DAY 2024"} />
        <TempUnderLayerMax>
          {/* <div style={{ padding: "200px 40px" }}>
            <h2>COMING SOON</h2>
          </div> */}
          <MV />
          <EndAnnounce />
          <DemodayAbout />
          <Speakers />
          {/* <section id="bannerArea">
            <a href="/">
              <img src="./assets/demoday2024/images/banner_to_top.png" alt="研究一覧ビューへ" className="banner-to-top" />
            </a>
          </section> */}
          <Overview />
          <TimeSchedule />
          <Reservation />
          {/* <Supporter /> */}
          <Sponsor />
          <Contact />
          {/* <BottomLogoArea /> */}
        </TempUnderLayerMax>
      </SDemoday2024>
    </>
  );
};
export default DemoDay2024;

const SDemoday2024 = styled.div`
  section#DemodayMovie {
    margin: 0;
    iframe {
      width: 100%;
      height: 600px;
    }
  }
  section#description {
    div {
      background: #eee;
      margin-bottom: 1.5%;
      padding: 3% 1%;
    }
  }
  .hero-title-box {
    display: none;
  }

  section#bannerArea {
    background-color: #fff;
    padding: 80px 0 30px;
    margin: 0;
    img.banner-to-top {
      width: 900px;
      margin: 0 auto;
      display: block;
      @media ${d.maxTab} {
        width: 90%;
      }
    }
  }
`;
