import styled from "styled-components"
import Head from "../../Head"
import Hero from "../organisms/Hero2021"
import TempUnderLayerMax from "../templates/TempUnderLayerMax";
import MV from "../demoday2022/organisms/MV";
import DemodayAbout from "../demoday2022/organisms/DemodayAbout";
import Speakers from "../demoday2022/organisms/Speakers";
import Overview from "../demoday2022/organisms/Overview";
import TimeSchedule from "../demoday2022/organisms/TimeSchedule";
import Reservation from "../demoday2022/organisms/Reservation";
import Supporter from "../demoday2022/organisms/Supporter";
import Sponsor from "../demoday2022/organisms/Sponsor";
import Contact from "../demoday2022/organisms/Contact";
import BottomLogoArea from "../demoday2022/organisms/BottomLogoArea";
import EndAnnounce from "../demoday2022/organisms/EndAnnounce";

const DemoDay2022 = () => {
  return (
    <>
      <Head title="DEMO DAY" />
      <SDemoday2022>
        <Hero title={"DEMO DAY 2022"} />
        <TempUnderLayerMax>
          <MV />
          <EndAnnounce />
          <DemodayAbout />
          <Speakers />
          <Overview />
          <TimeSchedule />
          <Reservation />
          <Supporter />
          <Sponsor />
          <Contact />
          <BottomLogoArea />
        </TempUnderLayerMax>
      </SDemoday2022>
    </>
  )
}
export default DemoDay2022;

const SDemoday2022 = styled.div`
  section#DemodayMovie {
    margin: 0;
    iframe {
      width: 100%;
      height: 600px;
    }
  }
  section#description {
    div {
      background: #eee;
      margin-bottom: 1.5%;
      padding: 3% 1%;
    }
  }
`;