import { useEffect } from "react";
import BalloonBase from "../BalloonBase";

const BalloonDemoday = (props) => {

  const {hoverDemoday} = props;
  useEffect(() => {
    document.body.style.cursor = hoverDemoday ? "pointer" : "default";
  },[hoverDemoday])

  return (
    <BalloonBase whichHover={hoverDemoday}>DEMO DAY 2023</BalloonBase>
  )
}
export default BalloonDemoday;