import styled from "styled-components";
import { d, v } from "../../../CssVariables";
import { IoChevronForwardSharp } from "react-icons/io5";
import Button from "../atoms/Button";

const ToMovie = () => {
  return (
    <SToMovie id="ToMovie">
      <h2>2023年3月14日 イベント当日動画配信</h2>
      <div className="btn-box flex">
        <Button url={"http://watch-movie.ku-oit.jp/"} color={v.textColor} >動画視聴ページはこちら</Button>
      </div>
      
    </SToMovie>
  )
}
export default ToMovie;

const SToMovie = styled.section`
  margin: 0;
  padding: 8vw 6%;
  background-color: #fff;
  @media ${d.minLaptop} {
    padding: 6vw 16vw;
  }

  h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 180% !important;
  }
  .btn-box {
    margin-bottom: 0 !important;
    a {
      background-color: ${v.primaryColor22};
      border-radius: 3px;
    }
  }
`;
