import { useEffect } from "react";
import BalloonBase from "../BalloonBase";

const Balloon7 = (props) => {
  
  const {hover7} = props;
  useEffect(() => {
    document.body.style.cursor = hover7 ? "pointer" : "default";
  },[hover7])

  return (
    <BalloonBase whichHover={hover7}>患者のQOL向上を目指した、新規生体類似材料を用いた、異物付着抑制効果を有した尿管ステントの研究</BalloonBase>
  )
}
export default Balloon7;