import { useEffect } from "react";
import BalloonBase from "../BalloonBase";

const Balloon1 = (props) => {

  const {hover1} = props;
  useEffect(() => {
    document.body.style.cursor = hover1 ? "pointer" : "default";
  },[hover1])

  return (
    <BalloonBase whichHover={hover1}>Rasシグナルの網羅的阻害を基軸とした革新的がん治療薬の開発</BalloonBase>
  )
}
export default Balloon1;