import { useState, useEffect } from "react";
export const useMediaQuery = () => {

  const [mq, setMq] = useState({
    isPC: window.matchMedia('screen and (min-width: 1024px)').matches,
    isTAB: window.matchMedia('screen and (min-width: 678px)').matches,
    isSP: window.matchMedia('screen and (max-width: 677px)').matches
  });

  useEffect(() => {
    const onResize = () => {
      setMq({
        isPC: window.matchMedia('screen and (min-width: 1024px)').matches,
        isTAB: window.matchMedia('screen and (min-width: 678px)').matches,
        isSP: window.matchMedia('screen and (max-width: 677px)').matches
      });
    }
    window.addEventListener('resize', onResize);
    window.addEventListener('load', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
      window.removeEventListener('load', onResize);
    }
  });

  return mq;
}