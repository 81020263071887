import styled from "styled-components";
import { d, v } from "../../../CssVariables";
import { IoChevronForwardSharp } from "react-icons/io5";

const Overview = () => {
  return (
    <SOverview id="overview">
      <div className="title-box">
        <div className="title-en-large title-en center">OVERVIEW</div>
        <h2 className="center">イベント概要</h2>
      </div>
      <div className="desc">
        <dl>
          <dt>開催日時</dt>
          <dd>2023年3月14日(火) 13:00〜16:00</dd>
          <dt>開催場所</dt>
          <dd>
            <ul>
              <li>
                大阪工業大学梅田キャンパス3階 常翔ホール
                <a className="to_googlemap flex" href="https://goo.gl/maps/NhVQeZMMk7jSNEUX8">
                  <IoChevronForwardSharp />
                  GOOGLE MAP
                </a>
              </li>
              <li>YouTubeライブ配信(ブラウザ上にて視聴)</li>
            </ul>
          </dd>
          <dt>費用</dt>
          <dd>無料</dd>
          <dt>参加申込</dt>
          <dd>
            <a href="#reservation" style={{ textDecoration: "underline" }}>
              参加申込フォーム
            </a>
            よりご登録ください
            <br />
            申込締切: 2023年3月13日(月)
          </dd>
          <dt>主催</dt>
          <dd>神戸大学、大阪工業大学</dd>
          <dt>後援</dt>
          <dd>
            <span>関西経済連合会、</span>
            <span>関西経済同友会、</span>
            <span>神戸市、</span>
            <span>大阪産業局、</span>
            <span>大阪商工会議所、</span>
            <span>神戸商工会議所、</span>
            <span>関西イノベーションイニシアティブ（代表幹事機関　公益財団法人都市活力研究所）</span>
            <span>京阪神スタートアップアカデミア・コアリション、</span>
            <span>三井住友銀行、</span>
            <span>大阪信用金庫</span>
          </dd>
        </dl>
      </div>
    </SOverview>
  );
};
export default Overview;

const SOverview = styled.section`
  margin: 0;
  padding: 8vw 6%;
  background-color: #fff;
  @media ${d.minLaptop} {
    padding: 6vw 16vw;
  }
  .title-en-large {
    color: ${v.primaryColor22};
  }
  .title-box {
    h2 {
      color: ${v.primaryColor22};
      font-size: 3.2rem;
      padding-bottom: 0;
      @media ${d.maxTab} {
        font-size: 2.4rem;
      }
    }
  }

  .desc {
    width: 900px;
    margin: 0 auto;
    padding: 0 30px;
    @media ${d.maxTab} {
      width: 100%;
      padding: 0;
    }
  }

  a.to_googlemap.flex {
    display: inline-flex !important;
    flex-direction: row;
    align-items: center !important;
    font-size: 1.3rem;
    background: ${v.primaryColor22};
    color: #111;
    display: inline-block;
    margin-left: 1em;
    @media ${d.maxTab} {
      margin-left: 0;
    }
    padding: 0 1em;
    border-radius: 1000px;
  }

  dl span {
    display: inline-block;
    padding-right: 0.5em;
  }
`;
