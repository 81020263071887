import styled from "styled-components";
import { d, v } from "../../../CssVariables";
import { IoChevronForwardSharp } from "react-icons/io5";

const Overview = () => {
  return (
    <SOverview id="overview">
      <div className="title-box">
        <h2 className="center">イベント概要</h2>
        <div className="title-en-small title-en center">OVERVIEW</div>
      </div>
      <div className="desc">
        <dl>
          <dt>開催日時</dt>
          <dd>2022年3月30日 13:00〜16:00</dd>
          <dt>開催場所</dt>
          <dd>
            <ul>
              <li>
                大阪工業大学 梅田キャンパス 常翔ホール(先着順100名限定)
                <a className="to_googlemap" href="https://goo.gl/maps/NhVQeZMMk7jSNEUX8">
                  <IoChevronForwardSharp />
                  GOOGLE MAP
                </a>
              </li>
              <li>ライブ配信(申込要・ブラウザ上にて視聴)</li>
            </ul>
          </dd>
          <dt>費用</dt>
          <dd>無料aa</dd>
          <dt>申込締切</dt>
          <dd>2022年3月29日</dd>
          <dt>主催</dt>
          <dd>神戸大学、 大阪工業大学</dd>
          <dt>共催</dt>
          <dd>関西経済連合会、 大阪イノベーションハブ</dd>
          <dt>後援</dt>
          <dd>
            関西経済同友会、 大阪商工会議所、 神戸商工会議所、 三井住友銀行
            <br />
            関西イノベーションイニシアティブ（代表幹事機関 公益財団法人都市活力研究所）
          </dd>
          <dt>協力</dt>
          <dd>NTTビジネスソリューションズ、 NTTスマートコネクト、 FM802</dd>
        </dl>
      </div>
    </SOverview>
  );
};
export default Overview;

const SOverview = styled.section`
  margin: 0;
  padding: 8vw 6%;
  @media ${d.minLaptop} {
    padding: 6vw 16vw;
  }
  .title-en-small {
    color: ${v.primaryColor21};
  }
  .desc {
    max-width: 900px;
    margin: 0 auto;
    /* box-shadow: 0 2px 10px 3px rgba(0, 0, 0, 0.1); */
    padding: 30px;
    background-color: #fff;
  }

  a.to_googlemap {
    background: ${v.secondaryColor21};
    color: #fff;
    display: inline-block;
    margin-left: 1em;
    @media ${d.maxTab} {
      margin-left: 0;
    }
    padding: 0 1em;
    border-radius: 1000px;
  }
`;
