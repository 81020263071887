import { useEffect } from "react";
import BalloonBase from "../BalloonBase2021";

const Balloon1 = (props) => {
  const { hover1 } = props;
  useEffect(() => {
    document.body.style.cursor = hover1 ? "pointer" : "default";
  }, [hover1]);

  return <BalloonBase whichHover={hover1}>コロナ渦における臨床実習の代替となる教育方法の検討</BalloonBase>;
};
export default Balloon1;
