import React, { useContext, useEffect, useRef  } from "react";
import styled from 'styled-components';
import { d, v } from "../../CssVariables";
import { AnnounceContext } from "../../providers/AnnounceProvider";
import { IoCloseSharp } from "react-icons/io5";
import gsap from "gsap";
import { useMediaQuery } from "../../UseMediaQuery";

const Announce = () => {

  const { announce, setAnnounce } = useContext(AnnounceContext)
  
  const mq = useMediaQuery();

  const toggleAnnounce = () => {
    setAnnounce(!announce)
  }

  const announceRef = useRef();

  useEffect(() => {
    const el = announceRef.current;
    gsap.to(el,
      {
        opacity: 1,
        y: -10,
        duration: 0.5,
        delay: 3,
      }
    )
  }, [announce]);

  return (
    <SAnnounce show={announce}>
      <div ref={announceRef} className={`announce-box ${announce ? "show" : "close"}`}>
        <div className="flex">
          { mq.isPC && <h3>操作方法について</h3> }
          { mq.isPC && <img src="./assets/images/announce.svg" /> }
          <p>このサイトでは、神戸大学・大阪工業大学の研究者・アントレプレナーの技術シーズや、両大学が共同で主催するイベント情報を閲覧することができます。</p>

        </div>
        
        <button className="close" onClick={toggleAnnounce}><IoCloseSharp /></button>
      </div>
      <div className="overlay" onClick={toggleAnnounce}></div>
    </SAnnounce>
  )
}
export default Announce;

const SAnnounce = styled.div`
@media ${d.minLaptop} {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  .announce-box {
    position: absolute;
    z-index: 2;
    top:0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;

    width: 640px;
    height: 360px;
    @media ${d.maxTab} {
      width: 100%;
      height: auto;
    }
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 24px;
    border-radius: 3px;
    padding: 20px 40px;
    opacity: 0;
    &.close {
      opacity: 0 !important;
      transition: all 1.0s;
    }
    .flex {
      padding: 30px 60px 30px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0 auto;
      justify-content: center;
      align-items: center;
      align-content: center;
      h3 {
        width: 100%;
        text-align: center;
        padding: 0;
        color: ${v.textColor};
      }
      img {
        margin: 16px 0;
        padding: 12px 20px 20px;
        border-top: 1px solid ${v.textColor};
        border-bottom: 1px solid ${v.textColor};
      }
      p {
        color: ${v.textColor};
        padding: 0;
        line-height: 1.5em;
      }
    }
  }

}


@media ${d.maxTab} {
  position: absolute;
    z-index: 2;
    bottom: 0;
  .announce-box {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 24px;
    border-radius: 3px;
    padding: 20px 80px 20px 20px;;
    opacity: 0;
    text-align: justify;
    &.close {
      opacity: 0 !important;
      transition: all 1.0s;
    }
    .flex {
      p {
        color: ${v.textColor};
        padding: 0;
        line-height: 1.5em;
      }
    }
  }

}



button.close {
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 0;
  width: 40px;
  height: 40px;
  font-size: 2em;
  border-radius: 1px 3px 1px 1px;
  background-color: ${v.textColor};
}
.overlay {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: transparent;
  }

`