import styled from "styled-components";
import { d, v } from "../../../CssVariables";
import bg from "../images/background_reservation.jpg";
import Button from "../atoms/Button";

const Reservation = () => {
  return (
    <SReservation id="reservation">
      <div className="title-box">
        <h2 className="center">視聴予約はこちら</h2>
        <div className="title-en-small title-en center">RESERVATION</div>
      </div>
      {/* <div className="btn-box flex">
        <Button url={"https://forms.office.com/Pages/ResponsePage.aspx?id=BgaLzUoz5ke7n9VwcRig6sP2TimKfJJJqKNPHUABIu5UQUVFVEUxMVM2MDBDNVVNUTVRQjNaVEE1Qi4u"} color={v.primaryColor21} free >現地で参加</Button>
        <Button url={"https://forms.office.com/Pages/ResponsePage.aspx?id=BgaLzUoz5ke7n9VwcRig6sP2TimKfJJJqKNPHUABIu5URDUwUTY2RTM0NVM4OU5UUzZMMVBKTE9MTi4u"} color={v.primaryColor21} free >WEBで参加</Button>
      </div> */}
      {/* <h3 className="center">予約締め切り : 3月29日(火)</h3> */}
      <h3 className="center">※予約受付は終了しました</h3>
    </SReservation>
  )
}
export default Reservation;

const SReservation = styled.section`
  margin: 0;
  padding: 8vw 6%;
  @media ${d.minLaptop} {
    padding: 6vw 12vw;
  }
  background-image: url(${bg});
  background-size: cover;
  background-position: center center;

  h2, .title-en-small, p {
    color: #fff;
  }
  h3 {
    color: #fff;
  }

`;

