import { Link } from "react-router-dom";
import styled from "styled-components";
import { d } from "../../CssVariables";
import Head from "../../Head";
import Hero2023 from "../organisms/Hero2023";
import UnderLayer from "../templates/TempUnderLayer";
import bg from "../../images/background_about.jpg";
import { IoArrowForwardSharp } from "react-icons/io5";

const About = () => {
  return (
    <>
      <Head title="本事業について" />
      <SAbout>
        <Hero2023 title={"本事業について"} />
        <UnderLayer>
          <section className="width-max">
            <div className="inner">
              <p>
                本事業では、
                <br className="pc-only" />
                神戸大学と大阪工業大学が共同で、
                <br className="pc-only" />
                所属する研究者・アントレプレナーの技術シーズを発掘し、
                <br className="pc-only" />
                起業活動支援を行い、技術シーズやビジネスモデルの
                <br className="pc-only" />
                ブラッシュアップを行います。
              </p>
              <p>
                実際に起業したベンチャー企業に対し、
                <br className="pc-only" />
                JST「大学発新事業創出プログラム（START）」の申請手続きの補助や
                <br className="pc-only" />
                ベンチャーキャピタル（VC）から投資を受けられるような支援を両大学が行うことで、
                <br className="pc-only" />
                「京阪神連携によるスタートアップ・エコシステム拠点形成
                <br className="pc-only" />
                （グローバル拠点都市：大阪・京都・ひょうご神戸コンソーシアム）」に
                <br className="pc-only" />
                貢献することを目指します。
              </p>
              {/* <div className="btn-box">
                <Link to="/project-all" className="btn btn-primary">
                  <span><IoArrowForwardSharp /></span>
                  <span>技術シーズ一覧はこちら</span>
                </Link>
              </div> */}
            </div>
          </section>
        </UnderLayer>
      </SAbout>
    </>
  );
};
export default About;

const SAbout = styled.div`
  .wrapper {
    width: 100%;
    background-image: url(${bg});
    background-size: cover;
  }

  section {
    margin: 0;
    padding: 8vw;
    @media ${d.maxSp} {
      padding: 20vw 8vw;
    }
    .inner {
      width: 900px;
      margin: 0 auto;
      @media ${d.maxSp} {
        width: 100%;
      }
      p {
        font-size: 1.8rem;
        font-weight: bold;
        text-align: center;
        line-height: 2em;
        @media ${d.maxSp} {
          text-align: left;
        }
      }
      .btn-box {
        margin-top: 3em;
        text-align: center;
      }
    }
  }
`;
