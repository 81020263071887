const SpeakerList = [
  {
    id: 1,
    portrait: '/assets/demoday2022/images/speaker_01.jpg',
    movie: 'https://youtu.be/pXDfL9BbbV8',
    title: '移動軌跡データの効率的な活用のためのアプリケーション開発',
    name: '安田 昌平',
    university: '(採択時) 神戸大学',
    department: '大学院工学研究科',
    position: '特命助教',
    remark: null,
    document: '/assets/demoday2022/documents/01-yasuda-jst_start_demoday_2022.pdf',
    movieStart: '0:14:58〜',
  },
  {
    id: 2,
    portrait: '/assets/demoday2022/images/speaker_02.jpg',
    movie: 'https://youtu.be/VEih0ENt9CA',
    title: '生体膜を模倣したバイオチップの開発とその事業化',
    name: '森垣 憲一',
    university: '神戸大学',
    department: 'バイオシグナル総合研究センター',
    position: '准教授',
    remark: null,
    document: '/assets/demoday2022/documents/02-morigaki-jst_start_demoday_2022.pdf',
    movieStart: '0:37:31〜',
  },
  {
    id: 3,
    portrait: '/assets/demoday2022/images/speaker_03.jpg',
    movie: 'https://youtu.be/d6-eO4_NpZA',
    title: '人工設計ペプチドを用いたレアメタル回収技術の開発とその事業化',
    name: '田村 厚夫',
    university: '神戸大学',
    department: '大学院理学研究科',
    position: '准教授',
    remark: null,
    document: '/assets/demoday2022/documents/03-tamura-jst_start_demoday_2022.pdf',
    movieStart: '0:59:36〜',
  },
  {
    id: 4,
    portrait: '/assets/demoday2022/images/speaker_04.jpg',
    movie: 'https://youtu.be/Q48MCuKvE04',
    title: 'フードビジネスを対象としたシミュレーション・最適化技術',
    name: '舟橋 健雄',
    university: '神戸大学バリュースクール',
    department: null,
    position: '学術研究員',
    remark: [
      <hr />,
      <h3>藤井 信忠 氏</h3>,
      <h4>
        <span>神戸大学大学院</span>
        <span>システム情報学研究科 システム科学専攻 准教授</span>
      </h4>,
    ],
    document: '/assets/demoday2022/documents/04-funahashi_fujii_sago_jst_start_demoday_2022.pdf',
    movieStart: '1:26:30〜',
  },
  {
    id: 5,
    portrait: '/assets/demoday2022/images/speaker_05.jpg',
    movie: 'https://youtu.be/cBZFI9fHm74',
    title: 'ペプチドを基軸とする神経障害性疼痛治療薬の開発',
    name: '芦高 恵美子',
    university: '大阪工業大学',
    department: '工学部生命工学科',
    position: '教授',
    remark: null,
    document: '/assets/demoday2022/documents/05-ashitaka-jst_start_demoday_2022.pdf',
    movieStart: '1:50:52〜',
  },
  {
    id: 6,
    portrait: '/assets/demoday2022/images/speaker_06.jpg',
    movie: 'https://youtu.be/Wrx653uxrbM',
    title: '分散センサに基づく危機管理AIシステムの実現及び事業化',
    name: '尾崎 敦夫',
    university: '大阪工業大学',
    department: '情報科学部情報知能学科',
    position: '教授',
    remark: [
      <h4>
        <hr />
        <span>[ 発表者 ]</span>
        <span>大阪工業大学大学院</span>
        <span>情報科学研究科 情報科学専攻</span>
        <span>豊味 諒磨</span>
      </h4>,
    ],
    document: '/assets/demoday2022/documents/06-ozaki-jst_start_demoday_2022.pdf',
    movieStart: '2:20:31〜',
  }
  
]
export default SpeakerList;