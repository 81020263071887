import { useEffect } from "react";
import BalloonBase from "../BalloonBase";

const Balloon3 = (props) => {
  const { hover3 } = props;
  useEffect(() => {
    document.body.style.cursor = hover3 ? "pointer" : "default";
  }, [hover3]);

  return <BalloonBase whichHover={hover3}>シワだけでなく「たるみ」を予防する神戸大学発の新しいスキンケア商品の開発</BalloonBase>;
};
export default Balloon3;
