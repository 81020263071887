import { useEffect } from "react";
import BalloonBase from "../BalloonBase2021";

const Balloon14 = (props) => {
  const { hover14 } = props;
  useEffect(() => {
    document.body.style.cursor = hover14 ? "pointer" : "default";
  }, [hover14]);

  return <BalloonBase whichHover={hover14}>農業生産現場における経営改善のためのIoTデバイス、シミュレータの開発</BalloonBase>;
};
export default Balloon14;
