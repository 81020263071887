import { useEffect } from "react";
import BalloonBase from "./BalloonBase";

const BalloonUniversityOit = (props) => {
  
  const {hoverUniversityOit} = props;
  useEffect(() => {
    document.body.style.cursor = hoverUniversityOit ? "pointer" : "default";
  },[hoverUniversityOit])

  return (
    <BalloonBase whichHover={hoverUniversityOit}>大阪工業大学</BalloonBase>
  )
}
export default BalloonUniversityOit;