import { Link } from "react-router-dom";
import Head from "../../Head";
import TempPage404 from "../templates/TempPage404";

const Page404 = () => {

  return (
    <>
      <Head title="404 ページがみつかりません" />
      <TempPage404>
        <h1>PAGE NOT FOUND 404</h1>
        <p>ページが見つかりません</p>
        <Link to="/">ホームに戻る</Link>
      </TempPage404>
    </>
  )
}

export default Page404;