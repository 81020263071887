import styled from "styled-components";
import { d, v } from "../../../CssVariables";
import bg from "../images/background_reservation.png";
import Button from "../atoms/Button";

const Reservation = () => {
  return (
    <SReservation id="reservation">
      <div className="title-box">
        <div className="title-en-large title-en center">RESERVATION</div>
        <h2 className="center">参加申込</h2>
      </div>

      <div className="btn-box flex">
        <h3 className="limit">申込期限: 2023年3月13日(月)</h3>
        <Button
          url={"https://forms.office.com/pages/responsepage.aspx?id=BgaLzUoz5ke7n9VwcRig6sP2TimKfJJJqKNPHUABIu5UM0JGWFFMUTNMUFk1UkNUSkFRMjMxUFhUOC4u"}
          color={v.primaryColor21}>
          会場参加申込はこちら
        </Button>
        <Button
          url={"https://forms.office.com/pages/responsepage.aspx?id=BgaLzUoz5ke7n9VwcRig6sP2TimKfJJJqKNPHUABIu5UMjVJNUtHNk1TRjRZWE1SS0hFWDMzUVQzSS4u"}
          color={v.primaryColor21}>
          オンライン視聴申込はこちら
        </Button>
      </div>
      {/* <h3 className="center">予約締め切り : 3月29日(火)</h3> */}
      {/* <h3 className="center">※予約受付は終了しました</h3> */}
    </SReservation>
  );
};
export default Reservation;

const SReservation = styled.section`
  margin: 0;
  padding: 8vw 6%;
  @media ${d.minLaptop} {
    padding: 6vw 12vw;
  }
  background-image: url(${bg});
  background-size: cover;
  background-position: center center;

  .title-en-large,
  p {
    color: #111;
  }
  h2 {
    font-size: 3.2rem;
    padding-bottom: 0;
    color: #111;
    @media ${d.maxTab} {
      font-size: 2.4rem;
    }
  }
  h3 {
    color: #111;
    font-size: 3.2rem;
    padding-bottom: 0;
  }

  .btn-box.flex {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 30px;
    max-width: 900px;
    border-radius: 3px;
    margin: 0 auto;
    margin: 0 auto !important;
    @media ${d.maxTab} {
      padding: 20px;
      align-items: center;
    }
    h3.limit {
      width: 100%;
      text-align: center;
      font-size: 2.1rem;
      padding: 0;
      margin-bottom: 20px;
      @media ${d.maxTab} {
        font-size: 1.8rem;
      }
    }

    a {
      display: flex;
      justify-content: center;
      color: #111 !important;
      background-color: #ffc800;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      font-size: 1.6rem;
      width: 280px;
      padding: 20px;
      @media ${d.maxTab} {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
`;
