const Projects = [
  {
    id: 1,
    url: "/project-2023/1",
    thumbnail: "/assets/models/texture2024/project_2023_01.jpg",
    adoptionYear: "2023年",
    category: "ライフサイエンス",
    title: "細胞間シグナルCD47-SIRPα系を標的とした新規がん治療法の開発",
    subtitle: null,
    authorUniversity: "神戸大学大学院",
    authorAffiliation: "医学研究科",
    authorPosition: "助教",
    authorName: "小谷 武徳",
    portrait: "/assets/images/project2023/01/portrait.jpg",
    video: {
      url: "https://youtu.be/N8p9Rx4u_cs",
      thumbnail: "/assets/images/project2023/01/video_thumbnail.jpg",
    },
    document: "/assets/demoday2024/documents/presentation_01.pdf",
    movieStart: "0:00:00〜",
    overviewText: [
      <p>
        様々ながん細胞に高発現する抗原に特異的に結合し、単独もしくは抗SIRPα抗体（マクロファージの抗体依存性細胞貪食を促進する抗体）との併用により「①強力な抗腫瘍効果を発揮する従来にない抗体医薬」及び、RNAiなどを用いてより効率的に「②CD47-SIRPα結合の形成を抑制する方法」を開発する。
      </p>,
    ],
    overviewImage: "/assets/images/project2023/01/fig01.jpg",
    buisinessModelText: [
      <p>
        上述（プロジェクトの概要欄に記述）した技術シーズについて非臨床POC取得を確立したのちに特許の取得を行い（一部は製剤化の過程も自前で行い）、製薬企業への導出を図る大学発ベンチャー企業の設立を目指す。
      </p>,
      <p>
        本研究で開発を進める技術シーズの適用は、全てのがんを発症した患者（現在国内では年間100万人程度、世界では1930万人程度ががんに罹患する）が想定される。
      </p>,
    ],
    buisinessModelImage: null,
    activityPlanText: [
      <p>
        これまでの研究により、研究代表者らは多くのがん細胞に発現する抗原に特異的に結合し、単独もしくは抗SIRPα抗体との併用によりがん細胞の排除を促進できる可能性のある抗体を見出している。そこで本研究開発では、その抗体によるin
        vivoレベルでの抗腫瘍効果の評価、マウス個体への影響（副作用など）、抗体の作用機序について解析を行い、非臨床におけるPOC確立を目指す。
      </p>,
      <p>
        また、生体におけるがん細胞でのCD47-SIRPα結合の形成を効率的に抑制する方法を確立することで、難治がんに対する革新的ながん治療法としての非臨床POCの取得を目指す。これらの技術シーズについては非臨床POC取得時のデータをもとに、特許取得へと進めたい。
      </p>,
    ],
    activityPlanImage: null,
  },
  {
    id: 2,
    url: "/project-2023/2",
    thumbnail: "/assets/models/texture2024/project_2023_02.jpg",
    category: "ものづくり",
    adoptionYear: "2023年",
    title: "閉塞性睡眠時無呼吸症候群（OSAS）に対する電気刺激を用いた口腔筋トレーニング装置の開発",
    subtitle: null,
    authorUniversity: "神戸大学",
    authorAffiliation: "医学部付属病院 医療技術部",
    authorPosition: null,
    authorName: "加藤 博史",
    portrait: "/assets/images/project2023/02/portrait.jpg",
    video: {
      url: "https://youtu.be/DRK3mRhPcfo",
      thumbnail: "/assets/images/project2023/02/video_thumbnail.jpg",
    },
    document: "/assets/demoday2024/documents/presentation_01.pdf",
    movieStart: "0:00:00〜",
    overviewText: [
      <p>
        MFT（Oral Myofunctional
        Therapy：口腔筋のトレーニング）は軽症～中等症のOSASを改善するとの報告がある。本プロジェクトでは口腔筋の一つであるオトガイ舌筋等を選択的に電気刺激することにより、同様の効果を実現することを目指す。特許出願中の電極および刺激方法は、オトガイ舌筋を選択的かつ有効に電気刺激することが可能な新しい技術であり、この技術を用いてこれまでにない医療機器の開発を目指す。
      </p>,
    ],
    overviewImage: "/assets/images/project2023/02/fig01.jpg",
    buisinessModelText: [
      <p>
        本プロジェクトでは医療機器の原理開発を行い、後の事業化において競争優位となるよう、特許取得や臨床研究の準備にも取り組む。本装置は覚醒時の電気刺激によりオトガイ舌筋をトレーニングし、無呼吸の改善を目指す。また、有効性を明らかにするため、対象患者のCTデータを用いた解剖学的特徴と治療効果の検証を行う。装置は保険適応を目指すが、保険適応外の活用も検討している（CEマークの取得によるヘルスケア製品等）。また、口腔筋のトレーニングは嚥下機能の改善も報告されているため、今後、適応拡大も検討する予定である。
      </p>,
      <p>
        本プロジェクト終了後にスタートアップを予定しているが、医療機器を目指す場合はクラスⅢもしくはクラスⅡ（いずれも臨床試験有）となるため、臨床試験、薬事承認に向け、医療機器製造販売企業とのパートナーシップを視野に入れた活動を行う。
      </p>,
    ],
    buisinessModelImage: null,
    activityPlanText: [<img src="/assets/images/project2023/02/fig02.jpg" alt="活動計画" />],
    activityPlanImage: null,
  },
  {
    id: 3,
    url: "/project-2023/3",
    thumbnail: "/assets/models/texture2024/project_2023_03.jpg",
    category: "ライフサイエンス",
    adoptionYear: "2023年",
    title: "シワだけでなく「たるみ」を予防する神戸大学発の新しいスキンケア商品の開発",
    subtitle: null,
    authorUniversity: "神戸大学",
    authorAffiliation: "農学研究科",
    authorPosition: "教授",
    authorName: "白井 康仁",
    portrait: "/assets/images/project2023/03/portrait.jpg",
    video: {
      url: "https://youtu.be/WXfJN2k5bRI",
      thumbnail: "/assets/images/project2023/03/video_thumbnail.jpg",
    },
    document: "/assets/demoday2024/documents/presentation_01.pdf",
    movieStart: "0:00:00〜",
    overviewText: [
      <p>
        皮膚の健康・若返りは今後の超高齢化社会において健康長寿社会実現のため非常に重要な課題である。現在、様々なシワ予防商品がでているが、シワの大きな原因であるコラーゲン分解を抑制する商品はない。
        我々はUVや加齢によるコラーゲン分解酵素の発現を抑制する天然植物エキスを見い出し、実際に小規模ヒト試験においてシワ面積などを減少させた（特許出願中）。また、原理的にこのエキスは首などのたるみも予防すると考えられる。
      </p>,
      <p>
        しかし、植物エキス中に含まれる有効成分の同定や、たるみに対する効果は未だ不明である。そこで、本研究では、有効成分の同定するとともに、皮膚科の医師の協力のもと、AIなどを駆使して「たるみ」の評価系を構築し、これまでにないメカニズムでシワだけでなく、「たるみ」も予防する大学発のスキンケア商品を開発する。
      </p>,
    ],
    overviewImage: "/assets/images/project2023/03/fig01.jpg",
    buisinessModelText: [
      <p>
        新規メカニズムでシワやたるみを予防するスキンケア商品を開発し、まず皮膚科医師協力のもと皮膚科やクリニックを通して販売を目指す。ついで、ネット通販やドラッグストアでの販売を目指す。
      </p>,
    ],
    buisinessModelImage: null,
    activityPlanText: [
      <ol>
        <li>素材特許及び配合特許取得を目指し、有効成分の探索及びより効果を発揮する植物エキスの組み合わせ（配合）の検討を行う。</li>
        <li>効能を高めるため、表皮のより深層に浸透するように工夫を行う。</li>
        <li>実際に目元やデコルテを含む首のたるみにも効果を示すかを検討し、最終的に「たるみ」の評価系の構築と予防商品を開発する。</li>
      </ol>,
    ],
    activityPlanImage: null,
  },
  {
    id: 4,
    url: "/project-2023/4",
    thumbnail: "/assets/models/texture2024/project_2023_04.jpg",
    category: "ライフサイエンス",
    adoptionYear: "2023年",
    title: "細胞膜のメカニクスを標的とした革新的がん治療法の開発",
    subtitle: null,
    authorUniversity: "神戸大学",
    authorAffiliation: "バイオシグナル総合研究センター",
    authorPosition: "准教授",
    authorName: "辻田 和也",
    portrait: "/assets/images/project2023/04/portrait.jpg",
    video: {
      url: "https://youtu.be/Zv3LoR_HfZs",
      thumbnail: "/assets/images/project2023/04/video_thumbnail.jpg",
    },
    document: "/assets/demoday2024/documents/presentation_01.pdf",
    movieStart: "0:00:00〜",
    overviewText: [
      <p>
        近年、がん細胞自体の物理特性ががん治療の有望なターゲットとして世界的に注目されている。本プロジェクトでは、細胞膜張力が「機械的がん抑制因子」として働くというコンセプトを治療法に応用すべく、マウスモデルを用いた検証、細胞膜張力を上げる作用を持つ化合物のスクリーニングを行い、細胞自体の物理特性をターゲットとした全く新しいタイプのがん治療法の創出を目指す。
      </p>,
    ],
    overviewImage: "/assets/images/project2023/04/fig01.jpg",
    buisinessModelText: [
      <p>
        同定した細胞膜張力に作用する化合物について特許出願を行い、製薬企業と連携し、がん細胞膜のメカニクス細胞を標的とした全く新しいタイプの抗がん剤を開発・製品化を目指す。また、ウイルスベクターを用いた細胞膜張力の操作による腫瘍抑制技術についても特許出願を行い、大学病院やがん関連研究所との連携により臨床研究を推進する。
      </p>,
    ],
    buisinessModelImage: null,
    activityPlanText: [
      <ol>
        <li>
          細胞膜張力によるがん悪性度抑制効果を実証する。具体的には細胞膜張力の操作によるがん細胞の浸潤性、抗がん剤感受性、幹細胞性、オルガノイド形成能に対する効果を検証する。
        </li>
        <li>細胞膜張力を上げる作用を持つリード化合物をハイスループットスクリーニングにより同定する。</li>
        <li>臨床応用を目指した、ウイルスベクターを用いた細胞膜張力の操作によるがん進展抑制効果を実証する。</li>
        <li>同定した化合物によるがん進展抑制効果を実証する。</li>
      </ol>,
    ],
    activityPlanImage: "/assets/images/project2023/04/fig02.jpg",
  },
  {
    id: 5,
    url: "/project-2023/5",
    thumbnail: "/assets/models/texture2024/project_2023_05.jpg",
    category: "環境エネルギー",
    adoptionYear: "2023年",
    title: "画期的なカーボンニュートラルのエネルギー供給を可能とする日本発の新技術",
    subtitle: "「ナノ金属-水素反応の実験実証  ( 正四面体凝縮モデルのメカニズム確証 ) 」",
    authorUniversity: "神戸大学",
    authorAffiliation: "海事科学研究科",
    authorPosition: "准教授",
    authorName: "金崎 真聡",
    portrait: "/assets/images/project2023/05/portrait.jpg",
    video: {
      url: "https://youtu.be/YZ3lh-tO96s",
      thumbnail: "/assets/images/project2023/05/video_thumbnail.jpg",
    },
    document: "/assets/demoday2024/documents/presentation_01.pdf",
    movieStart: "0:00:00〜",
    overviewText: [
      <p>
        ナノ金属-水素核反応エネルギー(MHE)は、画期的な一次エネルギー源で、カーボンニュートラルの新たな熱源として実用化が期待されるが、そのメカニズムは実験的に解明されていない。本課題では、試料中に残存する可能性があるヘリウム3を検出することで、発熱が４個の軽水素による多体核反応由来であることを実証するとともに、放射性物質を生成しない安心・安全なエネルギー源であることを証明する。これにより、MHEの社会実装に向けた新たな一歩を踏み出すことを目標とする。
      </p>,
    ],
    overviewImage: null,
    buisinessModelText: [
      <p>
        MHEによる発熱量増大を目的として、研究開発ベンチャーの立ち上げを想定している。本課題の実施期間中に、産業応用に向けて重要となる基本的な特許取得を目指す。また、将来的には、複数の産業応用メーカ等との共同研究契約で研究開発人材と研究資金を確保することで研究を推進し、取得した基本特許の実施権供与やMHE用試料の有償供与で収益を得ることを想定している。
      </p>,
    ],
    buisinessModelImage: null,
    activityPlanText: [
      <ul>
        <li>
          理論研究において、MHEでは熱の発生とともに、地球上にほとんど存在しないヘリウム3が生成されるとされている。しかし、Heは高温にならないと試料中から放出されにくいという特性がある。そこで、昇温脱離分析により、試料加熱時に放出されるヘリウム3の検出を試みる。
        </li>
        <li>
          MHE実用化のためには、発熱量のさらなる増大が必要不可欠である。そこで、本課題で実証されるメカニズムが生じやすい試料や反応体系などの開発を促進するために、新たな研究開発ベンチャーの設立準備を推進する。
        </li>
      </ul>,
    ],
    activityPlanImage: "/assets/images/project2023/05/fig01.jpg",
  },
  {
    id: 6,
    url: "/project-2023/6",
    thumbnail: "/assets/models/texture2024/project_2023_06.jpg",
    category: "ものづくり",
    adoptionYear: "2023年",
    title: "光オンデマンド合成法によるポリマーや医薬品原料の安全で安価なエコ生産システムの事業化",
    subtitle: null,
    authorUniversity: "神戸大学",
    authorAffiliation: "理学研究科",
    authorPosition: "准教授",
    authorName: "津田 明彦",
    portrait: "/assets/images/project2023/06/portrait.jpg",
    video: {
      url: "https://youtu.be/lW34zbSnKwY",
      thumbnail: "/assets/images/project2023/06/video_thumbnail.jpg",
    },
    document: "/assets/demoday2024/documents/presentation_01.pdf",
    movieStart: "0:00:00〜",
    overviewText: [
      <p>
        「持続可能な社会」の実現に向けて、安全性が高くて環境負荷の小さな、化学品製造が求められている。本課題では、当グループが開発した「ハロカーボンを原料とする光オン・デマンド有機合成法」を、化学品生産で使用するための製造システム・安全設備・ライセンス・ノウハウおよび技術指導のパッケージを販売するための事業立ち上げを企てる。
      </p>,
    ],
    overviewImage: "/assets/images/project2023/06/fig01.jpg",
    buisinessModelText: [
      <p>
        光オン・デマンド合成法のライセンス、もしくはその反応システムをユーザーの求める仕様にカスタマイズして国内リース販売する。加えて、当該合成法を用いて合成することができる化学品の自社での受託製造、およびオリジナル化合物（特殊カーボネートなど）の製造販売を目指す。
      </p>,
    ],
    buisinessModelImage: null,
    activityPlanText: [
      <ol>
        <li>
          光オン・デマンド合成システムの国内ライセンスもしくはリース販売 ：<br />
          商品となるフロー光オン・デマンド合成のベーシックシステムの制作
        </li>
        <li>
          特殊カーボネートの市場調査とサンプル提供：
          <br />
          サンプル（特殊カーボネート）提供のための試薬製造
        </li>
        <li>
          持続可能な社会の実現に寄与するシステム開発：
          <br />
          バイオメタンや二酸化炭素を原料とする光オン・デマンド有機合成法の研究開発を行い、時代のニーズに応じたシステムのバージョンアップ
        </li>
        <li>
          新たな生産システムの開発：
          <br />
          大型化に向けた、生産の自動化および遠制御を可能にするシステム開発
        </li>
      </ol>,
    ],
    activityPlanImage: "/assets/images/project2023/06/fig02.jpg",
  },
  {
    id: 7,
    url: "/project-2023/7",
    thumbnail: "/assets/models/texture2024/project_2023_07.jpg",
    category: "IT",
    adoptionYear: "2023年",
    title: "組織のサイバーレジリエンスを向上させるサイバーインテリジェンス情報サービスの構築",
    subtitle: null,
    authorUniversity: "神戸大学",
    authorAffiliation: "工学研究科",
    authorPosition: "准教授",
    authorName: "白石 善明",
    portrait: "/assets/images/project2023/07/portrait.jpg",
    video: {
      url: "https://youtu.be/oUn7reaLkrc",
      thumbnail: "/assets/images/project2023/07/video_thumbnail.jpg",
    },
    document: "/assets/demoday2024/documents/presentation_01.pdf",
    movieStart: "0:00:00〜",
    overviewText: [
      <p>
        組織のサイバー攻撃に対する回復力，すなわち“サイバーレジリエンス”を向上させるために，研究代表者が開発中のセキュリティ情報検索エンジンを内包したサイバーインテリジェンス情報を提示するサービス化のための開発を行う．
        クライアント組織からの攻撃の痕跡情報を入力として，関連する情報を抽出し，攻撃に関する情報をクライアント組織に提示し，サイバー攻撃への対応を支援するサービスの構築を目指す．
      </p>,
    ],
    overviewImage: "/assets/images/project2023/07/fig01.jpg",
    buisinessModelText: [
      <p>
        異常や攻撃の検知から情報収集，封じ込め，復旧，事後対応に至るまでのセキュリティオペレーションの一部または全部を組織外のセキュリティ専門業者に頼るとしても，社内人材であるセキュリティオペレータの情報収集フェーズにおける調査や対応の良否が事態収拾のスピードに影響することから，セキュリティオペレータを支援するサイバーインテリジェンス情報サービスを提供する．デジタル時代のビジネス展開を図ろうとする企業からセキュリティ人材が不足している組織をターゲットにサービス提供を目指す．
      </p>,
    ],
    buisinessModelImage: null,
    activityPlanText: [
      <p>
        商用レベルのシステムとして提供するためのユーザーインターフェースなどを設計し，セキュリティ情報検索エンジンを組み込んだサイバーインテリジェンス情報を出力するアプリケーションの試作品を作成する．
      </p>,
    ],
    activityPlanImage: null,
  },
  {
    id: 8,
    url: "/project-2023/8",
    thumbnail: "/assets/models/texture2024/project_2023_08.jpg",
    category: "IT",
    adoptionYear: "2023年",
    title: "エンゲージメントやコミュニケーションを促進するデジタルツイン行動変容支援システム",
    subtitle: null,
    authorUniversity: "大阪工業大学",
    authorAffiliation: "情報科学部 情報メディア学科",
    authorPosition: "特任教授",
    authorName: "佐野 睦夫",
    portrait: "/assets/images/project2023/08/portrait.jpg",
    video: {
      url: "https://youtu.be/97JnzMO6WE4",
      thumbnail: "/assets/images/project2023/08/video_thumbnail.jpg",
    },
    document: "/assets/demoday2024/documents/presentation_01.pdf",
    movieStart: "0:00:00〜",
    overviewText: [
      <ul>
        <li>従来の研修や業務改善システムは，無意識的な日常行動を反映することは難しく，また，行動変容の定着には個人差がある.</li>
        <li>
          本研究は，認知心理学とAIデータサイエンス的アプローチに基づき，ユーザの日常行
          動をセンシングし，仮想空間でのシミュレート行動体験を介して，振り返りを行うデジタ ルツイン型行動変容支援システムを開発する.
        </li>
      </ul>,
    ],
    overviewImage: "/assets/images/project2023/08/fig01.jpg",
    buisinessModelText: [
      <p>市場としては，企業研修や業務改善ソリューション分野を対象とする.ビジネスモデルとしては下記の2つを想定.</p>,
      <ol>
        <li>企業におけるパーソナライズ(チームビルディングを含む)トレーニングは，サブスクライブ型でソリューションを提供する.</li>
        <li>ビジネス(職場)環境全体に対しては，コンサルテーション型のサービスを提供する.</li>
      </ol>,
    ],
    buisinessModelImage: null,
    activityPlanText: [
      <p>内容:</p>,
      <ul>
        <li>デジタルツイン行動変容支援システムのプロトタイプの開発 → 大学内のラボで検証</li>
        <li>開発したプロトタイプシステムを使って，ステークホルダー企業と実証実験 ・実証結果をシステム改良にフィードバック</li>
      </ul>,
      <br />,
      <p>計画: </p>,
      <p>
        2024年度: 12月までに，大学内のラボで開発しながら運用を図り，プロトタイプを完成予定.
        パートナー企業2社程度(地域企業)を想定し，1月から本システムを活用し実証実験. <br />
        実証実験結果から逐次改良を行う.　 ※2025年2月起業予定
      </p>,
    ],
    activityPlanImage: "/assets/images/project2023/08/fig02.jpg",
  },
  {
    id: 9,
    url: "/project-2023/9",
    thumbnail: "/assets/models/texture2024/project_2023_09.jpg",
    category: "ものづくり",
    adoptionYear: "2023年",
    title: "金型成形と拡散接合による高機能マイクロ流体デバイス量産工程の確立",
    subtitle: null,
    authorUniversity: "大阪工業大学",
    authorAffiliation: "工学部 機械工学科",
    authorPosition: "講師",
    authorName: "横山 奨",
    portrait: "/assets/images/project2023/09/portrait.jpg",
    video: {
      url: "https://youtu.be/E6mC--7oJaU",
      thumbnail: "/assets/images/project2023/09/video_thumbnail.jpg",
    },
    document: "/assets/demoday2024/documents/presentation_01.pdf",
    movieStart: "0:00:00〜",
    overviewText: [
      <p>
        拡散接合は、<span className="bold">母材の変形を最低限に抑制しつつ接合界面を一体化</span>
        する技術です。母材の変形が少ないため、微細加工箇所に影響を及ぼすことなく、接合・密閉することが可能となります。
        本プロジェクトでは、金型成形と拡散接合技術を組み合わせることで、従来手法では困難であった微細な中空流路などを含むマイクロ流体デバイスの安価な量産工程の確立を目指します。
      </p>,
    ],
    overviewImage: null,
    buisinessModelText: [
      <p>2025年度の起業を目指し、拡散接合技術・装置の提供および使い捨て医療用マイクロ流体デバイスやフレキシブル熱交換器として展開を狙う。</p>,
      <p>
        将来的には、既存の加工技術と組み合わせることで、マイクロエレクトロニクス、MEMSデバイス、センサー、光学デバイスなど、微細な中空構造を有する装置幅を対象とした広範な産業分野への応用を図る。
      </p>,
    ],
    buisinessModelImage: "/assets/images/project2023/09/fig01.jpg",
    activityPlanText: [
      <p>【技術開発】</p>,
      <p>
        精密な金型成形技術の開発と高品質な拡散接合技術を確立する。 金型成形技術に関しては、企業と協力の上、解決の目処が立っている。
        拡散接合技術に関しては、接合不良を抑止するため、接合面への埃などの混入を抑止する必要がある。
        そのために、クリーンブースを設置し接合面への異物混入を回避する。 また、加圧プレー トの平滑性を維持できるように、
        交換や水平調整が可能なように拡散接合装置の一部改良を予定している。
      </p>,
      <br />,
      <p>【事業化】</p>,
      <p>
        装置の販売や、商業用マイクロ流体デバイスへの展開を目指す。
        1.使い捨て医療用デバイス、2.熱交換装置、3.プリンタブルエレクトロニクス分野、を対象として技術移転先を調査する。
      </p>,
    ],
    activityPlanImage: null,
  },
];

export default Projects;
