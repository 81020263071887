import styled from "styled-components";
import { d } from "../../../CssVariables";

const DemodayAbout = () => {
  return (
    <SDemodayAbout id="Demodayabout">
      {/* <img src="./assets/demoday2023/images/circle_01.svg" alt="" className="circle-01" />
      <img src="./assets/demoday2023/images/circle_02.svg" alt="" className="circle-02" /> */}

      <div className="flex">
        <div>
          <div className="title-box">
            <div className="title-en-large title-en">ABOUT</div>
            <h2>開催について</h2>
          </div>
        </div>
        <div>
          <p>科学技術振興機構(JST)大学発新事業創出プログラム(START)のDEMO DAYを開催します。</p>
          <p>
            本START事業では、神戸大学と大阪工業大学が共同で、所属する研究者・アントレプレナーの技術シーズを発掘し、起業活動支援を行い、技術シーズやビジネスモデルのブラッシュアップを行うと共に、JST「大学発新産業創出プログラム(START)」の申請やベンチャーキャピタル(VC)から投資を受けられるベンチャーへ橋渡しをすることで、「京阪神連携によるスタートアップ・エコシステム拠点形成(グローバル拠点都市：大阪・京都・ひょうご神戸コンソーシアム)」に貢献することを目的としています。
          </p>
          <p>
            DEMO
            DAYでは、神戸大学・大阪工業大学に所属する研究者・アントレプレナーと、事業プロモーター又は起業家(候補)が組んだ9つのチームが、それぞれの技術シーズをもとにしたベンチャー事業構想を発表します。オンラインでご覧いただけますので、ぜひご参加ください。
          </p>
          <div className="box flex">
            <a href="https://www.jst.go.jp/" target="_blank">
              科学技術振興機構(JST)とは
            </a>
            <a href="https://www.jst.go.jp/start/univ-promo/index.html" target="_blank">
              大学発新事業創出プログラム(START)とは
            </a>
          </div>
        </div>
      </div>
    </SDemodayAbout>
  );
};
export default DemodayAbout;

const SDemodayAbout = styled.section`
  position: relative;
  padding: 6vw;
  margin: 0;
  background-color: #155eb9;

  .title-en-large {
    color: #5a92cc;
  }

  img.circle-01 {
    position: absolute;
    top: -22vw;
    right: -22vw;
    z-index: 2;
    width: 40vw;
    height: auto;
    max-width: 740px;
    @media ${d.maxTab} {
      top: -25vw;
      right: -25vw;
      width: 50vw;
      max-width: none;
    }
  }
  img.circle-02 {
    position: absolute;
    bottom: -5.5vw;
    left: -9vw;
    z-index: 2;
    width: 22vw;
    height: auto;
    max-width: 380px;
    @media ${d.maxTab} {
      width: 30vw;
    }
  }

  .flex {
    position: relative;
    z-index: 3;
    flex-wrap: nowrap;
    @media ${d.maxTab} {
      flex-wrap: wrap;
    }
    > div:first-child {
      margin-right: 5vw;
      @media ${d.maxTab} {
        width: 100%;
      }
      @media ${d.maxSp} {
        margin-right: 0;
      }
    }
  }

  .title-en {
    color: #4490e0;
  }

  h2 {
    font-size: 3.2rem;
    color: #fff;
    @media ${d.maxSp} {
      font-size: 2.4rem;
    }
  }
  p {
    color: #fff;
    letter-spacing: 0.09em;
    line-height: 1.9em;
    text-align: justify;
    @media ${d.minLaptop} {
      &:first-child {
        margin-top: 0;
      }
    }
    a {
      text-decoration: underline;
      color: #dddddd;
    }
  }
  .box.flex {
    margin-top: 20px;
    margin-bottom: 0;
    justify-content: flex-start;
    a {
      display: inline-block;
      background-color: #fff;
      padding: 14px 20px;
      border-radius: 3px;
      font-weight: bold;
      margin-bottom: 16px;
      margin-right: 16px;
      margin-left: 0;
    }
  }
`;
