import { useEffect } from "react";
import BalloonBase from "../BalloonBase2022";

const Balloon4 = (props) => {
  const { hover4 } = props;
  useEffect(() => {
    document.body.style.cursor = hover4 ? "pointer" : "default";
  }, [hover4]);

  return <BalloonBase whichHover={hover4}>フードビジネスを対象としたシミュレーション・最適化技術</BalloonBase>;
};
export default Balloon4;
