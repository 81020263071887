import { useEffect } from "react";
import BalloonBase from "../BalloonBase2021";

const Balloon11 = (props) => {
  const { hover11 } = props;
  useEffect(() => {
    document.body.style.cursor = hover11 ? "pointer" : "default";
  }, [hover11]);

  return <BalloonBase whichHover={hover11}>超音波振動を応用した体内埋め込み型医療機器ワイヤレス給電システムの開発</BalloonBase>;
};
export default Balloon11;
