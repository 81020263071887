import { useEffect } from "react";
import BalloonBase from "../BalloonBase2021";

const Balloon9 = (props) => {
  const { hover9 } = props;
  useEffect(() => {
    document.body.style.cursor = hover9 ? "pointer" : "default";
  }, [hover9]);

  return <BalloonBase whichHover={hover9}>消費者の深層心理を探求するための技法の実証的研究</BalloonBase>;
};
export default Balloon9;
