import styled from "styled-components";
import { IoArrowForwardSharp } from "react-icons/io5";
import { v } from "../../CssVariables";

const Button = (props) => {
  const { children, link, color } = props;

  return (
    <SButton className={`btn ${color}`} href={link} >
      <span className="arrow"><IoArrowForwardSharp /></span>
      <span>{children}</span>
    </SButton>
  )
}
export default Button;

const SButton = styled.a`
  padding: 1.8rem 3rem;
  display: inline-block;
  background-color: #fff;
  color: ${v.textColor};

  span {
    vertical-align: middle;
    display: inline-block;
    padding: 0 0.2em;
  }
`;