import { useEffect } from "react";
import BalloonBase from "../BalloonBase2021";

const Balloon7 = (props) => {
  const { hover7 } = props;
  useEffect(() => {
    document.body.style.cursor = hover7 ? "pointer" : "default";
  }, [hover7]);

  return <BalloonBase whichHover={hover7}>可食性コーティングに用いるフィルム開発</BalloonBase>;
};
export default Balloon7;
