import { useEffect } from "react";
import BalloonBase from "../BalloonBase";

const Balloon5 = (props) => {
  
  const {hover5} = props;
  useEffect(() => {
    document.body.style.cursor = hover5 ? "pointer" : "default";
  },[hover5])

  return (
    <BalloonBase whichHover={hover5}>がんや認知症を防ぐマイオカインを高濃度に含む培養食肉カプセル</BalloonBase>
  )
}
export default Balloon5;