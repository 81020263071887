/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 /Users/oboshi/Dropbox/inProgress/START/2024/START-2024/public/assets/models/FIELD_2022.gltf --keepnames --keepgroups --shadows --meta 
*/

import React, { useState, useEffect, useRef } from "react";

import { useGLTF, useAnimations } from "@react-three/drei";
import { useSpring, a } from "@react-spring/three";
import * as easings from "d3-ease";

import gsap from "gsap";

//useGesture = 複数のイベントをバインドする。
import { useGesture } from "react-use-gesture";

import Balloon1 from "./Balloon/2022/Balloon1";
import Balloon2 from "./Balloon/2022/Balloon2";
import Balloon3 from "./Balloon/2022/Balloon3";
import Balloon4 from "./Balloon/2022/Balloon4";
import Balloon5 from "./Balloon/2022/Balloon5";
import Balloon6 from "./Balloon/2022/Balloon6";
import BalloonDemoday from "./Balloon/2022/BalloonDemoday";

export default function Model(props) {
  const group = useRef();
  const project1 = useRef();
  const project2 = useRef();
  const project3 = useRef();
  const project4 = useRef();
  const project5 = useRef();
  const project6 = useRef();
  const demoday = useRef();

  const { nodes, materials, animations } = useGLTF("/assets/models/FIELD_2022.gltf");
  const { actions } = useAnimations(animations, group);

  useEffect(() => {
    // アニメーションの再生
    // 参考: https://codeworkshop.dev/blog/2021-01-20-react-three-fiber-character-animation/
    // actions.<blenderのアクション名>.play()
    actions.project1Anim.play();
    actions.project2Anim.play();
    actions.project3Anim.play();
    actions.project4Anim.play();
    actions.project5Anim.play();
    actions.project6Anim.play();
  });

  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [hover3, setHover3] = useState(false);
  const [hover4, setHover4] = useState(false);
  const [hover5, setHover5] = useState(false);
  const [hover6, setHover6] = useState(false);
  const [hoverDemoday, setHoverDemoday] = useState(false);

  /**
   * クリックによるアニメーションと遷移
   * @param {*} item
   * @param {*} clickEventItem
   */
  // setLookAt(positionX, positionY, positionZ, targetX, targetY, targetZ, enableTransition)
  // const lookAtFunc = (item) => {
  //   props.cameraControlRef.current?.setLookAt(
  //     item.current.position.x,
  //     item.current.position.z + 15,
  //     item.current.position.y + 10,
  //     item.current.position.x,
  //     item.current.position.z + 4.5,
  //     item.current.position.y - 3.5,
  //     true
  //   );
  // };
  const lookAtFunc = (item) => {
    props.cameraControlRef.current?.setLookAt(
      item.current.position.x,
      item.current.position.y + 10,
      item.current.position.z + 15,
      item.current.position.x,
      item.current.position.y + 2.5,
      item.current.position.z + 5,
      true
    );
  };

  const operateProject1 = useGesture({
    onPointerOver: () => setHover1(true),
    onPointerOut: () => setHover1(false),
    onClick: () => {
      lookAtFunc(project1);
      setTimeout(() => {
        props.onClickProject1();
      }, 1000);
    },
  });
  const operateProject2 = useGesture({
    onPointerOver: () => setHover2(true),
    onPointerOut: () => setHover2(false),
    onClick: () => {
      lookAtFunc(project2);
      setTimeout(() => {
        props.onClickProject2();
      }, 1000);
    },
  });
  const operateProject3 = useGesture({
    onPointerOver: () => setHover3(true),
    onPointerOut: () => setHover3(false),
    onClick: () => {
      lookAtFunc(project3);
      setTimeout(() => {
        props.onClickProject3();
      }, 1000);
    },
  });
  const operateProject4 = useGesture({
    onPointerOver: () => setHover4(true),
    onPointerOut: () => setHover4(false),
    onClick: () => {
      lookAtFunc(project4);
      setTimeout(() => {
        props.onClickProject4();
      }, 1000);
    },
  });
  const operateProject5 = useGesture({
    onPointerOver: () => setHover5(true),
    onPointerOut: () => setHover5(false),
    onClick: () => {
      lookAtFunc(project5);
      setTimeout(() => {
        props.onClickProject5();
      }, 1000);
    },
  });
  const operateProject6 = useGesture({
    onPointerOver: () => setHover6(true),
    onPointerOut: () => setHover6(false),
    onClick: () => {
      lookAtFunc(project6);
      setTimeout(() => {
        props.onClickProject6();
      }, 1000);
    },
  });

  const operateDemoday = useGesture({
    onPointerOver: () => setHoverDemoday(true),
    onPointerOut: () => setHoverDemoday(false),
    onClick: () => {
      props.cameraControlRef.current?.setLookAt(
        demoday.current.position.x,
        demoday.current.position.y + 15,
        demoday.current.position.z + 15,
        demoday.current.position.x,
        demoday.current.position.y + 7.5,
        demoday.current.position.z + 5,
        true
      );
      // lookAtFunc(demoday);

      setTimeout(() => {
        props.onClickDemoday();
      }, 1000);
    },
  });

  const config = { config: { duration: 500, easing: easings.easeBackOut } };

  const { scale1 } = useSpring({ scale1: hover1 ? 1.15 : 1, config });
  const { scale2 } = useSpring({ scale2: hover2 ? 1.15 : 1, config });
  const { scale3 } = useSpring({ scale3: hover3 ? 1.15 : 1, config });
  const { scale4 } = useSpring({ scale4: hover4 ? 1.15 : 1, config });
  const { scale5 } = useSpring({ scale5: hover5 ? 1.15 : 1, config });
  const { scale6 } = useSpring({ scale6: hover6 ? 1.15 : 1, config });

  /**
   * ref
   */
  const field2021Ref = useRef();
  useEffect(() => {
    gsap.to(field2021Ref.current.scale, { z: 1, x: 1, y: 1, duration: 0.8, delay: 0.5, ease: "back.out(1)" });
  }, []);

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group ref={field2021Ref} name="FIELD_2022" rotation={[0, 0, 0]} scale={0} userData={{ name: "FIELD_2022" }}>
          <group name="COMMON" userData={{ name: "COMMON" }}>
            <group name="demoday" userData={{ name: "demoday" }}>
              <mesh
                ref={demoday}
                name="demoday_stage"
                castShadow
                receiveShadow
                geometry={nodes.demoday_stage.geometry}
                material={materials.base_color_2022}
                position={[-31.678, 3.139, -11.742]}
                userData={{ name: "demoday_stage" }}
                {...operateDemoday()}>
                <BalloonDemoday hoverDemoday={hoverDemoday} />
              </mesh>
              <mesh
                name="screen1"
                castShadow
                receiveShadow
                geometry={nodes.screen1.geometry}
                material={materials.demoday_screen_2022}
                position={[-31.912, 7.475, -23.812]}
                userData={{ name: "screen1" }}
              />
            </group>
            <mesh
              name="tree"
              castShadow
              receiveShadow
              geometry={nodes.tree.geometry}
              material={materials.base_color_2022}
              position={[-27.643, 2.411, 7.724]}
              userData={{ name: "tree" }}
            />
          </group>
          <group name="IT" userData={{ name: "IT" }}>
            <group name="stage_it" userData={{ name: "stage_it" }}>
              <mesh
                name="side001"
                castShadow
                receiveShadow
                geometry={nodes.side001.geometry}
                material={materials.base_color_2022}
                position={[-11.854, 0.25, 12.914]}
                userData={{ name: "side.001" }}
              />
              <mesh
                name="top001"
                castShadow
                receiveShadow
                geometry={nodes.top001.geometry}
                material={materials.White1}
                position={[-11.854, 0.5, 12.914]}
                userData={{ name: "top.001" }}
              />
            </group>
            <a.mesh
              ref={project1}
              name="project14"
              castShadow
              receiveShadow
              geometry={nodes.project14.geometry}
              material={materials.project_2022_1}
              position={[-0.193, 4.058, 20.129]}
              userData={{ name: "project14" }}
              {...operateProject1()}
              scale={scale1}>
              <Balloon1 hover1={hover1} />
            </a.mesh>
            <a.mesh
              ref={project4}
              name="project17"
              castShadow
              receiveShadow
              geometry={nodes.project17.geometry}
              material={materials.project_2022_4}
              position={[-26.986, 4.058, 16.305]}
              userData={{ name: "project17" }}
              {...operateProject4()}
              scale={scale4}>
              <Balloon4 hover4={hover4} />
            </a.mesh>
            <a.mesh
              ref={project6}
              name="project22"
              castShadow
              receiveShadow
              geometry={nodes.project22.geometry}
              material={materials.project_2022_6}
              position={[-13.72, 4.058, 10.002]}
              userData={{ name: "project22" }}
              {...operateProject6()}
              scale={scale6}>
              <Balloon6 hover6={hover6} />
            </a.mesh>
            <mesh
              name="text_it"
              castShadow
              receiveShadow
              geometry={nodes.text_it.geometry}
              material={materials.base_color_2022}
              position={[-30.761, 0.551, 25.07]}
              userData={{ name: "text_it" }}
            />
          </group>
          <group name="LIFE_SCIENCE" userData={{ name: "LIFE_SCIENCE" }}>
            <group name="stage_lifeScience" userData={{ name: "stage_lifeScience" }}>
              <mesh
                name="side"
                castShadow
                receiveShadow
                geometry={nodes.side.geometry}
                material={materials.base_color_2022}
                position={[9.491, 0.25, -14.026]}
                userData={{ name: "side" }}
              />
              <mesh
                name="top"
                castShadow
                receiveShadow
                geometry={nodes.top.geometry}
                material={materials.White1}
                position={[9.491, 0.5, -14.026]}
                userData={{ name: "top" }}
              />
            </group>
            <a.mesh
              ref={project2}
              name="project15"
              castShadow
              receiveShadow
              geometry={nodes.project15.geometry}
              material={materials.project_2022_2}
              position={[24.054, 4.058, -18.417]}
              userData={{ name: "project15" }}
              {...operateProject2()}
              scale={scale2}>
              <Balloon2 hover2={hover2} />
            </a.mesh>
            <a.mesh
              ref={project3}
              name="project16"
              castShadow
              receiveShadow
              geometry={nodes.project16.geometry}
              material={materials.project_2022_3}
              position={[9.617, 4.058, -9.741]}
              userData={{ name: "project16" }}
              {...operateProject3()}
              scale={scale3}>
              <Balloon3 hover3={hover3} />
            </a.mesh>
            <a.mesh
              ref={project5}
              name="project21"
              castShadow
              receiveShadow
              geometry={nodes.project21.geometry}
              material={materials.project_2022_5}
              position={[-3.285, 4.058, -21.874]}
              userData={{ name: "project21" }}
              {...operateProject5()}
              scale={scale5}>
              <Balloon5 hover5={hover5} />
            </a.mesh>
            <mesh
              name="text_lifeScience"
              castShadow
              receiveShadow
              geometry={nodes.text_lifeScience.geometry}
              material={materials.base_color_2022}
              position={[25.682, 0.55, -2.21]}
              userData={{ name: "text_lifeScience" }}
            />
          </group>
          <group name="YEARPLATE" userData={{ name: "YEARPLATE" }}>
            <mesh
              name="year_2021"
              castShadow
              receiveShadow
              geometry={nodes.year_2021.geometry}
              material={materials.White1}
              position={[37.296, 0.05, 5.084]}
              userData={{ name: "year_2021" }}
            />
          </group>
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/assets/models/FIELD_2022.gltf");
