import { useEffect } from "react";
import BalloonBase from "../BalloonBase";

const Balloon4 = (props) => {
  const { hover4 } = props;
  useEffect(() => {
    document.body.style.cursor = hover4 ? "pointer" : "default";
  }, [hover4]);

  return <BalloonBase whichHover={hover4}>細胞膜のメカニクスを標的とした革新的がん治療法の開発</BalloonBase>;
};
export default Balloon4;
