import styled from "styled-components";
import { d } from "../../../CssVariables";

const TimeSchedule = () => {
  return (
    <STimeSchedule id="timeSchedule">
      <div className="content-over-box"></div>

      <div className="inner">
        <div className="title-box">
          <div className="title-en-large title-en center">TIME SCHEDULE</div>
          <h2 className="center">タイムスケジュール</h2>
        </div>

        <div className="desc">
          <dl>
            <dt>13:00</dt>
            <dd>開会のご挨拶</dd>

            {/* <dt>13:15</dt>
            <dd>研究発表 4名</dd> */}
            <dt>13:15</dt>
            <dd>
              <b>[発表01]</b>
              <br />
              <b>コラーゲン分解酵素MMP-1の発現を抑制することによりシワだけでなく、”たるみ”も予防する神戸大学発のスキンケア商品の開発</b>
              <br />
              神戸大学大学院農学研究科 教授<span className="name">白井 康仁</span>
            </dd>

            <dt>13:30</dt>
            <dd>
              <b>[発表02]</b>
              <br />
              <b>エンゲージメントやコミュニケーションを促進するデジタルツイン行動変容支援システム</b>
              <br />
              大阪工業大学情報科学部 情報メディア学科 特任教授<span className="name">佐野 睦夫</span>
            </dd>

            <dt>13:45</dt>
            <dd>
              <b>[発表03]</b>
              <br />
              <b>細胞間シグナルCD47-SIRPα系を標的とした新規がん治療法の開発</b>
              <br />
              神戸大学大学院医学研究科 助教<span className="name">小谷 武徳 </span>
            </dd>

            <dt>14:00</dt>
            <dd>
              <b>[発表04]</b>
              <br />
              <b>無呼吸や嚥下機能を改善する電気刺激装置の開発</b>
              <br />
              神戸大学 医学部附属病院医療技術部臨床工学部門 臨床工学技士長<span className="name">加藤 博史</span>
            </dd>

            <dt>14:15</dt>
            <dd>休憩</dd>

            <dt>14:30</dt>
            <dd>
              <b>[発表05]</b>
              <br />
              <b>組織のサイバーレジリエンスを向上させるサイバーインテリジェンス情報サービスの構築</b>
              <br />
              神戸大学大学院工学研究科 准教授<span className="name">白石 善明</span>
            </dd>

            <dt>14:45</dt>
            <dd>
              <b>[発表06]</b>
              <br />
              <b>光オンデマンド合成法によるポリマーや医薬品原料の安全で安価なエコ生産システムの事業化</b>
              <br />
              神戸大学大学院理学研究科 准教授<span className="name">津田 明彦</span>
            </dd>

            <dt>15:00</dt>
            <dd>
              <b>[発表07]</b>
              <br />
              <b>
                画期的なカーボンニュートラルのエネルギー供給を可能とする日本発の新技術
                <br />
                「ナノ金属-水素反応の実験実証 (正四面体凝縮モデルのメカニズム確証)」
              </b>
              <br />
              神戸大学大学院海事科学研究科 准教授<span className="name">金崎 真聡</span>
            </dd>

            <dt>15:15</dt>
            <dd>
              <b>[発表08]</b>
              <br />
              <b>金型成形と拡散接合による高機能マイクロ流体デバイス量産工程の確立</b>
              <br />
              大阪工業大学工学部 機械工学科 講師<span className="name">横山 奨</span>
            </dd>

            <dt>15:30</dt>
            <dd>
              <b>[発表09]</b>
              <br />
              <b>細胞膜のメカニクスを標的とした革新的がん治療法の開発</b>
              <br />
              神戸大学バイオシグナル総合研究センター 准教授<span className="name">辻田 和也</span>
            </dd>

            <dt>15:45</dt>
            <dd>閉会のご挨拶</dd>
            <dt>15:50</dt>
            <dd>終了予定</dd>
          </dl>

          {/* <p style={{ marginTop: "10px" }}>※ 発表内容・発表順序は予告なく変更する場合があります。</p> */}
        </div>
      </div>
    </STimeSchedule>
  );
};
export default TimeSchedule;

const STimeSchedule = styled.section`
  position: relative;
  margin: 0;
  padding: 8vw 6%;
  background-color: #155eb9;
  @media ${d.minLaptop} {
    padding: 6vw 16vw;
  }
  .content-over-box {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1;
    width: 100%;
    height: 100%;

    background-color: transparent;
  }

  .inner {
    position: relative;
    z-index: 2;
  }

  .title-box {
    .title-en {
      color: #fff;
    }
    h2 {
      color: #fff;
      font-size: 3.2rem;
      padding-bottom: 0;
      @media ${d.maxTab} {
        font-size: 2.4rem;
      }
    }
  }
  .desc {
    width: 900px;
    margin: 0 auto;
    padding: 30px;
    /* background-color: rgba(255, 255, 255, 0.95); */
    @media ${d.maxTab} {
      width: 100%;
      padding: 10px;
    }

    dl {
      dt {
        flex-basis: 20%;
        background-color: transparent;
        color: #fff;
        font-size: 1.2em;
      }
      dd {
        flex-basis: 80%;
        background-color: transparent;
        letter-spacing: 0;
        color: #fff;
        font-size: 1.2em;
      }
      span.name {
        display: inline-block;
        margin-left: 1em;
      }
    }
  }

  p {
    color: #fff;
  }
`;
