import { useState } from "react";
import styled from "styled-components";
import { d, v } from "../../CssVariables";

import Logo from "../atoms/Logo";
import Menu from "../atoms/Menu";
import MenuToggleButton from "../atoms/MenuToggleButton";

const Header = () => {
  const [toggle, setToggle] = useState(false);

  const ChangeToggle = () => setToggle(!toggle);

  return (
    <SHeader>
      <div className="flex">
        <Logo />
        <MenuToggleButton toggle={toggle} setToggle={ChangeToggle} />
      </div>
      <Menu toggle={toggle} setToggle={ChangeToggle} />
    </SHeader>
  );
};

export default Header;

/* ---- css ---- */
const SHeader = styled.header`
  pointer-events: none;
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: ${v.headerHeightPc};
  @media ${d.maxSp} {
    height: ${v.headerHeightSp};
  }
  .flex {
    position: absolute;
    flex-direction: row;
    align-items: center;
    padding-left: 36px;
    height: ${v.headerHeightPc};
    @media ${d.maxSp} {
      height: ${v.headerHeightSp};
    }
    @media ${d.maxSp} {
      padding-left: 16px;
    }
  }
`;
