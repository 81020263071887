import { useEffect } from "react";

import styled from "styled-components";
import { d } from "../../CssVariables";

import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <>
      <SLogoBox>
        <Link to={"/"}>
          <img src="/assets/images/logo.svg" alt="" />
        </Link>
      </SLogoBox>
    </>
  );
};
export default Logo;

/* ---- css ---- */
const SLogoBox = styled.div`
  pointer-events: auto;
  position: absolute;
  z-index: 4;
  a {
    color: #fff;
    width: 133px;
    height: 24px;
    letter-spacing: -0.04em;
    @media ${d.maxSp} {
      font-size: 2.4rem;
    }
    font-weight: bold;
    &:hover {
      color: #fff;
    }
    &:after {
      display: none;
    }
    img {
      width: 133px;
      height: 20px;
    }
  }
`;
