import styled from "styled-components"
import Head from "../../Head"
import Hero from "../organisms/Hero2022"
import TempUnderLayerMax from "../templates/TempUnderLayerMax";
import MV from "../demoday2023/organisms/MV";
import DemodayAbout from "../demoday2023/organisms/DemodayAbout";
import Speakers from "../demoday2023/organisms/Speakers";
import Overview from "../demoday2023/organisms/Overview";
import TimeSchedule from "../demoday2023/organisms/TimeSchedule";
import Reservation from "../demoday2023/organisms/Reservation";
import Supporter from "../demoday2023/organisms/Supporter";
import Sponsor from "../demoday2023/organisms/Sponsor";
import Contact from "../demoday2023/organisms/Contact";
import BottomLogoArea from "../demoday2023/organisms/BottomLogoArea";
import EndAnnounce from "../demoday2023/organisms/EndAnnounce";
import { d } from "../../CssVariables";
import ToMovie from "../demoday2023/organisms/ToMovie";

const DemoDay2023 = () => {
  return (
    <>
      <Head title="DEMO DAY" />
      <SDemoday2023>
        <Hero title={"DEMO DAY 2023"} />
        <TempUnderLayerMax>
          <MV />
          <EndAnnounce />
          {/* <ToMovie /> */}
          <DemodayAbout />
          <Speakers />
          <section id="bannerArea">
            <a href="/">
              <img src="./assets/demoday2023/images/banner_to_top.png" alt="研究一覧ビューへ" className="banner-to-top" />
            </a>
            </section>
          <Overview />
          <TimeSchedule />
          <Reservation />
          {/* <Supporter /> */}
          <Sponsor />
          <Contact />
          <BottomLogoArea />
        </TempUnderLayerMax>
      </SDemoday2023>
    </>
  )
}
export default DemoDay2023;

const SDemoday2023 = styled.div`
  section#DemodayMovie {
    margin: 0;
    iframe {
      width: 100%;
      height: 600px;
    }
  }
  section#description {
    div {
      background: #eee;
      margin-bottom: 1.5%;
      padding: 3% 1%;
    }
  }
  .hero-title-box {
    display: none;
  }


  section#bannerArea {
    background-color: #fff;
    padding: 80px 0 30px;
    margin: 0;
    img.banner-to-top {
      width: 900px;
      margin: 0 auto;
      display: block;
      @media ${d.maxTab} {
        width: 90%;
      }
    }
  
  }
`;