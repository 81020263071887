import styled from "styled-components";
import { d, v } from "../../CssVariables";
import MainTitle from "../atoms/MainTitle";

const Hero = (props) => {
  const { title } = props;
  return (
    <SHero>
      <div className="width-max">
        <div className="hero-title-box">
          <MainTitle>{title}</MainTitle>
        </div>
      </div>
    </SHero>
  );
};
export default Hero;

const SHero = styled.div`
  padding-top: ${v.headerHeightPc};
  @media ${d.maxSp} {
    padding-top: ${v.headerHeightSp};
  }
  background-color: ${v.primaryColor21};
  .hero-title-box {
    width: 100%;
    padding: 1.25% 10% 1.25% 10%;
    background-color: #fff;
    @media ${d.maxSp} {
      padding: 6% 6%;
      width: 100%;
    }
    h1 {
      padding: 0;
      color: ${v.textColor};
    }
  }
`;
