import styled from "styled-components";
import { d, v } from "../../../CssVariables";

const Contact = () => {
  return (
    <SContanct id="contact">
      <div className="title-box">
        <div className="title-en-large title-en center">CONTACT</div>
        <h2 className="center">お問い合わせ</h2>
      </div>
      <div className="flex">
        <div className="contact-list">
          <h4>神戸大学 問い合わせ先</h4>
          <hr />
          <p>株式会社神戸大学イノベーション　事業開発部アソシエイト</p>
          <p>
            〒657-8501
            <br />
            神戸市灘区六甲台町1-1　産官学連携本部4階
          </p>
          <p>TEL. 078-803-6649</p>
          <p>MAIL. gapfund.office@kobe-u-innov.jp</p>
        </div>
        <div className="contact-list">
          <h4>大阪工業大学 問い合わせ先</h4>
          <hr />
          <p>大阪工業大学　学長室　研究支援社会連携推進課</p>
          <p>
            〒535-8585
            <br />
            大阪府大阪市旭区大宮5丁目16-1 <br />
            (大阪工業大学大宮キャンパス８号館3F）
          </p>
          <p>TEL. 06-6954-4140</p>
          <p>MAIL. OIT.Kenkyu@josho.ac.jp</p>
        </div>
      </div>
    </SContanct>
  );
};  
export default Contact;

const SContanct = styled.section`
  margin: 0;
  padding: 8vw 6%;
  @media ${d.minLaptop} {
    padding: 6vw 12vw;
  }
  background-color: #ededef;
  .flex {
    .contact-list {
      width: 50%;
      padding: 20px 20px 0 20px;
      margin-bottom: 20px;
      @media ${d.maxSp} {
        width: 100%;
      }
      hr {
        margin: 1em 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
      h4 {
        text-align: center;
      }
      p {
        line-height: 1.3em;
        text-align: center;
        @media ${d.maxSp} {
          text-align: left;
        }
      }
    }
  }
  .title-box {
    .title-en-large {
      color: ${v.primaryColor23};
    }
    h2 {
      color: ${v.primaryColor23};
      font-size: 3.2rem;
      padding-bottom: 0;
      @media ${d.maxTab} {
        font-size: 2.4rem;
      }
    }
  }
`;
