import styled from "styled-components";
import { d, v } from "../../../CssVariables";
import SpeakerList from "../data/speakerList";

const Speakers = () => {
  const outputSpeaker = SpeakerList.map((speaker, index) => (
    <div key={index} className="item col-4 flex">
      <div className="flex">
        <a className="image-box" href={speaker.movie} target="_blank">
          <img src={speaker.portrait} alt="" />
        </a>
        <div className="desc">
          <h4>{speaker.title}</h4>
          <div className="inner">
            <h3>{speaker.name} 氏</h3>
            <h4>
              {speaker.university && <span>{speaker.university}</span>}
              {speaker.department && <span>{speaker.department}</span>}
              {speaker.position && <span>{speaker.position}</span>}
            </h4>
            {speaker.remark && <div className="remark">{speaker.remark}</div>}
          </div>
        </div>
      </div>
    </div>
  ));

  return (
    <SSpeakers id="speakers" className="flex">
      <div className="col-left">
        <div className="title-en">SPEAKERS</div>
      </div>
      <div className="col-right">
        <div className="title-box">
          <h2>登壇者情報</h2>
        </div>
        <div className="speaker-list flex">{outputSpeaker}</div>
      </div>
    </SSpeakers>
  );
};
export default Speakers;
const SSpeakers = styled.section`
  margin: 0;
  background-color: ${v.primaryColor21};

  padding: 8vw 4vw;
  @media ${d.maxSp} {
    padding: 18vw 8vw;
  }
  .col-left {
    width: 12vw;
    @media ${d.maxTab} {
      width: 100%;
    }
    .title-en {
      color: #fff;
      font-size: 12vw;
      font-weight: bold;
      transform: rotate(90deg);
      transform-origin: 50% 50%;
      line-height: 12vw;
      @media ${d.maxTab} {
        transform: none;
      }
    }
  }
  .col-right {
    width: calc(100vw - 12vw - 14vw);
    @media ${d.maxTab} {
      width: 100%;
    }
    h2 {
      color: #fff;
      font-size: 3.2rem;
      @media ${d.minLaptop} {
        padding-top: 0;
        margin-bottom: 1.5em;
      }
      @media ${d.maxTab} {
        font-size: 2.4rem;
      }
    }
    .speaker-list {
      .item {
        padding: 20px;
        margin-bottom: 40px;
        border: 2px solid ${v.secondaryColor21};
        text-align: center;
        position: relative;
        border: solid 2px ${v.secondaryColor21};
        background-color: ${v.primaryColor21};

        &::before,
        &::after {
          position: absolute;
          content: "";
          display: block;
          background-color: ${v.primaryColor21};
        }
        &::before {
          top: -4px;
          bottom: -4px;
          left: 16px;
          right: 16px;
        }
        &::after {
          top: 16px;
          bottom: 16px;
          left: -4px;
          right: -4px;
        }

        div.flex {
          flex-direction: column;
          position: relative;
          z-index: 1;

          a.image-box {
            position: relative;
            display: inline-flex;
            width: 100%;
            aspect-ratio: 1 / 1;
						
            :before {
              content: "MOVIE";
              position: absolute;
              z-index: 2;
              left: 0;
              bottom: 0;
              width: auto;
              height: auto;
              padding: 1em 1.5em 1em 2.5em;
              background-color: ${v.secondaryColor21};
              color: #fff;
              font-size: 1.6rem;
            }
            :after {
              content: "";
              position: absolute;
              z-index: 3;
              bottom: calc(1em + 5px);
              left: 1.5em;
              width: 8px;
              height: 8px;
              border-top: solid 1px #fff;
              border-right: solid 1px #fff;
              transform: rotate(45deg);
            }
            img {
              position: relative;
              z-index: 1;
            }
          }

          .desc {
            flex-grow: 2;
            background-color: #fff;
            text-align: left;
            > h4 {
              background-color: #eee;
              padding: 2rem;
              color: ${v.secondaryColor21};
              font-size: 1.6rem;
            }
            .inner {
              padding: 2rem;
              h3 {
                color: ${v.secondaryColor21};
              }
              h4 {
                line-height: 1.5em;
                font-size: 1.5rem;
                color: ${v.secondaryColor21};
                span {
                  display: block;
                }
              }
              hr {
                background-color: ${v.secondaryColor21};
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
`;
