import { useEffect } from "react";
import BalloonBase from "../BalloonBase2021";

const Balloon16 = (props) => {
  const { hover16 } = props;
  useEffect(() => {
    document.body.style.cursor = hover16 ? "pointer" : "default";
  }, [hover16]);

  return <BalloonBase whichHover={hover16}>「機能性表示食品」取得を目指した臨床試験研究と成果物・検証プロセスの社会実装・事業化</BalloonBase>;
};
export default Balloon16;
