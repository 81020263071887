import styled from "styled-components";
import { IoArrowForwardSharp } from "react-icons/io5";
import { d, v } from "../../../CssVariables";

const Button = (props) => {

  const { url, color, free, children } = props;

  return (
    <SButton href={url} style={{"color": `${color}`}}>
      <span className="arrow"><IoArrowForwardSharp /></span>{children}
      {free && <span className="free-mark" style={{"backgroundColor": `${color}`}}>無料</span> } 
    </SButton>
  )

}
export default Button;

const SButton = styled.a`
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 1.2em 2em;
  border-radius: 0;
  text-align: center;
  font-size: 2rem;
  @media ${d.maxSp} {
    font-size: 1.8rem;
  }
  font-weight: bold;
  line-height: 1.5em;
  /* 
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.2);
  transform: scale(1);

  &:hover {
    transform: scale(1.05);
  } */

  span.arrow {
    display: flex;
    align-items: center;
    margin-right: 0.1em;
    width: 1em;
    height: 1em;
  }
  span.free-mark {
    background-color: ${v.primaryColor21};
    display: inline-block;
    padding: 0 0.5em;
    margin-left: 0.5em;
    color: #fff;
  }
`;