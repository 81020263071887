import styled from "styled-components";
import { d, v } from "../../../CssVariables";
import { useMediaQuery } from "../../../UseMediaQuery";

const MV = () => {

  const mq = useMediaQuery();

  return (
    <SMV id="mv">
      
      <div className="video-wrapper">
        <video
          autoPlay // オートプレイ。 reactではキャメルケースにする。
          muted // chromeではこれがないとオートプレイされない
          playsInline // 全画面モードにして再生するのではなく、動画ファイルが埋め込まれた表示領域でそのまま動画ファイルを再生することを指す。 モバイルの自動再生では必須。
          loop
          poster="" // 要設定。 映像のダウンロード中に表示される画像の URL を指定
        >
          { mq.isSP && <source src="./assets/demoday2022/videos/movie_sp.mp4"></source> }
          { mq.isTAB && <source src="./assets/demoday2022/videos/movie_pc.mp4"></source> }
          
        </video>
      </div>
      
      
      <div className="object-wrapper">
        <img className="mv-title" src={`${process.env.PUBLIC_URL}/assets/demoday2022/images/mv_title.svg`} />

        <div className="blue-square"></div>
        {/* <div className="red-square"></div> */}
        <img src="./assets/demoday2022/images/red_triangle.svg" alt="" className="red-triangle"/>
        {/* <a className="btn_to-supporter" href="#supporter">
          <img src={`${process.env.PUBLIC_URL}/assets/demoday2022/images/to_supporter.png`} alt=""/>
        </a> */}
      </div>
    </SMV>
  )
}
export default MV;

const SMV = styled.div`
  position: relative;
  /* width: 100vw; */
  width: 100%;
  height: 100vh;

  .video-wrapper {
    position: absolute;
    z-index: 1;
    margin-top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    video {
      position: absolute;
      z-index: 100;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      min-width: 100%;
      min-height: 100%;
      z-index: 10;
    }
  }

  .object-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;

    img.mv-title {
      position: absolute;
      z-index: 102;
      width: 36vw;
      top: calc(50% + 20px);
      left: 4vw;
      transform: translateY(-50%);
      @media ${d.maxTab} {
        width: 60vw;
      }
      @media ${d.maxSp} {
        width: 66vw;
      }
    }
    .blue-square {
      position: absolute;
      z-index: 101;
      bottom: -4vw;
      width: 22vw;
      height: 22vw;
      background-color: ${v.secondaryColor21};
      @media ${d.maxTab} {
        width: 30vw;
        height: 30vw;
        bottom: -8vw;
      }
    }
    .red-triangle {
      position: absolute;
      z-index: 101;
      right: 0;
      bottom: -20vw;
      width: 24vw; 
      height: auto;
      @media ${d.maxTab} {
        width: 30vw;
        bottom: -30vw;
      }
    }
    a.btn_to-supporter {
      position: absolute;
      z-index: 102;
      right: 20px;
      top: 100px;
      width: 200px;
      height: auto;
      @media ${d.maxTab} {
        top: 20px;
        right: 20px;
        width: 28vw;
        max-width: 180px;
      }
      @media ${d.maxSp} {
        width: 28vw;
      }
      img {
        width: 100%;
        height: 100%;
        transform: scale(1);
        transition-duration: 0.5s;
        &:hover {
          transform: scale(1.05);
          transition-duration: 0.5s;
        }
      }
    }
  }
`;

