import { useEffect } from "react";
import BalloonBase from "../BalloonBase2022";

const Balloon5 = (props) => {
  const { hover5 } = props;
  useEffect(() => {
    document.body.style.cursor = hover5 ? "pointer" : "default";
  }, [hover5]);

  return <BalloonBase whichHover={hover5}>ペプチドを基軸とする神経障害性疼痛治療薬の開発</BalloonBase>;
};
export default Balloon5;
