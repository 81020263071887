import styled from "styled-components";
import { d, v } from "../../../CssVariables";

const Sponsor = () => {
  return (
    <SSponsor id="sponsor">
      <div className="title-box">
        <h2 className="center">共催・後援・協力</h2>
        <div className="title-en-small title-en center">SPONSORS</div>
      </div>
      <div className="company-box flex">
        <div className="logo-ker">
          <img src={`${process.env.PUBLIC_URL}/assets/demoday2022/images/logo_kansai-keizai-rengokai.jpg`} alt="関西経済連合会" />
        </div>
        <div className="logo-oih">
          <img src={`${process.env.PUBLIC_URL}/assets/demoday2022/images/logo_osaka_innovation_hub.png`} alt="大阪イノベーションハブ" />
        </div>
        <div className="logo-kkd">
          <img src={`${process.env.PUBLIC_URL}/assets/demoday2022/images/logo_kansaidoyukai.jpg`} alt="関西経済同友会" />
        </div>
        <div className="logo-occi">
          <img src={`${process.env.PUBLIC_URL}/assets/demoday2022/images/logo_occi.png`} alt="大阪商工会議所" />
        </div>
        <div className="logo-ks">
          <img src={`${process.env.PUBLIC_URL}/assets/demoday2022/images/logo_kobe-shokokaigisho.gif`} alt="神戸商工会議所" />
        </div>
        <div className="logo-smbc">
          <img src={`${process.env.PUBLIC_URL}/assets/demoday2022/images/logo_smbc.png`} alt="SMBC" />
        </div>
        <div className="logo-ksii">
          <img src={`${process.env.PUBLIC_URL}/assets/demoday2022/images/logo_ksii.jpg`} alt="KSII" />
        </div>
        <div className="logo-ntt-bs">
          <img src={`${process.env.PUBLIC_URL}/assets/demoday2022/images/logo_ntt_bs.svg`} alt="NTTビジネスソリューションズ" />
        </div>
        <div className="logo-ntt-sc">
          <img src={`${process.env.PUBLIC_URL}/assets/demoday2022/images/logo_ntt_sc.svg`} alt="NTT Smart Connect" />
        </div>
        <div className="logo-fm802">
          <img src={`${process.env.PUBLIC_URL}/assets/demoday2022/images/logo_fm802.png`} alt="FM802" />
        </div>
      </div>
    </SSponsor>
  );
};
export default Sponsor;

const SSponsor = styled.section`
  margin: 0;
  background-color: #fff;
  padding: 8vw 6vw 6vw;
  @media ${d.maxSp} {
    padding: 8vw 4vw 0;
  }
  .title-en-small {
    color: ${v.primaryColor21};
  }
  .company-box.flex {
    flex-direction: row;
    justify-content: center;
    /* max-width: 1000px; */
    margin: 0 auto;
    div {
      align-self: center;
      width: 15%;
      @media ${d.maxSp} {
        width: 40%;
      }
      margin: 0 2.5% 2vw 2.5%;
      @media ${d.maxSp} {
        margin: 0 5% 8vw 5%;
      }
      &.logo-ker {
      }
      &.logo-oih {
        width: 17%;
        @media ${d.maxSp} {
          width: 40%;
        }
      }
      &.logo-kkd {
        width: 14%;
        @media ${d.maxSp} {
          width: 36%;
        }
      }
      &.logo-occi {
        width: 14%;
        @media ${d.maxSp} {
          width: 36%;
        }
      }
      &.logo-ks {
      }
      &.logo-smbc {
        width: 11%;
        @media ${d.maxSp} {
          width: 26%;
        }
      }
      &.logo-ntt-bs {
        width: 15%;
        @media ${d.maxSp} {
          width: 40%;
        }
      }
      &.logo-ntt-sc {
        width: 13%;
        @media ${d.maxSp} {
          width: 35%;
        }
      }
      &.logo-ksii {
        width: 5.2%;
        @media ${d.maxSp} {
          width: 13%;
        }
      }
      &.logo-fm802 {
        width: 12%;
        @media ${d.maxSp} {
          width: 30%;
        }
      }
      img {
        width: 100%;
        @media ${d.maxSp} {
          width: 100%;
        }
      }
    }
  }
`;
