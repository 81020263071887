import styled from "styled-components";
import Button from "../atoms/Button";
import bg from "../images/background_supporter.jpg";
import { d, v } from "../../../CssVariables";

const Supporter = () => {
  return (
    <SSupporter id="supporter">
      <div className="title-box">
        <h2 className="center">学生サポーター募集中</h2>
        <div className="title-en-small title-en center">SUPPORTER</div>
      </div>
      <div className="desc">
        <h3 style={{color:"#ffffff", textAlign:"center"}}>※募集は終了しました</h3>
        <p>
          本イベントの学生サポーターを募集中です。 当日は双方向ライブ配信システム「connective」を使用して、オンラインで本イベントのサポーターとして参加いただきます。 ご自身の場所からPCでご参加いただき、登壇者の発表に配信システムを使用したりアクションやチャットでの質問をして頂きます。
        </p>
        <dl>
          <dt>参加資格</dt>
          <dd>学生(社会人学生も可)</dd>
          <dt>参加費</dt>
          <dd>無料</dd>
          <dt>募集人数</dt>
          <dd>20名（申込多数の場合は抽選）</dd>
          <dt>参加条件</dt>
          <dd>
            <ul>
              <li>当日、安定したネットワーク環境で参加できる方</li>
              <li>オンラインリハーサルに参加できる方（3月25日、28日、29日　18：00～19：00　のうちどこか１日を選択）</li>
            </ul>
          </dd>
          <dd className="dd-only">
          ※参加者には後日、FM802と大阪工業大学のグッズをセットにして進呈します。
          </dd>
        </dl>
      </div>
      {/* <div className="btn-box flex">
        <Button url={"https://forms.office.com/Pages/ResponsePage.aspx?id=BgaLzUoz5ke7n9VwcRig6sP2TimKfJJJqKNPHUABIu5UQlRHSk40RERNM0RDSjI2SVZPSlMyRzFISy4u"} color={v.secondaryColor21} free>申し込みはこちら</Button>
      </div> */}
    </SSupporter>
  )
}
export default Supporter;

const SSupporter = styled.section`
  margin: 0;
  padding: 8vw 6%;
  @media ${d.minLaptop} {
    padding: 8vw 20vw;
  }
  background-image: url(${bg});
  background-size: cover;
  background-position: center center;
  color: #fff;

  h2, .title-en-small {
    color: #fff;
  }
  .desc {
    margin-bottom: 3vw;
    @media ${d.maxSp} {
      margin-bottom: 6vw;
    }
    p {
      color: #fff;
      margin-bottom: 1em;
    }
    dl {
      margin-bottom: 20px;
      dt {
        background-color: transparent;
      }
      dd {
        background-color: transparent;
        &.dd-only {
          flex-basis: 100%;
          padding-top: 20px;
        }
      }
    }
  }

  .btn-box {
    margin-bottom: 0;
  }

`;
