import { useEffect } from "react";
import BalloonBase from "../BalloonBase";

const Balloon2 = (props) => {
  const { hover2 } = props;
  useEffect(() => {
    document.body.style.cursor = hover2 ? "pointer" : "default";
  }, [hover2]);

  return <BalloonBase whichHover={hover2}>閉塞性睡眠時無呼吸症候群（OSAS）に対する電気刺激を用いた口腔筋トレーニング装置の開発</BalloonBase>;
};
export default Balloon2;
