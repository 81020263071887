import * as React from "react";
import { Helmet } from "react-helmet";


const Head = (props) => {
  const { title } = props;

  return (
    <Helmet>
      <title>{title}</title>
      <meta
        name="description"
        content="神戸大学・大阪工業大学による研究"
      />
      <meta name="viewport" content="width=device-width,initial-scale=1.0,minimum-scale=1.0"></meta>

      {/* OGP */}
      <head prefix="og: http://ogp.me/ns#" />
      <meta property="og:url" content="https://ku-oit.jp/" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={{title}} />
      {/* <meta property="og:description" content="ページのディスクリプション" /> */}
      <meta property="og:site_name" content="JST START" />
      <meta property="og:image" content={`${process.env.PUBLIC_URL}/ogp_image.jpg`} />

      {/* Google Font */}

    </Helmet>
  )
}
export default Head;