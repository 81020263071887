import { useEffect } from "react";
import BalloonBase from "../BalloonBase2021";

const Balloon2 = (props) => {
  const { hover2 } = props;
  useEffect(() => {
    document.body.style.cursor = hover2 ? "pointer" : "default";
  }, [hover2]);

  return <BalloonBase whichHover={hover2}>乳幼児期手話言語獲得支援における映像教材の開発、ならびに動画配信のもたらす効果の実証</BalloonBase>;
};
export default Balloon2;
