import React, { createContext, useState } from "react";


export const AnnounceContext = createContext({});

export const AnnounceProvider = (props) => {
  const { children } = props;

  const [announce, setAnnounce] = useState(true);

  return (
    <AnnounceContext.Provider value={{ announce, setAnnounce }}>
      {children}
    </AnnounceContext.Provider>
  );;
}