import { useEffect } from "react";
import BalloonBase from "../BalloonBase2021";

const Balloon5 = (props) => {
  const { hover5 } = props;
  useEffect(() => {
    document.body.style.cursor = hover5 ? "pointer" : "default";
  }, [hover5]);

  return <BalloonBase whichHover={hover5}>気象モデルWRFを用いた洋上風況調査手法の実用化</BalloonBase>;
};
export default Balloon5;
